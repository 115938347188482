import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { setToast } from "../../utils/ToastNotification";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplications } from "../../features/application";
import { fetchApplicationStatus } from "../../features/applicationStatus";
import { fetchApplicationValues } from "../../features/applicationValue";
import { fetchCustomers } from "../../features/customer";
import { fetchProperties } from "../../features/properties";
import { Button } from "../Ui/button";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const Header = ({ props }) => {
  const [data, setData] = useState([]);
  const [columnArray, setColumn] = useState([]);
  const [values, setValues] = useState([]);

  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (e) => {
    e.preventDefault();
    const fileUploaded = e.target.files[0];
    console.log("File upload: ", fileUploaded);

    Papa.parse(fileUploaded, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const columnArray = [];
        const valuesArray = [];
        console.log("result data: ", result.data);
        result.data.map((d) => {
          columnArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        setData(result.data);
        setColumn(columnArray[0]);
        setValues(valuesArray);

        uploadApplication(columnArray[0], valuesArray, e);
        e.target.value = null;
      },
    });
  };

  const uploadApplication = async (cols, vals, e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/upload`
          : `http://localhost:3000/application/upload`;

      const res = await axios.post(
        url,
        { columns: cols, values: vals },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      const uploadResponse = res.data.data;
      await dispatch(fetchApplications());
      await dispatch(fetchApplicationStatus());
      await dispatch(fetchApplicationValues());
      await dispatch(fetchProperties());
      await dispatch(fetchCustomers());

      const toastPayload = {
        index: 0,
        description: 12,
        type: "application",
      };
      setToast(toastPayload);
      props.openImportModal(uploadResponse);
      setData([]);
      setColumn([]);
      setValues([]);
    } catch (err) {
      console.log(err);
      const toastPayload = {
        index: 1,
        description: 11,
        type: "application",
      };
      setToast(toastPayload);
    }
  };

  const clearFilters = () => {
    props.setFilterText("");
    props.setFilterType("");
    props.setFilterOptions({});
    props.setSortOptions({});
  };

  // const [exportModal, setExportModal] = useState(false);
  // const openExportModal = () => {
  //   setExportModal(true);
  // };
  // const closeExportModal = () => {
  //   setExportModal(false);
  // };

  // useEffect(() => {
  //   console.log(exportModal);
  // }, [exportModal]);

  return (
    <div className="flex flex-row gap-8 justify-start items-center text-black dark:text-white font-sans  rounded">
      {props.view === "app" && (
        <div className="flex flex-col">
          <h2 className="mb-8 font-sans text-lg">APPLICATIONS</h2>

          <div className="mb-4 flex gap-2">
            <Button
              variant="outline"
              className="rounded bg-green-reef text-white w-fit ml-auto"
              onClick={handleClick}
            >
              Import Applications
            </Button>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              className="hidden"
              accept=".csv"
            />
            <Button
              variant="outline"
              className="rounded bg-green-reef text-white  ml-auto"
              onClick={props.showMyAssignments}
            >
              Show My Assignments
            </Button>
            <Button
              variant="outline"
              className="rounded bg-green-reef text-white  ml-auto"
              onClick={() => {
                props.setExportModal(!props.exportModal);
              }}
            >
              Export to Excel
            </Button>
          </div>
        </div>
      )}

      <div
        className={`search flex gap-2 ${
          props.view === "app" ? "ml-auto" : "mr-auto"
        } items-center pr-4 mt-16`}
      >
        {props.view === "app" && (
          <Button
            variant="outline"
            className="rounded  bg-blue-reef  text-white w-fit ml-auto"
            onClick={(e) => {
              clearFilters();
            }}
          >
            Clear
          </Button>
        )}

        <label className="text-sm font-sans">Search By:</label>
        <select
          className="rounded text-black border h-[2.5rem]"
          onChange={(e) => {
            props.setFilterType(e.target.value);
          }}
        >
          <option value="" disabled={true} selected={true}>
            Select One:
          </option>
          <option value="Name">Applicant Name</option>
          <option value="Program">Program Name</option>
          <option value="Property">Property Address</option>
        </select>

        <input
          type="text"
          className="rounded border h-[2.5rem]"
          onChange={(e) => {
            props.setFilterText(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default Header;
