import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { Axios } from "../utils/axios/axiosConfig";
import { setToast } from "../utils/ToastNotification";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  loggedUser: {},
  users: [],
  usernames: [],
  profile: null,
  status: "idle",
  error: null,
  loginStatus: "idle",
  loginError: null,
  deleteStatus: "idle",
  deleteError: null,
  usernameStatus: "idle",
  usernameError: null,
};

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/user";
const local_base_url = "http://localhost:3000/user";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  try {
    const res = await axios.post(
      `${active_base_url}/fetch`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const user = res.data.data;
    return user;
  } catch (err) {
    console.log("errr1", err);
  }
});
export const fetchUsernames = createAsyncThunk(
  "users/fetchUsernames",
  async () => {
    try {
      const res = await axios.post(
        `https://expressproxy-prod.azurewebsites.net/username/fetch`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const user = res.data.data;
      console.log("fetchUsersss", user);
      return user;
    } catch (err) {
      console.log("errr1", err);
    }
  }
);

export const addUser = createAsyncThunk("users/createUser", async (user) => {
  try {
    const res = await axios.post(`${active_base_url}/add`, user, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const addUser = res.data.data;
    console.log("addUsers", addUser);
    return addUser;
  } catch (err) {
    console.log(err);
  }
});

export const loginUser = createAsyncThunk("users/loginUser", async (user) => {
  try {
    console.log("ACTive: ", active_base_url);
    const res = await axios.post(`${active_base_url}/login`, user);
    const loggedUser = res.data.data;

    console.log("LOGGEDUSER: ", loggedUser);

    localStorage.setItem("role", loggedUser.role);
    localStorage.setItem("user_id", loggedUser.id);
    localStorage.setItem("firstName", loggedUser.firstName);
    localStorage.setItem("lastName", loggedUser.lastName);
    localStorage.setItem("email", loggedUser.email);
    localStorage.setItem("phone", loggedUser.phone);
    localStorage.setItem("token", loggedUser.token);

    return loggedUser;
  } catch (err) {
    const toastPayload = {
      index: 1,
      description: 0,
      type: "user",
    };
    setToast(toastPayload);
  }
});

//changed url
export const deleteUser = createAsyncThunk("users/deleteUser", async (user) => {
  try {
    const res = await axios.post(`${active_base_url}/delete`, user.id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const deleteUser = res.data.data;
    return deleteUser;
  } catch (err) {
    console.log(err);
  }
});

export const updateUser = createAsyncThunk("users/updateUser", async (user) => {
  try {
    console.log("User: ", user);
    const res = await axios.post(`${active_base_url}/update`, user, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const updateUser = res.data.data;
    console.log("addUsers", updateUser);
    return updateUser;
  } catch (err) {
    console.log(err);
  }
});
export const userSlice = createSlice({
  name: "user",
  initialState: { value: initStateValue },
  reducers: {
    logout: (state, action) => {
      state.value = initStateValue;
      console.log("logout", state.value);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.value.loginStatus = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.value.loginStatus = "succeeded";
        state.value.loggedUser = { ...action.payload };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.value.loginStatus = "failed";
        state.value.loginError = action.error.message;
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.value.status = "loading";
        state.value.users = action.payload;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      })
      .addCase(fetchUsernames.pending, (state, action) => {
        state.value.usernameStatus = "loading";
        state.value.usernames = action.payload;
      })
      .addCase(fetchUsernames.fulfilled, (state, action) => {
        state.value.usernameStatus = "succeeded";
        state.value.usernames = action.payload;
      })
      .addCase(fetchUsernames.rejected, (state, action) => {
        state.value.usernameStatus = "failed";
        state.value.usernameError = action.error.message;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.value.deleteStatus = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.value.deleteStatus = "succeeded";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.value.deleteStatus = "failed";
        state.value.deleteError = action.error.message;
      });
  },
});

export const selectAllUsers = (state) => state.user.value.users;
export const getUsersStatus = (state) => state.user.value.status;
export const getUsersError = (state) => state.user.value.error;
export const getLoginStatus = (state) => state.user.value.loginStatus;
export const getDeleteStatus = (state) => state.user.value.deleteStatus;
export const getDeleteError = (state) => state.user.value.deleteError;
export const getLoggedUser = (state) => state.user.value.loggedUser;
export const selectAllUsernames = (state) => state.user.value.usernames;
export const getUsernameStatus = (state) => state.user.value.usernameStatus;

export const { logout } = userSlice.actions;

export default userSlice.reducer;
