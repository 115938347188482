import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "tailwindcss/tailwind.css";

const DateRangePicker = ({ props }) => {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  // useEffect(() => {
  //   console.log("Start Date: ", startDate);
  // }, [startDate]);

  // useEffect(() => {
  //   console.log("Start Date: ", endDate);
  // }, [endDate]);

  return (
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-4">Select a date range:</h3>
      <div className="flex gap-4">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Date:
          </label>
          <DatePicker
            selected={props.startDate}
            onChange={(date) => props.setStartDate(date)}
            selectsStart
            startDate={props.startDate}
            endDate={props.endDate}
            dateFormat="MM/dd/yyyy"
            className="w-[7rem] px-3 py-2 border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Date:
          </label>
          <DatePicker
            selected={props.endDate}
            onChange={(date) => props.setEndDate(date)}
            selectsEnd
            startDate={props.startDate}
            endDate={props.endDate}
            minDate={props.startDate}
            dateFormat="MM/dd/yyyy"
            className="w-[7rem]  px-3 py-2 border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
