import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Moment from "react-moment";
import { updateBudget } from "../../features/programBudgetLog";
import { selectAllUsers } from "../../features/user";

import { useSelector } from "react-redux";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const DropdownInput = ({ props }) => {
  return (
    <div className="flex gap-4">
      <input
        defaultValue={props.value.value}
        className=" text-black rounded border-2 border-gray-300 w-11/12 p-1 h-[2.5rem]"
        onChange={(e) => {
          props.updateDropdown({
            id: props.value.id,
            value: e.target.value,
          });
        }}
      />
      <button
        className="bg-red-400 hover:bg-red-500 px-2 rounded text-white"
        onClick={() => {
          props.deleteDropdownValue(props.value.id);
        }}
      >
        x
      </button>
    </div>
  );
};

export default DropdownInput;
