import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/application/property";
const local_base_url = "http://localhost:3000/application/property";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  appProperties: [],
  status: "idle",
  error: null,
};

export const fetchApplicationProperties = createAsyncThunk(
  "application/fetchApplicationProperties",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const propertyList = res.data.data;
      return propertyList;
    } catch (err) {
      console.log(err);
    }
  }
);

export const applicationPropertySlice = createSlice({
  name: "applicationProperties",
  initialState: { value: initStateValue },
  reducers: {
    clearApplicationProperties: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationProperties.pending, (state, action) => {
        state.value.status = "loading";
        state.value.appProperties = action.payload;
      })
      .addCase(fetchApplicationProperties.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.appProperties = action.payload;
      })
      .addCase(fetchApplicationProperties.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllAppProperties = (state) =>
  state.applicationProperties.value.appProperties;

export const getAppPropertyStatus = (state) =>
  state.applicationProperties.value.status;

export const { clearApplicationProperties } = applicationPropertySlice.actions;
// export const { clearApplicationStatus } = statusSlice.actions;

export default applicationPropertySlice.reducer;
