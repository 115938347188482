import React, { useEffect } from "react";
import Sidebar from "../Sidebar/CustomerSidebar";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { HiOutlineClipboardList } from "react-icons/hi";

import {
  customerById,
  getCustomerStatus,
  selectCustomer,
} from "../../features/customer";

import {
  selectAllPrograms,
  getProgramStatus,
  fetchPrograms,
} from "../../features/program";

import {
  fetchDropdownValues,
  getDropdownValueStatus,
} from "../../features/dropdownValue";

import {
  fetchCheckValues,
  getCheckValueStatus,
} from "../../features/checkValue";

import { AiOutlineLogout } from "react-icons/ai";
import MyApplications from "./Customer/MyApplications";

import { getUsersStatus } from "../../features/user";

import {
  getCustomerApplicationStatus,
  fetchApplications,
} from "../../features/application";

import acwdLogo from "../../assets/Acwdlogo-Hi-Res_png.png";
import hubLogo from "../../assets/WaterSavingsHub_logo.png";

import StartApplicationContainer from "./Customer/StartApplicationContainer";
import CustomerProfileContainer from "./Customer/CustomerProfileContainer";

const CustomerView = ({ props }) => {
  const dispatch = useDispatch();

  const usersStatus = useSelector(getUsersStatus);
  const customerStatus = useSelector(getCustomerStatus);
  const customer = useSelector(selectCustomer);
  const programStatus = useSelector(getProgramStatus);
  const programs = useSelector(selectAllPrograms);
  const applicationStatus = useSelector(getCustomerApplicationStatus);
  const dropdownStatus = useSelector(getDropdownValueStatus);
  const checkStatus = useSelector(getCheckValueStatus);

  useEffect(() => {
    //dont fetch unecessary resources on guest login
    const user_id = localStorage.getItem("user_id");
    if (customerStatus === "idle" && user_id != 123) {
      dispatch(customerById(parseInt(user_id)));
    }
    if (programStatus === "idle") {
      dispatch(fetchPrograms());
    }
    if (applicationStatus === "idle" && user_id != 123) {
      dispatch(fetchApplications());
    }

    if (dropdownStatus === "idle" && user_id != 123) {
      dispatch(fetchDropdownValues());
    }

    if (checkStatus === "idle" && user_id != 123) {
      dispatch(fetchCheckValues());
    }
    if (user_id == 123) {
      props.selectMenu(1);
    }
  }, [customerStatus, programStatus, applicationStatus, dispatch]);

  return (
    <div className="main-content transistion flex min-h-screen duration-200 dark:bg-gray-900">
      <div className="w-full">
        {localStorage.getItem("user_id") != 123 && (
          <Sidebar
            showSidebar={props.sidebarOpen}
            toggleSidebar={props.toggleSidebar}
            selectMenu={props.selectMenu}
            menuOptions={props.menuOptions}
            type={props.role}
          />
        )}

        <div className="flex w-full flex-col">
          <div className="sticky top-0 z-50 flex h-[5rem] w-full items-center justify-end gap-12 border-b-2 border-gray-200 bg-[#7d7d7d] pr-8 font-semibold tracking-wide text-white shadow-xl dark:bg-gray-800">
            <div className="mr-auto flex gap-4 p-8 text-lg font-medium text-white">
              <img
                src={acwdLogo}
                alt="ACWD Logo"
                className="h-8 align-middle"
              />
              {/* <img
                src={hubLogo}
                alt="Water Savings Center"
                className="h-8 align-middle"
              /> */}
              <span className="font-montserrat">ACWD WATER SAVINGS CENTER</span>
            </div>
            <ul className="flex items-center gap-4 text-white">
              <li>
                {localStorage.getItem("role") === "ADMIN" && (
                  <h2 className="h-fit w-fit rounded-md bg-green-200 px-4 text-green-800 shadow-sm">
                    Admin
                  </h2>
                )}
                {localStorage.getItem("role") === "USER" && (
                  <h2 className="h-fit w-fit rounded-md bg-yellow-200 px-4 py-1 text-yellow-800 shadow-sm">
                    User
                  </h2>
                )}
                {localStorage.getItem("role") === "GUEST" && (
                  <h2 className="h-fit w-fit rounded-md bg-gray-100 px-4 py-1 text-gray-800 shadow-sm">
                    Guest
                  </h2>
                )}
                {localStorage.getItem("role") === "COUNTY" && (
                  <h2 className="h-fit w-fit rounded-md bg-gray-100 px-4 py-1 text-gray-800 shadow-sm">
                    Agency
                  </h2>
                )}
              </li>

              <li
                className="cursor-pointer rounded-lg hover:text-gray-200"
                onClick={() => {
                  props.logoutUser();
                }}
              >
                {localStorage.getItem("user_id") != 123 && (
                  <div className="flex gap-2">
                    <AiOutlineLogout size="24" />
                    <span className="">Logout</span>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <AnimatePresence exitBeforeEnter initial={false}>
            {props.menuOptions[0] && usersStatus === "loading" && (
              <p>Loading ...</p>
            )}
            {props.menuOptions[0] &&
              usersStatus === "succeeded" &&
              programs && (
                <MyApplications
                  props={{
                    sidebarOpen: props.sidebarOpen,
                    title: "My Applications",
                    icon: <HiOutlineClipboardList size="32" />,
                    programs: programs,
                    data: [],
                  }}
                />
              )}
            {props.menuOptions[0] && usersStatus === "failed" && (
              <p>failed to load resources</p>
            )}
            {props.menuOptions[1] && programs && (
              <StartApplicationContainer
                props={{ programs: programs, sidebarOpen: props.sidebarOpen }}
              />
            )}
            {props.menuOptions[2] && customer && (
              <CustomerProfileContainer
                props={{ sidebarOpen: props.sidebarOpen }}
              />
            )}
            {props.menuOptions[4] && <></>}
            {props.menuOptions[5] && <></>}
            {props.menuOptions[6] && <></>}
            {props.menuOptions[7] && <></>}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default CustomerView;
