import React, { useState, useRef } from "react";
import SidebarFooter from "./SidebarFooter";

import { IoIosHome } from "react-icons/io";
import { MdAccountTree, MdWaterDrop } from "react-icons/md";
import { HiUserGroup, HiOutlineDocumentReport } from "react-icons/hi";
import { FaUserCog } from "react-icons/fa";

import useDarkMode from "../../hook/useDarkMode";

import { BiExpand } from "react-icons/bi";

import { MdHomeWork } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";

import { FiMinimize2 } from "react-icons/fi";

import { AnimatePresence } from "framer-motion";

import { HiOutlineClipboardList } from "react-icons/hi";
import { AiOutlineFileAdd } from "react-icons/ai";

const marginLeft = {
  marginLeft: "1rem",
};

const Sidebar = ({
  toggleSidebar,
  showSidebar,
  selectMenu,
  menuOptions,
  type,
}) => {
  const [menuIndex, setMenuIndex] = useState(Array(8).fill(false));
  const [customerMenuIndex, setCustomerMenuIndex] = useState(
    Array(4).fill(false)
  );

  const menuToggleOn = (index) => {
    const tempArr = Array(8).fill(false);
    tempArr[index] = true;
    setMenuIndex(tempArr);
  };
  const menuToggleOff = () => {
    const tempArr = Array(8).fill(false);
    setMenuIndex(tempArr);
  };
  const customerMenuToggleOn = (index) => {
    const tempArr = Array(4).fill(false);
    tempArr[index] = true;
    setCustomerMenuIndex(tempArr);
    // console.log("customerMenu", customerMenuIndex);
  };
  const customerMenuToggleOff = () => {
    const tempArr = Array(4).fill(false);
    setCustomerMenuIndex(tempArr);
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 z-20 mt-[2rem] w-[14rem] transform font-sans text-white transition duration-200 ease-in-out ${
        showSidebar ? "" : "-translate-x-3/4"
      }`}
    >
      <div className="fixed left-0 top-0 flex min-h-screen w-[14rem] flex-col bg-[#7d7d7d] bg-gradient-to-b from-[#7d7d7d] to-[#cacaca] font-semibold tracking-wider text-white shadow-xl transition duration-200 dark:bg-gray-800 dark:bg-transparent">
        {/* <hr
          className={
            showSidebar
              ? "opacity-40 w-10/12 mx-auto mt-[2.5rem]"
              : "opacity-40 w-full mb-12 mt-[2.5rem]"
          }
        /> */}

        <AnimatePresence exitBeforeEnter initial={false}>
          <div className="mb-[4rem] mt-[3rem] font-sans text-lg font-medium tracking-wider">
            {type === "ADMIN" && (
              <ul
                class="ml-[.5rem] mt-[2rem] flex flex-col gap-3"
                style={marginLeft}
              >
                <li
                  className={`flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[0] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(0, "ADMIN");
                  }}
                  onMouseOver={() => {
                    menuToggleOn(0);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(0);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[0] === true && !menuOptions[0]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[0] && "bg-wc-green"}`}
                    >
                      <IoIosHome
                        size={`${showSidebar ? "22" : "32"}`}
                        className={`${menuOptions[0] && "text-white"}`}
                      />
                    </span>
                  </div>
                  {/* 
                  {!showSidebar && optionHover && (
                    <motion.div
                      className={`w-fit h-10 absolute bg-wc-green top-0 bottom-0 right-[-110px] text-white rounded p-4 text flex justify-center items-center`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Options
                    </motion.div>
                  )} */}

                  <span
                    className={`${
                      showSidebar ? "basis-8/12 tracking-wide" : "hidden"
                    } `}
                  >
                    Home
                  </span>
                </li>

                {/* applications */}
                <li
                  className={`mt-1 flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[1] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    // menuToggle(1);
                    selectMenu(1, "ADMIN");
                  }}
                  onMouseOver={() => {
                    menuToggleOn(1);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(1);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[1] === true && !menuOptions[1]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[1] && "bg-wc-green"}`}
                    >
                      <MdAccountTree
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[1] && "text-white"}`}
                      />
                    </span>
                    {/* {!showSidebar && altHover && (
                    <motion.div
                      className="w-fit h-10 absolute bg-wc-green top-0 bottom-0 right-[-150px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Alternatives
                    </motion.div>
                  )} */}
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Applications
                  </span>
                </li>

                <li
                  className={`mt-1 flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${showSidebar ? "w-11/12" : "w-full"} ${
                    menuOptions[2] && "bg-wc-green shadow-md"
                  }`}
                  onClick={() => {
                    // menuToggle(2);
                    selectMenu(2, "ADMIN");
                  }}
                  onMouseOver={() => {
                    menuToggleOn(2);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(2);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[2] === true && !menuOptions[2]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[2] && "bg-wc-green"}`}
                    >
                      <MdWaterDrop
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[2] && "text-white"}`}
                      />
                    </span>
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Programs
                  </span>
                </li>
                <li
                  className={`flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[3] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(3, "ADMIN");
                  }}
                  onMouseOver={() => {
                    menuToggleOn(3);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(3);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[3] === true && !menuOptions[3]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[3] && "bg-wc-green"}`}
                    >
                      <HiUserGroup
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[3] && "text-white"}`}
                      />
                    </span>
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Customers
                  </span>
                </li>
                {/* properties */}
                <li
                  className={`flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[4] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(4, "ADMIN");
                  }}
                  onMouseOver={() => {
                    menuToggleOn(4);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(4);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[4] === true && !menuOptions[4]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[4] && "bg-wc-green"}`}
                    >
                      <MdHomeWork
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[4] && "text-white"}`}
                      />
                    </span>
                    {/* {!showSidebar && altSummaryHover && (
                    <motion.div
                      className="w-fit h-18 absolute bg-wc-green top-0 bottom-0 right-[-148px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Alternatives Summary
                    </motion.div>
                  )} */}
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Properties
                  </span>
                </li>

                {/* <li
                  className={`flex items-center cursor-pointer rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  }  ${menuOptions[5] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(5, "ADMIN");
                    // toggleAltSummary();
                  }}
                  onMouseOver={() => {
                    menuToggleOn(5);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(5);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "w-8 h-8  mr-2 text-black flex items-center justify-center rounded "
                        : "w-16 h-16 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                    }`}
                  >
                    <span
                      className={`rounded   ${
                        menuIndex[5] === true && !menuOptions[5]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[5] && "bg-wc-green"}`}
                    >
                      <HiOutlineDocumentReport
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[5] && "text-white"}`}
                      />
                    </span>
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Reports
                  </span>
                </li> */}
                <li
                  className={`flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[6] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(6, "ADMIN");
                    // toggleAltSummary();
                  }}
                  onMouseOver={() => {
                    menuToggleOn(6);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(6);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[6] === true && !menuOptions[6]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[6] && "bg-wc-green"}`}
                    >
                      <FaUserShield
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[6] && "text-white"}`}
                      />
                    </span>
                    {/* {!showSidebar && altSummaryHover && (
                    <motion.div
                      className="w-fit h-18 absolute bg-wc-green top-0 bottom-0 right-[-148px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Alternatives Summary
                    </motion.div>
                  )} */}
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Admin
                  </span>
                </li>
                <li
                  className={`flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[7] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(7, "ADMIN");
                    // toggleAltSummary();
                  }}
                  onMouseOver={() => {
                    menuToggleOn(7);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(7);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[7] === true && !menuOptions[7]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[7] && "bg-wc-green"}`}
                    >
                      <FaUserCog
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[7] && "text-white"}`}
                      />
                    </span>
                    {/* {!showSidebar && altSummaryHover && (
                    <motion.div
                      className="w-fit h-18 absolute bg-wc-green top-0 bottom-0 right-[-148px] text-white rounded p-4 text flex justify-center items-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Alternatives Summary
                    </motion.div>
                  )} */}
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Profile
                  </span>
                </li>
              </ul>
            )}
            {type === "CONTRACTOR" && (
              <ul
                class="ml-[.5rem] mt-[2rem] flex flex-col gap-3"
                style={marginLeft}
              >
                <li
                  className={`mt-1 flex cursor-pointer items-center rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[0] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    // menuToggle(1);
                    // selectMenu(1, "ADMIN");
                  }}
                  onMouseOver={() => {
                    menuToggleOn(0);
                  }}
                  onMouseOut={() => {
                    menuToggleOff(0);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                        : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                    }`}
                  >
                    <span
                      className={`rounded ${
                        menuIndex[0] === true && !menuOptions[1]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[0] && "bg-wc-green"}`}
                    >
                      <MdAccountTree
                        size={`${showSidebar ? "28" : "32"}`}
                        className={`${menuOptions[0] && "text-white"}`}
                      />
                    </span>
                  </div>
                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Applications
                  </span>
                </li>
              </ul>
            )}
            <div className="mt-3 flex cursor-pointer justify-end pr-4">
              <span className="rounded border border-white shadow hover:bg-gray-300">
                {showSidebar ? (
                  <FiMinimize2
                    size="24"
                    className="text-white"
                    onClick={() => {
                      toggleSidebar();
                    }}
                  />
                ) : (
                  <BiExpand
                    size="24"
                    className="text-white"
                    onClick={() => {
                      toggleSidebar();
                    }}
                  />
                )}
              </span>
            </div>
            {type === "CUSTOMER" && (
              <div className="flex flex-col gap-4">
                <ul className="ml-[1.8rem] flex flex-col gap-8">
                  <li
                    className="flex cursor-pointer items-center rounded"
                    onClick={() => {
                      selectMenu(0, "CUSTOMER");
                    }}
                    onMouseOver={() => {
                      customerMenuToggleOn(0);
                    }}
                    onMouseOut={() => {
                      customerMenuToggleOff(0);
                    }}
                  >
                    <div
                      className={`"" ${
                        showSidebar
                          ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                          : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                      }`}
                    >
                      <span
                        className={`rounded ${
                          customerMenuIndex[0] === true && !menuOptions[0]
                            ? "text-gray-200"
                            : "text-gray-500"
                        } ${menuOptions[0] && "bg-gray-200"}`}
                      >
                        <HiOutlineClipboardList
                          size={`${showSidebar ? "22" : "32"}`}
                        />
                      </span>
                    </div>

                    <span
                      className={`${showSidebar ? "basis-8/12" : "hidden"}`}
                    >
                      My Applications
                    </span>
                  </li>
                  <li
                    className="flex cursor-pointer items-center rounded"
                    onClick={() => {
                      selectMenu(1, "CUSTOMER");
                    }}
                    onMouseOver={() => {
                      customerMenuToggleOn(1);
                    }}
                    onMouseOut={() => {
                      customerMenuToggleOff(1);
                    }}
                  >
                    <div
                      className={`"" ${
                        showSidebar
                          ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                          : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                      }`}
                    >
                      <span
                        className={`rounded ${
                          customerMenuIndex[1] === true && !menuOptions[1]
                            ? "text-gray-200"
                            : "text-gray-500"
                        } ${menuOptions[1] && "bg-gray-200"}`}
                      >
                        <AiOutlineFileAdd
                          size={`${showSidebar ? "22" : "32"}`}
                        />
                      </span>
                    </div>

                    <span
                      className={`${showSidebar ? "basis-8/12" : "hidden"}`}
                    >
                      Start New Application
                    </span>
                  </li>
                  <li
                    className="flex cursor-pointer items-center rounded"
                    onClick={() => {
                      selectMenu(2, "CUSTOMER");
                    }}
                    onMouseOver={() => {
                      customerMenuToggleOn(2);
                    }}
                    onMouseOut={() => {
                      customerMenuToggleOff(2);
                    }}
                  >
                    <div
                      className={`"" ${
                        showSidebar
                          ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                          : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                      }`}
                    >
                      <span
                        className={`rounded ${
                          customerMenuIndex[2] === true && !menuOptions[2]
                            ? "text-gray-200"
                            : "text-gray-500"
                        } ${menuOptions[2] && "bg-gray-200"}`}
                      >
                        <AiOutlineFileAdd
                          size={`${showSidebar ? "22" : "32"}`}
                        />
                      </span>
                    </div>

                    <span
                      className={`${showSidebar ? "basis-8/12" : "hidden"}`}
                    >
                      Home
                    </span>
                  </li>
                  <li
                    className="flex cursor-pointer items-center rounded"
                    onClick={() => {
                      selectMenu(1, "CUSTOMER");
                    }}
                    onMouseOver={() => {
                      customerMenuToggleOn(3);
                    }}
                    onMouseOut={() => {
                      customerMenuToggleOff(3);
                    }}
                  >
                    <div
                      className={`"" ${
                        showSidebar
                          ? "mr-2 flex h-8 w-8 items-center justify-center rounded text-black"
                          : "relative ml-auto flex h-16 w-16 items-center justify-center rounded text-black"
                      }`}
                    >
                      <span
                        className={`rounded ${
                          customerMenuIndex[3] === true && !menuOptions[3]
                            ? "text-gray-200"
                            : "text-gray-500"
                        } ${menuOptions[3] && "bg-gray-200"}`}
                      >
                        <AiOutlineFileAdd
                          size={`${showSidebar ? "22" : "32"}`}
                        />
                      </span>
                    </div>

                    <span
                      className={`${showSidebar ? "basis-8/12" : "hidden"}`}
                    >
                      Home
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </AnimatePresence>
        <hr
          className={
            showSidebar
              ? "mx-auto mb-12 w-10/12 opacity-40"
              : "mb-12 w-full opacity-40"
          }
        />
        {showSidebar && (
          <div className="flex flex-col items-center justify-center gap-4">
            {/* <span className="text-center text-light-green-reef">
              Powered By Woodard & Curran
            </span> */}
            <a
              className="w-10/12 cursor-pointer rounded-xl px-2 text-center tracking-wider text-blue-500"
              href="http://www.acwd.org"
              target="_blank"
            >
              www.acwd.org
            </a>
          </div>
        )}
        {/* 
        <SidebarFooter toggle={toggleSidebar} showSidebar={showSidebar} /> */}
      </div>
    </div>
  );
};

export default Sidebar;
