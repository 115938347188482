import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchProperties,
  getPropertyStatus,
  selectAllProperties,
} from "../../../features/properties";
import CustomerProfile from "../../User/CustomerProfileSettings";

const CustomerProfileContainer = ({ props }) => {
  const dispatch = useDispatch();
  const propertyList = useSelector(selectAllProperties);
  const propertyStatus = useSelector(getPropertyStatus);

  useEffect(() => {
    propertyStatus === "idle" && dispatch(fetchProperties());
  }, [propertyStatus, dispatch]);

  return (
    <div
      className={`h-full bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 p-16 ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content  overflow-auto">
        {propertyList ? (
          <CustomerProfile props={propertyList} />
        ) : (
          <span>Loading property list...</span>
        )}
      </div>
    </div>
  );
};

export default CustomerProfileContainer;
