import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  budgets: [],
  status: "idle",
  error: null,
  programBudgets: [],
  budgetStatus: "idle",
  budgetError: null,
};

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/program/budget";
const local_base_url = "http://localhost:3000/program/budget";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchBudgets = createAsyncThunk("budget/fetchBudget", async () => {
  try {
    const res = await axios.post(
      `${active_base_url}/fetch-all`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const program_budgets = res.data.data;
    return program_budgets;
  } catch (err) {
    console.log(err);
  }
});
export const budgetById = createAsyncThunk(
  "budget/fetchBudgetById",
  async (id) => {
    try {
      const res = await axios.post(`${active_base_url}/fetch-by-id`, id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const program_budgets = res.data.data;
      console.log("programBudgets", program_budgets);
      return program_budgets;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addBudget = createAsyncThunk("budget/add", async (budget) => {
  try {
    const res = await axios.post(`${active_base_url}/add`, budget, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const addBudget = res.data.data;
    return addBudget;
  } catch (err) {
    console.log(err);
  }
});

export const updateBudget = createAsyncThunk(
  "budget/update",
  async (budget) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, budget, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const updatedBudget = res.data.data;
      return updatedBudget;
    } catch (err) {
      console.log(err);
    }
  }
);

export const programBudgetSlice = createSlice({
  name: "programBudget",
  initialState: { value: initStateValue },
  reducers: {
    // clearProgramFields: (state, action) => {
    //   state.value = initStateValue;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBudgets.pending, (state, action) => {
        state.value.status = "loading";
        state.value.budgets = action.payload;
      })
      .addCase(fetchBudgets.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.budgets = action.payload;
      })
      .addCase(fetchBudgets.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      })
      .addCase(budgetById.pending, (state, action) => {
        state.value.budgetStatus = "loading";
        state.value.programBudgets = action.payload;
      })
      .addCase(budgetById.fulfilled, (state, action) => {
        state.value.budgetStatus = "succeeded";
        state.value.programBudgets = action.payload;
      })
      .addCase(budgetById.rejected, (state, action) => {
        state.value.budgetStatus = "failed";
        state.value.budgetError = action.error.message;
      });
  },
});

export const selectAllProgramBudgets = (state) =>
  state.programBudget.value.programBudgets;
export const getProgramBudgetStatus = (state) =>
  state.programBudget.value.budgetStatus;
export const selectAllBudgets = (state) => state.programBudget.value.budgets;
export const getBudgetStatus = (state) => state.programBudget.value.status;
// export const { clearProgramFields } = fieldSlice.actions;
export default programBudgetSlice.reducer;
