import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import AdminView from "../components/Containers/AdminView";
import CustomerView from "../components/Containers/CustomerView";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  getUsersStatus,
  logout,
  getLoggedUser,
} from "../features/user";
import { clearProperties } from "../features/properties";
import { clearStatusList } from "../features/status";
import {
  fetchCustomers,
  getCustomersStatus,
  logoutCustomer,
} from "../features/customer";
import { clearProgramUploads } from "../features/programUpload";
import { clearProgramFields } from "../features/programField";
import { clearPrograms } from "../features/program";
import { clearValues } from "../features/applicationValue";
import { clearApplications } from "../features/application";
import { clearDropdownValues } from "../features/dropdownValue";
import { clearCheckValues } from "../features/checkValue";

import { clearApplicationProperties } from "../features/applicationProperties";

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import useDarkMode from "../hook/useDarkMode";

import AuthVerify from "../utils/AuthVerify";

import { clearApplicationStatus } from "../features/applicationStatus";

const Dashboard = () => {
  const [colorTheme, setTheme] = useDarkMode();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const usersStatus = useSelector(getUsersStatus);
  const customersStatus = useSelector(getCustomersStatus);
  const loggedUser = useSelector(getLoggedUser);

  // //sidebar
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const openSidebar = () => setSidebarOpen(true);
  const closeSidebar = () => setSidebarOpen(false);
  const toggleSidebar = () => {
    sidebarOpen ? closeSidebar() : openSidebar();
  };

  // //clear user state
  const logoutUser = () => {
    localStorage.clear();
    dispatch(logout());
    dispatch(clearProperties());
    dispatch(clearStatusList());
    dispatch(clearProgramUploads());
    dispatch(clearProgramFields());
    dispatch(clearPrograms());
    dispatch(clearValues());
    dispatch(clearApplications());
    dispatch(clearApplicationStatus());
    dispatch(clearDropdownValues());
    dispatch(clearApplicationProperties());
    navigate("/");
  };

  //clear customer state
  const clearCustomer = () => {
    localStorage.clear();
    dispatch(logoutCustomer());
    dispatch(clearProperties());
    dispatch(clearStatusList());
    dispatch(clearProgramUploads());
    dispatch(clearProgramFields());
    dispatch(clearPrograms());
    dispatch(clearValues());
    dispatch(clearApplications());
    dispatch(clearApplicationStatus());
    dispatch(clearDropdownValues());
    dispatch(clearApplicationProperties());
    dispatch(clearCheckValues());
    navigate("/");
  };

  //menu
  const menu = Array(7).fill(false);
  const customerMenu = Array(3).fill(false);

  const defaultSelect = [true];
  const [menuOptions, setMenuOptions] = useState([...defaultSelect, ...menu]);
  const [customerMenuOptions, setCustomerMenuOptions] = useState([
    ...defaultSelect,
    ...customerMenu,
  ]);

  const selectMenu = (index, permission) => {
    if (permission === "ADMIN") {
      const tempArr = Array(8).fill(false);
      tempArr[index] = true;
      setMenuOptions(tempArr);
    } else {
      const tempArr = Array(4).fill(false);
      tempArr[index] = true;
      setCustomerMenuOptions(tempArr);
    }
  };

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const userId = parseInt(localStorage.getItem("user_id"));
    if (userId !== 123) {
      console.log("fetching users and customers", userId);

      if (usersStatus === "idle") {
        dispatch(fetchUsers());
      }

      if (customersStatus === "idle") {
        dispatch(fetchCustomers());
      }

      setLoad(usersStatus !== "succeeded");
    }
  }, [usersStatus, customersStatus, dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ToastContainer theme="light" />
      {load ? (
        <div className="gap-18 sticky top-0 flex min-h-screen w-full items-center justify-center bg-wc-blue p-12 font-sans text-white">
          <h2 className="text-lg">Welcome to the ACWD Water Savings Center</h2>
          <ClimbingBoxLoader size={18} color={"#A3AEB9"} loading={load} />
        </div>
      ) : loggedUser &&
        (loggedUser.role === "ADMIN") | (loggedUser.role === "CONTRACTOR") ? (
        <AdminView
          props={{
            sidebarOpen: sidebarOpen,
            toggleSidebar: toggleSidebar,
            selectMenu: selectMenu,
            logoutUser: logoutUser,
            setTheme: setTheme,
            menuOptions: menuOptions,
            colorTheme: colorTheme,
            role: loggedUser.role,
          }}
        />
      ) : (
        <CustomerView
          props={{
            sidebarOpen: sidebarOpen,
            toggleSidebar: toggleSidebar,
            selectMenu: selectMenu,
            logoutUser: clearCustomer,
            setTheme: setTheme,
            menuOptions: customerMenuOptions,
            colorTheme: colorTheme,
            role: "CUSTOMER",
          }}
        />
      )}

      <AuthVerify
        props={{ logout: logoutUser, logoutCustomer: clearCustomer }}
      />
    </motion.div>
  );
};

export default Dashboard;
