import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { Button } from "../Ui/button";

//test
import { addStatus, fetchStatusList } from "../../features/status";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddStatusModal = ({ props }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    number: "",
    customerNumber: "",
    statusId: "",
    name: "",
    customerName: "",
    milestone: false,
    description: "",
    daysBeforeAlert: null,
    userPermissionControls: "",
    programId: parseInt(props.programId),
  });
  //methods
  //   const submit = async (e) => {
  //     try {
  //       e.preventDefault();
  //       if (checkEmptyFields(user) && passwordConfirmation(user)) {
  //         let addedUser = await dispatch(
  //           addUser({
  //             firstName: user.firstName,
  //             lastName: user.lastName,
  //             email: user.email,
  //             password: user.password,
  //             phone: user.phone,
  //             role: user.role,
  //           })
  //         );

  //         if (!addedUser.payload.errors) {
  //           await dispatch(fetchUsers());
  //           handleClose();
  //           setUser(initialUser);
  //         }
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  const submit = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      await dispatch(addStatus(status));
      await dispatch(fetchStatusList());
      props.handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop onClick={props.handleClose}>
      <motion.div
        className=" rounded w-[80rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="flex ">
          <div className="flex flex-col line-clamp-7 min-w-12 gap-4 w-full border-r p-8 border-gray-100">
            <div className=" rounded-t  flex gap-2 items-center w-full ">
              <h2 className="font-semibold text-start flex gap-2 mx-auto   text-gray-900 ">
                Add Status
              </h2>
            </div>
            <div>
              <div className="flex p-4 relative gap-4">
                <div className="flex flex-col w-full">
                  <span className="text-lg text-blue-reef">
                    Status Display Number
                  </span>
                  <span className="text-sm text-gray-400">
                    This is the number that will appear in the internal staff
                    workflow overview diagram (
                    <span className="text-blue-400">e.g.</span> "3", "3b", "X")
                  </span>
                  <input
                    type="text"
                    className="password text-black rounded  border-2 border-gray-100 h-[2.5rem] w-full"
                    placeholder=" Enter Text"
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        number: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div>
                    <span className="text-lg text-blue-reef">Status Name</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-400">
                      This is the name of the status that is visible to internal
                      staff only.
                    </span>
                    <input
                      type="text"
                      className="password text-black rounded  border-2 border-gray-100 w-full h-[2.5rem]"
                      placeholder=" Enter Text"
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-4 p-4">
                <div className="flex flex-col w-6/12">
                  <span className="text-lg text-blue-reef">Status Id</span>
                  <div className="flex flex-col gap-5">
                    <span className="text-sm text-gray-400">
                      Used by the system to order the statuses in the workflow
                      and dropdown options.
                    </span>
                    <input
                      type="text"
                      className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                      placeholder=" Enter Number"
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          statusId: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-6/12">
                  <span className="text-lg text-blue-reef">
                    Status Description
                  </span>
                  <span className="text-sm text-gray-400">
                    Describe the tasks that need to occur while an application
                    is in this status. This is visible to internal staff only.
                  </span>
                  <textarea
                    type="text"
                    className="password text-black rounded border-2 border-gray-100 h-[5rem] p-2"
                    placeholder=" Enter Text"
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="flex gap-4 justify-start p-4">
                <div className="flex flex-col w-full">
                  <span className="text-lg text-blue-reef">
                    Customer Status Display Number
                  </span>
                  <span className="text-sm text-gray-400">
                    This is the number that will appear in the customer-facing
                    workflow overview diagram.
                  </span>
                  <input
                    type="text"
                    className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                    placeholder=" Enter Text"
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        customerNumber: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <span className="text-lg text-blue-reef">
                    Customer Status Name
                  </span>
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-400">
                      This is the name of the status that will be visible to
                      customers.
                    </span>
                    <input
                      type="text"
                      className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                      placeholder=" Enter Text"
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          customerName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col p-12  w-full gap-4">
            <div className="flex justify-between gap-4">
              <div className="flex flex-col">
                <label>Milestone? </label>
                <div className="flex gap-4">
                  <div className="flex gap-4">
                    <input
                      // className="h-4 w-4"
                      type="checkbox"
                      onChange={() => {
                        setStatus((prev) => ({
                          ...prev,
                          milestone: !status.milestone,
                        }));
                      }}
                    />
                    <span className="text-sm text-gray-400">
                      Check this box if you expect most applications to land on
                      this status. This will cause it to appear by default in
                      the workflow diagram layout for both internal staff &
                      customers.
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-blue-reef">
                  Days Pending Before Alert
                </span>
                <span className="text-sm text-gray-400">
                  Enter the number of days a status can be active before it is
                  flagged for an alert or other follow-up.
                </span>
                <input
                  type="text"
                  className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                  placeholder=" Enter Number"
                  onChange={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      daysBeforeAlert: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            {/*
            <div className="flex flex-col">
              <span className="text-lg text-blue-reef">
                User Permission Controls
              </span>
              <span className="text-gray-400 text-sm">TBD</span>
              <input
                type="text"
                className="password text-black rounded  border-2 border-gray-100"
                placeholder=" Enter Text"
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    userPermissionControls: e.target.value,
                  }));
                }}
              />
            </div>
            */}
            {/* <motion.button
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 1 }}
              className="marginXAuto text-white bg-green-reef hover:bg--700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
              onClick={(e) => {
                submit(e);
              }}
            >
              Save
            </motion.button> */}
            <Button
              variant="outline"
              className="bg-green-reef text-white rounded w-3/12 mt-auto ml-auto"
              onClick={(e) => {
                submit(e);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddStatusModal;
