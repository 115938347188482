import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { selectAllUsers } from "../../features/user";
import { useSelector } from "react-redux";
import moment from "moment";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const ApplicationStatus = ({ props }) => {
  const [statusComment, setStatusComment] = useState(props.status.comment);

  useEffect(() => {
    setStatusComment(props.status.comment);
  });

  const updateStatusComment = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/status/status-log/update`
          : `http://localhost:3000/status/status-log/update`;

      const res = await axios.post(
        url,
        { id: props.status.id, comment: e },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedStatusLog = res.data.data;
      props.getStatusLogs();
      console.log(updatedStatusLog);
    } catch (err) {
      console.log(err);
    }
  };

  const [user, setUser] = useState(null);
  const users = useSelector(selectAllUsers);

  useEffect(() => {
    if (users) {
      //fix can either be user or customber, need a property to check.
      const temp = users.filter((user) => {
        return user.id === props.status.userId;
      });

      setUser(temp[0]);
    }
  });

  const [asignee, setAsignee] = useState(null);
  useEffect(() => {
    if (users) {
      const temp = users.filter((user) => {
        return user.id === props.status.staffAssignmentId;
      });
      setAsignee(temp[0]);
    }
  });

  return (
    <tr className={`rounded-lg dark:text-white text-black text-start text-sm `}>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        <Moment format="MM/DD/YYYY">
          {moment.utc(props.status.createdAt).format("MM/DD/YYYY")}
        </Moment>
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {props.status.statusNumber !== "null"
          ? props.status.statusNumber
          : "n/a"}
      </td>

      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {props.status.statusName !== "null" ? props.status.statusName : "n/a"}
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {asignee ? (
          `${asignee.firstName} ${asignee.lastName}`
        ) : (
          <span>n/a</span>
        )}
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {user && user.firstName + " " + user.lastName}
      </td>
      <td className="p-3 text-blue-500 dark:text-white hover:underline r whitespace-nowrap">
        <textarea
          onChange={(e) => {
            updateStatusComment(e.target.value);
          }}
          defaultValue={statusComment}
          className="text-black border border-gray-200 rounded w-full h-[5rem] p-2"
        />
      </td>
    </tr>
  );
};

export default ApplicationStatus;
