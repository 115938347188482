import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  uploads: [],
  status: "idle",
  error: null,
};

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/program/upload";
const local_base_url = "http://localhost:3000/program/upload";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchUploads = createAsyncThunk(
  "upload/fetchUploads",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const uploads = res.data.data;
      return uploads;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addUpload = createAsyncThunk(
  "upload/addUpload",
  async (upload) => {
    try {
      const res = await axios.post(`${active_base_url}/add`, upload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addUpload = res.data.data;
      return addUpload;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteUpload = createAsyncThunk("upload/delete", async (id) => {
  try {
    const res = await axios.post(`${active_base_url}/delete`, id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const deletedUpload = res.data.data;
    return deletedUpload;
  } catch (err) {
    console.log(err);
  }
});

export const updateUpload = createAsyncThunk(
  "upload/update",
  async (upload) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, upload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const updatedUpload = res.data.data;
      return updatedUpload;
    } catch (err) {
      console.log(err);
    }
  }
);

export const uploadSlice = createSlice({
  name: "programUpload",
  initialState: { value: initStateValue },
  reducers: {
    clearProgramUploads: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUploads.pending, (state, action) => {
        state.value.status = "loading";
        state.value.uploads = action.payload;
      })
      .addCase(fetchUploads.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.uploads = action.payload;
      })
      .addCase(fetchUploads.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllUploads = (state) => state.programUpload.value.uploads;
export const getUploadStatus = (state) => state.programUpload.value.status;

export const { clearProgramUploads } = uploadSlice.actions;

export default uploadSlice.reducer;
