import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/dropdown/value";
const local_base_url = "http://localhost:3000/dropdown/value";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  dropdownValues: [],
  status: "idle",
  error: null,
};

export const fetchDropdownValues = createAsyncThunk(
  "dropdown/fetchDropdownValues",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const values = res.data.data;
      return values;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addDropdownValue = createAsyncThunk(
  "dropdown/addDropdownValue",
  async (value) => {
    try {
      console.log("value", value);
      const res = await axios.post(`${active_base_url}/add`, value, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addValue = res.data.data;
      return addValue;
    } catch (err) {
      console.log(err);
    }
  }
);

export const dropdownValueSlice = createSlice({
  name: "dropdownValue",
  initialState: { value: initStateValue },
  reducers: {
    clearDropdownValues: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDropdownValues.pending, (state, action) => {
        state.value.status = "loading";
        state.value.dropdownValues = action.payload;
      })
      .addCase(fetchDropdownValues.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.dropdownValues = action.payload;
      })
      .addCase(fetchDropdownValues.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllDropdownValues = (state) =>
  state.dropdownValue.value.dropdownValues;

export const getDropdownValueStatus = (state) =>
  state.dropdownValue.value.status;

export const { clearDropdownValues } = dropdownValueSlice.actions;

export default dropdownValueSlice.reducer;
