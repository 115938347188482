import { useEffect, useState } from "react";
import {
  passwordResetByUrl,
  validatePasswordReset,
} from "../features/passwordReset";
import { validateEmailConfirmation } from "../features/emailConfirmation";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function RegistrationSuccess() {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center h-screen gap-12">
        <div className="bg-test1 w-[21rem] h-[5rem] bg-cover flex "></div>
        <div>
          {
            "Your registration was successful. As a one-time account setup step, please check your email for an automated message with a link to verify your email and complete registration. "
          }
        </div>
      </div>
    </>
  );
}

export default RegistrationSuccess;
