import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/application/value";
const local_base_url = "http://localhost:3000/application/value";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  appValues: [],
  status: "idle",
  error: null,
};

export const fetchApplicationValues = createAsyncThunk(
  "application/fetchApplicationValues",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const values = res.data.data;
      return values;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addApplicationValue = createAsyncThunk(
  "application/addApplicationValue",
  async (value) => {
    try {
      const res = await axios.post(`${active_base_url}/add`, value, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addValue = res.data.data;
      return addValue;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteApplicationValue = createAsyncThunk(
  "application/deleteApplicationValue",
  async (id) => {
    try {
      const res = await axios.post(`${active_base_url}/delete`, id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const deleteValue = res.data.data;
      return deleteValue;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateApplicationValue = createAsyncThunk(
  "application/updateApplicationValue",
  async (value) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, value, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addValue = res.data.data;
      return addValue;
    } catch (err) {
      console.log(err);
    }
  }
);

export const valueSlice = createSlice({
  name: "applicationValue",
  initialState: { value: initStateValue },
  reducers: {
    clearValues: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationValues.pending, (state, action) => {
        state.value.status = "loading";
        state.value.appValues = action.payload;
      })
      .addCase(fetchApplicationValues.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.appValues = action.payload;
      })
      .addCase(fetchApplicationValues.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllValues = (state) =>
  state.applicationValue.value.appValues;

export const getValueStatus = (state) => state.applicationValue.value.status;

export const { clearValues } = valueSlice.actions;

export default valueSlice.reducer;
