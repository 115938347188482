import React from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";

import { setToast } from "../../utils/ToastNotification";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};
const UnlockApplicationModal = ({ props }) => {
  return (
    <Backdrop
      onClick={() => {
        props.closeAppModal();
      }}
    >
      <motion.div
        className="rounded-md max-w-lg min-w-sm bg-white dark:bg-wc-gradient dark:text-white bg-cover text-wc-blue
        shadow-md font-sans p-12 flex flex-col gap-4"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        {/* <h2 className="">{heading}</h2> */}
        <p className="">
          Are you sure? This will automatically send an email request to the
          customer.
        </p>
        <div className="flex gap-2 justify-center">
          <motion.button
            whileHover={{ scale: 1 }}
            whileTap={{ scale: 1 }}
            className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
            onClick={() => {
              props.closeAppModal();
            }}
          >
            Cancel
          </motion.button>
          <motion.button
            whileHover={{ scale: 1 }}
            whileTap={{ scale: 1 }}
            className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
            onClick={(e) => {
              props.unlockApplication();
              props.closeAppModal();
            }}
          >
            Send
          </motion.button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UnlockApplicationModal;
