import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setToast } from "./ToastNotification";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = ({ props }) => {
  let location = useLocation();

  useEffect(() => {
    const user = localStorage.getItem("token");

    if (user) {
      console.log("token", parseJwt(user));
      const decodedJwt = parseJwt(user);
      if (decodedJwt.exp * 1000 < Date.now()) {
        console.log("expired token");
        props.logout();
        props.logoutCustomer();
        const toastPayload = {
          index: 1,
          description: 1,
          type: "user",
        };
        setToast(toastPayload);
      }
    }

    if (!user) {
      props.logout();
      props.logoutCustomer();
    }
  }, [location, props]);

  return <></>;
};

export default AuthVerify;
