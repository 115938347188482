import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ProgramBudget from "./ProgramBudget";

const ProgramBudgetTable = ({ props }) => {
  console.log("Program Budget Table Properties:", props);

  const [programBudgets, setProgramBudgets] = useState(null);
  useEffect(() => {
    setProgramBudgets(props.programBudgets);
  }, [props.programBudgets]);
  return (
    <div className="rounded p-4">
      <table className="w-full shadow rounded boreder">
        <thead className="bg-gray-50 text-gray-900 border-b border-gray-200">
          <th className="p-3 text-sm font-semibold tracking-wider whitespace-nowrap text-start">
            Program Budget
          </th>
          <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap">
            Date Updated
          </th>
          <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap ">
            Changed By
          </th>
          <th className="p-3 text-sm font-semibold tracking-wider whitespace-nowrap text-start">
            Comment
          </th>
        </thead>
        <tbody className="divide-y">
          {programBudgets &&
            programBudgets.map((budget, index) => {
              return (
                <ProgramBudget
                  props={{
                    budget: budget,
                    index: index,
                    getBudgets: props.getBudgets,
                  }}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ProgramBudgetTable;
