import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  savings: [],
  status: "idle",
  error: null,
  applicationWaterSavings: [],
  applicationStatus: "idle",
  applicationError: null,
};

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/application/review-note";
const local_base_url = "http://localhost:3000/application/review-note";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

// export const fetchWaterSavings = createAsyncThunk(
//   "application/review-note/fetch",
//   async () => {
//     try {
//       const res = await axios.post(
//         `${active_base_url}/fetch-all`,
//         {},
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//         }
//       );
//       const waterSavings = res.data.data;
//       return waterSavings;
//     } catch (err) {
//       console.log(err);
//     }
//   }
// );
// export const waterSavingsById = createAsyncThunk(
//   "application/review-note/fetchBudgetById",
//   async (id) => {
//     try {
//       const res = await axios.post(`${active_base_url}/fetch-by-id`, id, {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });
//       const waterSavings = res.data.data;

//       return waterSavings;
//     } catch (err) {
//       console.log(err);
//     }
//   }
// );

export const addReviewNote = createAsyncThunk(
  "application/review-note/add",
  async (note) => {
    try {
      console.log("hi");
      const res = await axios.post(`${active_base_url}/add`, note, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addWaterReviewNote = res.data.data;
      return addReviewNote;
    } catch (err) {
      console.log(err);
    }
  }
);

// export const updateBudget = createAsyncThunk(
//   "application/water-savings/update",
//   async (budget) => {
//     try {
//       const res = await axios.post(`${active_base_url}/update`, budget, {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });
//       const updatedWaterSavings = res.data.data;
//       return updatedWaterSavings;
//     } catch (err) {
//       console.log(err);
//     }
//   }
// );

export const reviewNoteSlice = createSlice({
  name: "reviewNotes",
  initialState: { value: initStateValue },
  reducers: {
    // clearProgramFields: (state, action) => {
    //   state.value = initStateValue;
    // },
  },
  //   extraReducers(builder) {
  //     builder
  //       .addCase(fetchWaterSavings.pending, (state, action) => {
  //         state.value.status = "loading";
  //         state.value.savings = action.payload;
  //       })
  //       .addCase(fetchWaterSavings.fulfilled, (state, action) => {
  //         state.value.status = "succeeded";
  //         state.value.savings = action.payload;
  //       })
  //       .addCase(fetchWaterSavings.rejected, (state, action) => {
  //         state.value.status = "failed";
  //         state.value.error = action.error.message;
  //       })
  //       .addCase(waterSavingsById.pending, (state, action) => {
  //         state.value.applicationStatus = "loading";
  //         state.value.applicationWaterSavings = action.payload;
  //       })
  //       .addCase(waterSavingsById.fulfilled, (state, action) => {
  //         state.value.applicationStatus = "succeeded";
  //         state.value.applicationWaterSavings = action.payload;
  //       })
  //       .addCase(waterSavingsById.rejected, (state, action) => {
  //         state.value.applicationStatus = "failed";
  //         state.value.applicationError = action.error.message;
  //       });
  //   },
});

// export const selectAllWaterSavings = (state) =>
//   state.waterSavings.value.savings;
// export const getWaterSavingsStatus = (state) => state.waterSavings.value.status;
// export const selectApplicationWaterSavings = (state) =>
//   state.waterSavings.value.applicationWaterSavings;
// export const getApplicationWaterSavingsStatus = (state) =>
//   state.waterSavings.value.applicationStatus;

export default reviewNoteSlice.reducer;
