import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import Backdrop from "../Misc/Backdrop";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const ProgramImageModal = ({
  handleClose,
  text,
  setUsers,
  allUsers,
  image,
}) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className=" rounded bg-gray-50 dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow font-sans p-8"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="">
          {" "}
          <img
            src={image}
            alt={image}
            className="w-full object-cover object-center"
            style={{ height: "48rem" }}
          />
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ProgramImageModal;
