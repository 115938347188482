import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import WaterSavingsTable from "./WaterSavingsTable";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const WaterSavingsLog = ({ props }) => {
  console.log("water savings log", props);
  const dispatch = useDispatch();

  // const programBudgetLogs = useSelector(selectAllProgramBudgets);

  //   const initialField = {
  //     fieldName: "",
  //     fieldDescription: "",
  //     required: false,
  //     fieldType: "",
  //     programId: props.programId,
  //   };

  const [waterSavings, setWaterSavings] = useState(null);

  const getWaterSavings = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/water-savings/fetch-by-id`
          : `http://localhost:3000/application/water-savings/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.modalController.modalAppId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const water_savings = res.data.data;
      console.log("water savings", water_savings);
      water_savings.sort((a, b) => {
        return a.id - b.id;
      });
      console.log("water_savings", water_savings);
      setWaterSavings(water_savings);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWaterSavings();
  }, [props]);

  return (
    <Backdrop
      onClick={() => {
        props.waterSavingsController.closeWaterSavingsModal();
        getWaterSavings();
      }}
    >
      <motion.div
        className=" rounded w-[70rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans "
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 min-w-12">
          <div className=" rounded w-full flex gap-2 items-center justify-start ">
            <h2 className="text-lg font-medium text-center flex gap-2 text-blue-reef">
              Water Savings Log
            </h2>
          </div>

          {/* {waterSavings && (
            <WaterSavings props={{ waterSavings: waterSavings }} />
          )} */}
          {/* {budgets && (
            <ProgramBudgetTable
              props={{ programBudgets: budgets, getBudgets: getBudgets }}
            />
          )} */}
          {waterSavings && (
            <WaterSavingsTable props={{ waterSavings: waterSavings }} />
          )}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default WaterSavingsLog;
