import React from "react";
import { motion } from "framer-motion";
import CommunicationLog from "./CommunicationLog";

const CustomerContactTable = ({ props }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`application-table bg-white dark:bg-gray-700 w-full duration-400 ease-in-out transition-spacing  font-sans flex flex-col gap-4 mb-3`}
    >
      {/* <Header /> */}
      <div className="overflow-auto w-full text-white rounded h-100 shadow">
        <table className="table-auto w-full text-left ">
          <thead className="sticky top-0 border">
            <tr className="bg-wc-green  text-gray-50 border border-gray-100">
              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap w-3/12">
                Date
              </th>
              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap w-3/12">
                Staff Name
              </th>
              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap w-6/12">
                Note
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {props.communicationHistory &&
              props.communicationHistory
                .sort((a, b) => {
                  return b.id - a.id;
                })
                .map((comm, index) => {
                  return (
                    <CommunicationLog
                      props={{
                        communicationHistory: comm,
                        setNote: props.setNote,
                        index: index,
                      }}
                      key={index}
                    />
                  );
                })}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default CustomerContactTable;
