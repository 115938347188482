import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Moment from "react-moment";
// import { updateBudget } from "../../features/programBudgetLog";
import { selectAllUsers } from "../../features/user";
import ReactQuill, { Quill } from "react-quill";

import { useSelector } from "react-redux";
import moment from "moment";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const CommunicationLog = ({ props }) => {
  const updateWaterSaving = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/water-savings/update`
          : `http://localhost:3000/application/water-savings/update`;

      const res = await axios.post(
        url,
        { id: props.waterSaving.id, comment: e },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedWaterSaving = res.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const updateNote = async (e) => {
    try {
      console.log("NOOOOTE: ", note);
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/communication-history/update`
          : `http://localhost:3000/application/communication-history/update`;

      const res = await axios.post(
        url,
        { id: props.communicationHistory.id, value: note },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedNote = res.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const [user, setUser] = useState(null);
  const users = useSelector(selectAllUsers);

  useEffect(() => {
    if (users) {
      const temp = users.filter((user) => {
        return user.id === props.communicationHistory.userId;
      });
      console.log("user", temp[0]);
      setUser(temp[0]);
    }
  });

  const [note, setNote] = useState(null);
  useEffect(() => {
    if (note) {
      updateNote(note);
    }
  }, [note]);

  return (
    <tr className={`rounded-lg dark:text-white text-black text-start p-3 `}>
      <td className="p-3 dark:text-white  whitespace-nowrap">
        {/* <Moment format="MM/DD/YYYY">
          {moment
            .utc(props.communicationHistory.createdAt)
            .format("MM/DD/YYYY")}
        </Moment> */}
        <Moment format="MM/DD/YYYY">
          {props.communicationHistory.createdAt}
        </Moment>
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {user && `${user.firstName} ${user.lastName}`}
      </td>
      <td className="p-3 text-gray-900 dark:text-white whitespace-nowrap">
        {/* fix in future there shouldn't be two inputs determined by budgetComment */}
        {/* <textarea
          onChange={(e) => {
            // updateWaterSaving(e.target.value);
            // props.setNote(e.target.value);
            setNote(e.target.value);
          }}
          defaultValue={props.communicationHistory.value}
          className="text-black border border-gray-200 rounded w-full"
        /> */}
        <ReactQuill
          theme="snow"
          className="w-full"
          value={props.communicationHistory.value}
          onChange={setNote}
        />

        {/* {budgetComment === "" && (
          <textarea
            onChange={(e) => {
              setBudgetComment(e.target.value);
            }}
            defaultValue={budgetComment}
            className="text-black border border-gray-200 rounded"
          />
        )} */}
      </td>
    </tr>
  );
};

export default CommunicationLog;
