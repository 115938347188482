import React, { useState } from "react";
import { motion } from "framer-motion";

import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";

const Status = ({ status, index, props }) => {
  const [deleteHover, setDeleteHover] = useState(false);
  const deleteMouseOver = () => {
    setDeleteHover(true);
  };
  const deleteMouseOut = () => {
    setDeleteHover(false);
  };
  const [editHover, setEditHover] = useState(false);
  const editMouseOver = () => {
    setEditHover(true);
  };
  const editMouseOut = () => {
    setEditHover(false);
  };

  return (
    <tr
      onClick={(e) => {
        e.stopPropagation();
        props.statusController.openStatusUpdateModal(status);
      }}
      className={`rounded dark:text-white text-black text-start hover:bg-blue-50 `}
    >
      <td className="pl-2 py-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {status.number}
      </td>
      <td className="pl-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {status.customerNumber}
      </td>
      <td className="pl-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {status.name}
      </td>
      <td className="pl-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {status.customerName}
      </td>

      <td className="pl-2 text-sm whitespace-nowrap">
        {status.daysBeforeAlert}
      </td>
      <td className="pl-2 text-sm text-gray-700 whitespace-nowrap flex gap-1 justify-start">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            props.statusController.openStatusUpdateModal(status);
          }}
          //   onMouseOver={editMouseOver}
          //   onMouseOut={editMouseOut}
        >
          <BsFillPencilFill
            size="18"
            className="cursor-pointer dark:text-white"
          />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        {localStorage.getItem("role") === "ADMIN" && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={(e) => {
              e.stopPropagation();
              props.statusController.openStatusDeleteModal(status.id);
            }}
            // onMouseOver={deleteMouseOver}
            // onMouseOut={deleteMouseOut}
          >
            <BsFillTrashFill
              size="18"
              className="cursor-pointer dark:text-white"
            />
            {deleteHover && (
              <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
                delete
              </div>
            )}
          </motion.button>
        )}
      </td>
    </tr>
  );
};

export default Status;
