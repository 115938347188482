import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FcHome, FcDepartment, FcCamcorder, FcLandscape } from "react-icons/fc";
import RegisterContact from "./RegisterContact";
import RegisterAccount from "./RegisterAccount";
import Header from "../Misc/Header";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};

const Register = ({ props }) => {
  const [viewAccount, setViewAccount] = useState(false);
  const openAccount = () => {
    setViewAccount(true);
  };
  const closeAccount = () => {
    setViewAccount(false);
  };
  return (
    <motion.div
      className="backdrop absolute top-0 left-0 w-full h-full  bg-wc-blue flex items-center justify-center overflow-auto z-50 font-sans"
      variants={slideIn}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* <header className="w-full absolute top-0 flex justify-end p-4">
        <div className=" cursor-pointer rounded-full border-2 border-black w-[2rem] h-[2rem] flex justify-center items hover:bg-white">
          <span
            onClick={() => {
              props.closeShowRegister();
            }}
          >
            x
          </span>
        </div>
      </header> */}
      <div className="main-container w-full flex gap-12 p-12 justify-center">
        {viewAccount === false ? (
          <RegisterContact
            props={{
              closeShowRegister: props.closeShowRegister,
              openAccount: openAccount,
              internal: 0,
            }}
          />
        ) : (
          <RegisterAccount
            props={{
              closeAccount: closeAccount,
              closeShowRegister: props.closeShowRegister,
              type: "external",
            }}
          />
        )}
      </div>
    </motion.div>
  );
};

export default Register;
