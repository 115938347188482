import React, { useEffect, useState } from "react";

const Status = ({
  props,
  index,
  currentStep,
  customer,
  statusLength,
  status,
}) => {
  const [passed, setPassed] = useState(false);
  useEffect(() => {
    if (parseInt(props.statusId) === parseInt(status.statusId)) {
      setPassed(true);
    } else {
      setPassed(false);
    }
  }, [props, status]);

  return (
    <div>
      {props.milestone === true || passed === true ? (
        <div className="status w-[5rem] flex flex-col  ">
          <div
            className={`circleWrapper flex rounded-full relative transition-all duration-600 s ${
              index < statusLength - 1 &&
              "after:z-10 after:w-[9rem] after:h-[.5rem] after:absolute after:top-0 after:bottom-0 after:mt-[1.7rem]"
            } after:bg-gray-400 :`}
          >
            <div
              className={`rounded-full border-4 w-[4rem] h-[4rem] flex justify-center items-center z-20 transition-all duration-800  ${
                passed === true
                  ? "border-[#3e6992] bg-[#487baa]"
                  : "border-gray-500 bg-gray-400 "
              } ${
                props.statusNumber === "X" && currentStep === 12 && "bg-red-500"
              } `}
            >
              <span className="text-white z-50">
                {customer ? props.customerNumber : props.number}
              </span>
            </div>
          </div>

          <span>{customer ? props.customerName : props.name}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Status;
