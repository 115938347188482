import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/application/status";
const local_base_url = "http://localhost:3000/application/status";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  statusList: [],
  status: "idle",
  error: null,
};

export const fetchApplicationStatus = createAsyncThunk(
  "application/fetchApplicationStatus",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const statusList = res.data.data;
      return statusList;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addApplicationStatus = createAsyncThunk(
  "application/addApplicationStatus",
  async (status) => {
    try {
      const res = await axios.post(`${active_base_url}/add`, status, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addStatus = res.data.data;
      return addStatus;
    } catch (err) {
      console.log(err);
    }
  }
);
export const updateApplicationStatus = createAsyncThunk(
  "application/updateApplicationStatus",
  async (status) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, status, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const updateStatus = res.data.data;
      return updateStatus;
    } catch (err) {
      console.log(err);
    }
  }
);

export const statusSlice = createSlice({
  name: "applicationStatus",
  initialState: { value: initStateValue },
  reducers: {
    clearApplicationStatus: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationStatus.pending, (state, action) => {
        state.value.status = "loading";
        state.value.statusList = action.payload;
      })
      .addCase(fetchApplicationStatus.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.statusList = action.payload;
      })
      .addCase(fetchApplicationStatus.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllStatus = (state) =>
  state.applicationStatus.value.statusList;

export const getStatus = (state) => state.applicationStatus.value.status;

export const { clearValues } = statusSlice.actions;
export const { clearApplicationStatus } = statusSlice.actions;

export default statusSlice.reducer;
