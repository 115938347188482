import React, { useState, useEffect } from "react";
import Property from "./CustomerProperty";
import RingLoader from "react-spinners/RingLoader";
import ReactPaginate from "react-paginate";
import { selectAllApplications } from "../../features/application";
import { useDispatch, useSelector } from "react-redux";

const CustomerPropertyTable = ({ props, applicationList }) => {
  console.log("Customer property table props: ", props.propertyList);

  const [customerProperties, setCustomerProperties] = useState([]);
  useEffect(() => {
    if (props.propertyList) {
      const temp = props.propertyList?.filter((el) => {
        const checked = el.customerProperty.filter((cust) => {
          return cust.customerId === parseInt(localStorage.getItem("user_id"));
        });
        return checked.length > 0;
      });
      console.log("customerProperties****: ", temp);
      setCustomerProperties(temp);
    }
  }, [props.propertyList]);
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(0);
  const propertiesPerPage = 100;
  const pagesVisited = pageNumber * propertiesPerPage;
  const displayProperties = customerProperties
    ? customerProperties
        .slice(pagesVisited, pagesVisited + propertiesPerPage)
        .map((property, index) => {
          return (
            <Property
              props={{ property: property, applications: applicationList }}
              index={index}
              key={index}
            />
          );
        })
    : null;
  const pageCount = props.propertyList
    ? Math.ceil(props.propertyList?.length / propertiesPerPage)
    : 0;
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  console.log(
    "Display properties in customer properties page: ",
    customerProperties
  );
  console.log("What is used to display stuff: ", props);

  // redo of load screen
  return props.propertyList ? (
    <div className="">
      <table className="min-w-full border border-gray-100">
        <thead className="border-b-2 border-gray-200 bg-blue-reef text-white">
          <tr>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Property Address
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap">
              Account ID
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap">
              Account Status
            </th>

            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Property Type
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Owner
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Tenant
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Account Holder
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Landscaper
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Property Manager
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Landlord
            </th>
            <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap ">
              Other Relationship to Property
            </th>
          </tr>
        </thead>
        {props.propertyList && applicationList && (
          <tbody className="divide-y">{displayProperties}</tbody>
        )}
      </table>
      {props.propertyList?.length > 100 && false && (
        <ReactPaginate
          className="flex gap-2 justify-center items-center p-4"
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName="text-blue-reef tracking-wide"
          nextLinkClassName="text-blue-reef tracking-wide"
          disabledClassName={"paginationDisabled"}
          activeClassName="rounded-md border p-2 bg-blue-reef text-white"
        />
      )}
    </div>
  ) : (
    <div className="flex justify-center items-center">
      <RingLoader size={100} color={"#1FBABF"} />
    </div>
  );
};

export default CustomerPropertyTable;
