import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import statusReducer from "./features/status";
import customerReducer from "./features/customer";
import propertyReducer from "./features/properties";
import programReducer from "./features/program";
import programFieldReducer from "./features/programField";
import programUploadReducer from "./features/programUpload";
import applicationReducer from "./features/application";
import applicationValueReducer from "./features/applicationValue";
import applicationStatusReducer from "./features/applicationStatus";
import programBudgetLogReducer from "./features/programBudgetLog";
import dropdownValueReducer from "./features/dropdownValue";
import waterSavingsLogReducer from "./features/waterSavingsLog";
import reviewNotesReducer from "./features/reviewNotes";
import applicationPropertiesReducer from "./features/applicationProperties";
import locationReducer from "./features/location";
import referenceReducer from "./features/fileReference";
import checkValueReducer from "./features/checkValue";
import lookupOptionReducer from "./features/lookupOption";
import lookupCharacteristicReducer from "./features/lookupCharacteristic";
import emailTemplateReducer from "./features/emailTemplate";

import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  user: userReducer,
  status: statusReducer,
  customer: customerReducer,
  property: propertyReducer,
  program: programReducer,
  programField: programFieldReducer,
  programUpload: programUploadReducer,
  application: applicationReducer,
  applicationValue: applicationValueReducer,
  applicationStatus: applicationStatusReducer,
  programBudgetLog: programBudgetLogReducer,
  dropdownValue: dropdownValueReducer,
  checkValue: checkValueReducer,
  lookupOption: lookupOptionReducer,
  lookupCharacteristic: lookupCharacteristicReducer,
  waterSavingsLog: waterSavingsLogReducer,
  reviewNote: reviewNotesReducer,
  applicationProperties: applicationPropertiesReducer,
  location: locationReducer,
  reference: referenceReducer,
  emailTemplate: emailTemplateReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
});

const persistor = persistStore(store);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
