import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setToast } from "../utils/ToastNotification";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/customer";
const local_base_url = "http://localhost:3000/customer";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  loggedCustomer: {},
  customers: [],
  customer: {},
  status: "idle",
  loginStatus: "idle",
  customerStatus: "idle",
  error: null,
  loginError: null,
  customerError: null,
};

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const customers = res.data.data;
      console.log("fetchCustomers:", customers);
      return customers;
    } catch (err) {
      console.log(err);
    }
  }
);

export const customerById = createAsyncThunk(
  "customers/fetchCustomer",
  async (customerId) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-by-id`,
        { id: customerId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const customer = res.data.data;
      return customer;
    } catch (err) {
      console.log(err);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customers/createCustomer",
  async (user) => {
    try {
      const res = await axios.post(`${active_base_url}/add`, user, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addCustomer = res.data.data;
      return addCustomer;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/updateCustomerr",
  async (user) => {
    try {
      console.log("user: ", user);
      const res = await axios.post(`${active_base_url}/update`, user, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const updateCustomer = res.data.data;
      console.log("update: ", updateCustomer);

      localStorage.setItem("firstName", updateCustomer.firstName);
      localStorage.setItem("lastName", updateCustomer.lastName);
      localStorage.setItem("email", updateCustomer.email);
      localStorage.setItem("phone", updateCustomer.phone);
      return updateCustomer;
    } catch (err) {
      console.log(err);
    }
  }
);

export const registerCustomer = createAsyncThunk(
  "customers/registerCustomer",
  async (user) => {
    try {
      const res = await axios.post(`${active_base_url}/registration`, user);
      const addCustomer = res.data.data;
      return addCustomer;
    } catch (err) {
      console.log(err);
    }
  }
);

export const loginCustomer = createAsyncThunk(
  "customers/loginCustomer",
  async (customer) => {
    try {
      const res = await axios.post(`${active_base_url}/login`, customer);
      const loggedCustomer = res.data.data;
      console.log("addCustomer", loggedCustomer);
      localStorage.setItem("user_id", loggedCustomer.id);
      localStorage.setItem("firstName", loggedCustomer.firstName);
      localStorage.setItem("lastName", loggedCustomer.lastName);
      localStorage.setItem("email", loggedCustomer.email);
      localStorage.setItem("phone", loggedCustomer.phone);
      localStorage.setItem("role", "CUSTOMER");
      localStorage.setItem("token", loggedCustomer.token);
      return loggedCustomer;
    } catch (err) {
      console.log(err);
      const toastPayload = {
        index: 1,
        description: 0,
        type: "user",
      };
      setToast(toastPayload);
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: { value: initStateValue },
  reducers: {
    logoutCustomer: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.value.status = "loading";
        state.value.customers = action.payload;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      })
      .addCase(customerById.pending, (state, action) => {
        state.value.customerStatus = "loading";
        state.value.customer = { ...action.payload };
      })
      .addCase(customerById.fulfilled, (state, action) => {
        state.value.customerStatus = "succeeded";
        state.value.customer = { ...action.payload };
      })
      .addCase(customerById.rejected, (state, action) => {
        state.value.customerStatus = "failed";
        state.value.customerError = action.error.message;
      })
      .addCase(loginCustomer.pending, (state, action) => {
        state.value.loginStatus = "loading";
        state.value.loggedCustomer = { ...action.payload };
      })
      .addCase(loginCustomer.fulfilled, (state, action) => {
        state.value.loginStatus = "succedded";
        state.value.loggedCustomer = { ...action.payload };
      })
      .addCase(loginCustomer.rejected, (state, action) => {
        state.value.loginStatus = "failed";
        state.value.loginError = action.error.message;
      });
  },
});

export const selectAllCustomers = (state) => state.customer.value.customers;
export const getCustomersStatus = (state) => state.customer.value.status;
export const getLoggedCustomer = (state) => state.customer.value.loggedCustomer;
export const getCustomerStatus = (state) => state.customer.value.customerStatus;
export const selectCustomer = (state) => state.customer.value.customer;

export const { logoutCustomer } = customerSlice.actions;

export default customerSlice.reducer;
