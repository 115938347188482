import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { FaUserPlus } from "react-icons/fa";
import { Button } from "../Ui/button";

import { fetchStatusList, updateStatus } from "../../features/status";
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const UpdateStatusModal = ({ props }) => {
  const dispatch = useDispatch();
  console.log("radf", props);
  const [status, setStatus] = useState({
    id: props.statusController.status.id,
    number: props.statusController.status.number,
    name: props.statusController.status.name,
    customerNumber: props.statusController.status.customerNumber,
    customerName: props.statusController.status.customerName,
    milestone: props.statusController.status.milestone,
    statusId: props.statusController.status.statusId,
    description: props.statusController.status.description,
    userPermissionControls:
      props.statusController.status.userPermissionControls,
    daysBeforeAlert: props.statusController.status.daysBeforeAlert,
  });

  const saveStatus = async () => {
    try {
      await dispatch(updateStatus(status));
      await dispatch(fetchStatusList());
      props.statusController.closeStatusUpdateModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={props.statusController.closeStatusUpdateModal}>
      {/* <div className="w-full p-12 flex flex-col line-clamp-7 min-w-12 gap-4">
          <div className=" rounded w-full flex gap-2 items-center justify-center ">
            <h2 className="text-lg font-bold text-center flex gap-2 text-blue-reef">
              <FaUserPlus size="28" />
              Update Status
            </h2>
          </div>
          <div className="flex gap-2 justify-start">
            <div className="flex flex-col w-full">
              <span className="text-lg text-blue-reef">Number</span>
              <input
                type="text"
                className="password text-black rounded  border-2 border-gray-100"
                defaultValue={status.number}
                placeholder=" Enter Number"
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    number: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <span className="text-lg text-blue-reef">Name</span>
              <input
                type="text"
                className="password text-black rounded  border-2 border-gray-100"
                placeholder=" Enter Text"
                defaultValue={status.name}
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex gap-2 justify-start">
            <div className="flex flex-col w-full">
              <span className="text-lg text-blue-reef">Customer Number</span>
              <input
                type="text"
                className="password text-black rounded  border-2 border-gray-100"
                placeholder=" Enter Number"
                defaultValue={status.customerNumber}
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    customerNumber: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <span className=" text-blue-reef">Customer Name</span>
              <input
                type="text"
                className="password text-black rounded  border-2 border-gray-100"
                placeholder=" Enter Text"
                defaultValue={status.customerName}
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    customerName: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <label>Milestone? </label>
            <input
              type="checkbox"
              checked={status.milestone}
              onChange={() => {
                setStatus((prev) => ({
                  ...prev,
                  milestone: !status.milestone,
                }));
              }}
            />
          </div>

          <div className="flex flex-col">
            <span className="text-lg text-blue-reef">Status Id</span>
            <input
              type="text"
              className="password text-black rounded  border-2 border-gray-100"
              placeholder=" Enter Number"
              defaultValue={status.statusId}
              onChange={(e) => {
                setStatus((prev) => ({
                  ...prev,
                  statusId: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-lg text-blue-reef">Description</span>
            <input
              type="text"
              className="password text-black rounded  border-2 border-gray-100"
              placeholder=" Enter Description"
              defaultValue={status.description}
              onChange={(e) => {
                setStatus((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-lg text-blue-reef">
              User Permission Controls
            </span>
            <input
              type="text"
              className="password text-black rounded  border-2 border-gray-100"
              placeholder=" Enter Description"
              defaultValue={status.userPermissionControls}
              onChange={(e) => {
                setStatus((prev) => ({
                  ...prev,
                  userPermissionControls: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col">
            <span className=" text-blue-reef">
              Days Pending Before Alert
            </span>
            <input
              type="text"
              className="password text-black rounded  border-2 border-gray-100"
              placeholder=" Enter Text"
              defaultValue={status.daysBeforeAlert}
              onChange={(e) => {
                setStatus((prev) => ({
                  ...prev,
                  daysBeforeAlert: e.target.value,
                }));
              }}
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="rounded-md p-2 bg-green-reef dark:bg-wc-grey text-white flex items-center justify-center m-2 h-button "
            onClick={(e) => {
              saveStatus(e);
            }}
          >
            SAVE
          </motion.button>
        </div> */}
      <motion.div
        className="rounded w-[80rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="flex ">
          <div className="p-8 flex flex-col line-clamp-7 min-w-12 gap-4 w-full border-r border-gray-100">
            <div className=" rounded  flex gap-2 items-center justify-center ">
              <h2 className="text-lg font-bold text-center flex gap-2 text-blue-reef">
                Edit Status
              </h2>
            </div>
            <div className="flex gap-4 relative">
              <div className="flex flex-col w-full">
                <span className="text-lg text-blue-reef">
                  Status Display Number
                </span>
                <span className="text-sm text-gray-400">
                  This is the number that will appear in the internal staff
                  workflow overview diagram (
                  <span className="text-blue-400">e.g.</span> "3", "3b", "X")
                </span>
                <input
                  type="text"
                  className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                  placeholder=" Enter Text"
                  defaultValue={status.number}
                  onChange={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      number: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col w-full">
                <div>
                  <span className="text-lg text-blue-reef">Status Name</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-400">
                    This is the name of the status that is visible to internal
                    staff only.
                  </span>
                  <input
                    type="text"
                    className="password text-black rounded  border-2 border-gray-100 w-full h-[2.5rem]"
                    placeholder=" Enter Text"
                    defaultValue={status.name}
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="flex flex-col w-6/12">
                <span className="text-lg text-blue-reef">Status Id</span>
                <div className="flex flex-col ">
                  <span className="text-sm text-gray-400">
                    Used by the system to order the statuses in the workflow and
                    dropdown options.
                  </span>
                  <input
                    type="text"
                    className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                    placeholder=" Enter Number"
                    defaultValue={status.statusId}
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        statusId: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col w-6/12">
                <span className="text-lg text-blue-reef">
                  Status Description
                </span>
                <span className="text-sm text-gray-400">
                  Describe the tasks that need to occur while an application is
                  in this status. This is visible to internal staff only.
                </span>
                <textarea
                  type="text"
                  className="password text-black rounded border-2 border-gray-100 h-[5rem] p-2"
                  placeholder=" Enter Text"
                  defaultValue={status.description ? status.description : ""}
                  onChange={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="flex gap-4 justify-start">
              <div className="flex flex-col w-full">
                <span className="text-lg text-blue-reef">
                  Customer Status Display Number
                </span>
                <span className="text-sm text-gray-400">
                  This is the number that will appear in the customer-facing
                  workflow overview diagram.
                </span>
                <input
                  type="text"
                  className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                  placeholder=" Enter Text"
                  defaultValue={status.customerNumber}
                  onChange={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      customerNumber: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col w-full">
                <span className="text-lg text-blue-reef">
                  Customer Status Name
                </span>
                <div className="flex flex-col">
                  <span className="text-sm text-gray-400">
                    This is the name of the status that will be visible to
                    customers.
                  </span>
                  <input
                    type="text"
                    className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                    placeholder=" Enter Text"
                    defaultValue={status.customerName}
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        customerName: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col p-12  w-full gap-4 ">
            <div className="flex justify-between gap-4">
              <div className="flex flex-col">
                <label>Milestone? </label>
                <div className="flex gap-4">
                  <div className="flex gap-4">
                    <input
                      type="checkbox"
                      className=""
                      checked={status.milestone}
                      onChange={() => {
                        setStatus((prev) => ({
                          ...prev,
                          milestone: !status.milestone,
                        }));
                      }}
                    />
                    <span className="text-sm text-gray-400">
                      Check this box if you expect most applications to land on
                      this status. This will cause it to appear by default in
                      the workflow diagram layout for both internal staff &
                      customers.
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-blue-reef">
                  Days Pending Before Alert
                </span>
                <span className="text-sm text-gray-400">
                  Enter the number of days a status can be active before it is
                  flagged for an alert or other follow-up.
                </span>
                <input
                  type="text"
                  className="password text-black rounded  border-2 border-gray-100 h-[2.5rem]"
                  placeholder=" Enter Number"
                  defaultValue={status.daysBeforeAlert}
                  onChange={(e) => {
                    setStatus((prev) => ({
                      ...prev,
                      daysBeforeAlert: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            {/*
            <div className="flex flex-col">
              <span className="text-lg text-blue-reef">
                User Permission Controls
              </span>
              <span className="text-gray-400 text-sm">TBD</span>
              <input
                type="text"
                className="password text-black rounded  border-2 border-gray-100"
                placeholder=" Enter Text"
                defaultValue={status.userPermissionControls}
                onChange={(e) => {
                  setStatus((prev) => ({
                    ...prev,
                    userPermissionControls: e.target.value,
                  }));
                }}
              />
            </div>
            */}
            {/* <motion.button
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 1 }}
              className="marginXAuto text-white bg-green-reef hover:bg--700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
              onClick={(e) => {
                saveStatus();
              }}
            >
              SAVE
            </motion.button> */}
            <Button
              variant="outline"
              className="bg-green-reef text-white rounded w-3/12 mt-auto ml-auto"
              onClick={(e) => {
                saveStatus();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateStatusModal;
