import React from "react";
import ProgramSummaryCircle from "./ProgramSummaryCircle";
import ProgramSummaryCircleSeparator from "./ProgramSummaryCircleSeparator";

const ProgramSummaryCardStatus = ({ props, data }) => {
  console.log("data: ", data);
  /* Circles and their separators */
  // map over the elements in data and return a circle for each one. have them output the raw html to a variable in the new array
  const circleArray = data
    .filter((y) => {
      return y.label !== "0";
    })
    .map((x) => {
      return <ProgramSummaryCircle label={x.label} />;
    });
  // make "bars" for n-1 the amount of elements there are. have them output the raw html to a variable in the new array
  const barHTML = <ProgramSummaryCircleSeparator />;
  // merge the arrays with the circle elements and the bar elements. alternate
  const stepArray = [];
  circleArray.map((x) => {
    stepArray.push(x);
    stepArray.push(barHTML);
  });
  // remove the last element since it is an unneeded bar
  stepArray.pop();

  /* Text under the circles */
  const textArray = data
    .filter((y) => {
      return y.label !== "0";
    })
    .map((x) => {
      return <span className="w-[6rem] text-sm break-normal">{x.name}</span>;
    });

  /* Text showing number of applications */
  const appArray = data
    .filter((y) => {
      return y.label !== "0";
    })
    .map((x) => {
      return (
        <span className="w-[6rem] text-md font-bold break-normal">
          {x.apps}
        </span>
      );
    });

  /* Text showing number of applications */
  const overArray = data
    .filter((y) => {
      return y.label !== "0";
    })
    .map((x) => {
      return (
        <span className="w-[6rem] text-md text-red-500 font-bold break-normal">
          {x.overdue ? x.overdue : ""}
        </span>
      );
    });

  return (
    <>
      <div className="flex flex-row w-full justify-center">
        {stepArray.map((x) => {
          return x;
        })}
      </div>
      <div className="flex flex-row w-full h-[4rem] text-center justify-center">
        {textArray.map((x) => {
          return x;
        })}
      </div>
      <div className="flex flex-row w-full h-[2rem] text-center justify-center">
        {appArray.map((x) => {
          return x;
        })}
      </div>
      <div className="flex flex-row w-full h-[2rem] text-center justify-center">
        {overArray.map((x) => {
          return x;
        })}
      </div>
    </>
  );
};

export default ProgramSummaryCardStatus;
