import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import ApplicationTable from "../Applications/ApplicationTable";
import { selectAllAppProperties } from "../../features/applicationProperties";
import { selectAllStatus } from "../../features/applicationStatus";

import { selectAllApplications } from "../../features/application";
import CustomerTable from "./CustomerTable";
import CustomerPopupModal from "../Customers/CustomerPopupModal";
import { formatAddress } from "../../utils/formatAddress";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const PropertyModal = ({ props }) => {
  console.log("propertyModal", props);
  const dispatch = useDispatch();

  const appProperties = useSelector(selectAllAppProperties);
  const statusList = useSelector(selectAllStatus);
  const [numOfApps, setNumOfApps] = useState(null);
  const [propertyApplications, setPropertyApplications] = useState(null);
  const apps = useSelector(selectAllApplications);
  const [waterSavings, setWaterSavings] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [customerProperties, setCustomerProperties] = useState(null);

  const fetchCustomerProperties = async (id) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/customer-property/fetch-by-id`
          : `http://localhost:3000/customer-property/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const cp = res.data.data;
      setCustomerProperties(cp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.modalController.property) {
      fetchCustomerProperties(props.modalController.property.id);
    }

    //fix here
    if (apps && props.modalController.property) {
      const appProp = appProperties
        ?.filter((x) => {
          return x.propertyId === props.modalController.property.id;
        })
        .map((y) => {
          return y.applicationId;
        })
        .map((z) => {
          return apps.filter((el) => {
            return el.id === z;
          })[0];
        });
      const temp = apps.filter((app) => {
        return app.propertyId === props.modalController.property.id;
      });
      const dup = [...temp, ...appProp];
      const uniqueData = Array.from(new Set(dup.map((obj) => obj.id))).map(
        //consider 0 applications
        (id) => {
          return dup.find((obj) => obj.id === id);
        }
      );

      const appStatusMap = [];

      uniqueData.map((x) => {
        const cs =
          statusList?.filter((y) => {
            return y.applicationId === x.id;
          }).length > 0
            ? statusList.filter((y) => {
                return y.applicationId === x.id;
              })[0]
            : null;
        if (cs?.statusId !== 0) {
          appStatusMap.push(x);
        }
      });

      console.log("UNIQUE DATA", appStatusMap);

      setNumOfApps(appStatusMap.length);
      setPropertyApplications(appStatusMap);

      const appWaterSavings = appStatusMap.map((el) => {
        return el.waterSavings;
      });
      if (appWaterSavings.length > 0) {
        const waterSavingsTotal = appWaterSavings.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue;
          }
        );
        setWaterSavings(waterSavingsTotal);
      }
      const cost = appStatusMap.map((el) => {
        return el.cost;
      });
      if (cost.length > 0) {
        const costTotal = cost.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        });
        setTotalCost(costTotal);
      }
    }
  }, [apps]);

  const [menu, setMenu] = useState(0);
  const changeMenu = (index) => {
    setMenu(index);
  };

  const [customerModal, setCustomerModal] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const openCustomerModal = (id) => {
    setCustomerId(id);
    setCustomerModal(true);
  };

  const [modalMenu, setModalMenu] = useState({
    menu: [true, false, false],
    setMenu: (payload) => {
      const temp = [false, false, false];
      temp[payload] = true;
      setModalMenu((prev) => ({
        ...prev,
        menu: temp,
      }));
    },
  });

  function numberComma(input) {
    console.log(input);
    let nf = new Intl.NumberFormat("en-US");
    input = nf.format(input);
    return input;
  }
  return (
    <Backdrop
      onClick={() => {
        props.modalController.closePropertyModal();
      }}
    >
      <motion.div
        className=" rounded w-[110rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans h-[50rem] overflow-auto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        {customerModal && customerId && (
          <CustomerPopupModal props={{ id: customerId }} />
        )}

        <div className="flex justify-center sticky top-0 w-full border border-gray-200  z-50 bg-white">
          <div
            className={` px-2 py-2 cursor-pointer flex justify-center w-full border-r rounded-l border-gray-200 ${
              modalMenu.menu[0] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              changeMenu(0);
              modalMenu.setMenu(0);
            }}
          >
            <span className="tracking-wider">Application History</span>
          </div>
          <div
            className={` px-2 py-2 cursor-pointer flex w-full justify-center  rounded-r bg-white border-gray-200 ${
              modalMenu.menu[1] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              changeMenu(1);
              modalMenu.setMenu(1);
            }}
          >
            <span className="tracking-wider ">Property Details</span>
          </div>
        </div>
        {menu === 1 && (
          <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 min-w-12">
            <div className="flex gap-8 justify-center">
              <div className="flex flex-col w-full">
                <span className=" text-blue-reef">Property ID</span>
                <input
                  type="text"
                  className="password text-black rounded  border border-gray-200 h-[2.5rem]"
                  placeholder=" Description"
                  name="uploadDescription"
                  disabled
                  value={props.modalController.property.id}
                />
              </div>
              <div className="flex flex-col w-full">
                <span className=" text-blue-reef">Associated Location ID</span>
                <input
                  type="text"
                  className="password text-black rounded  border border-gray-200 h-[2.5rem]"
                  placeholder=" N/A"
                  name="uploadDescription"
                  disabled
                  value={props.modalController.property.locationNo}
                />
              </div>
              <div className="flex flex-col w-full">
                <span className=" text-blue-reef">Cayenta Location Class</span>
                <input
                  type="text"
                  className="password text-black rounded  border border-gray-200 h-[2.5rem]"
                  placeholder=" N/A"
                  name="uploadDescription"
                  disabled
                  value={props.modalController.property.locationClass}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <span className=" text-blue-reef">Address</span>
              <input
                type="text"
                className="password text-black rounded  border border-gray-200 h-[2.5rem]"
                disabled
                placeholder=" Description"
                name="uploadDescription"
                defaultValue={formatAddress([
                  props.modalController.property?.mfcStreetNo,
                  props.modalController?.property?.streetPfxDir,
                  props.modalController?.property?.mfcStreetName,
                  props.modalController?.property?.streetNmSfx,
                  props.modalController?.property?.streetSfxDir,
                  props.modalController?.property?.secAddrId,
                  props.modalController?.property?.secAddrRange,
                ])}
              />
            </div>
            <div className="flex gap-8">
              <div className="flex flex-col w-full">
                <span className=" text-blue-reef">City</span>
                <input
                  type="text"
                  className="password text-black rounded  border border-gray-200  h-[2.5rem]"
                  placeholder=" Description"
                  name="uploadDescription"
                  disabled
                  value={`${props.modalController.property.city.trim()} 
               `}
                />
              </div>
              <div className="flex flex-col w-full">
                <span className=" text-blue-reef">State</span>
                <input
                  type="text"
                  disabled
                  className="password text-black rounded  border border-gray-200 h-[2.5rem]"
                  placeholder=" Description"
                  name="uploadDescription"
                  value={"CA"}
                />
              </div>
            </div>
            <div className="flex flex-col w-full mb-2 ">
              <span className=" text-blue-reef">Zip Code</span>
              {/* There is code on the zip input that catches a few db errors. this needs to be looked at / fixed */}
              <input
                type="text"
                disabled
                className="password text-black rounded  border border-gray-200 h-[2.5rem] w-3/12"
                placeholder=" Description"
                name="uploadDescription"
                value={
                  props.modalController.property.postalCode.trim()
                    ? props.modalController.property.postalCode.trim()
                    : props.modalController.property.provinceCd.trim() !== "CA"
                    ? props.modalController.property.provinceCd.trim()
                    : ""
                }
              />
            </div>
            <hr />
            <span className="font-semibold mt-8">
              Customers Registered to Property
            </span>
            {customerProperties && (
              <CustomerTable
                props={{
                  customerProperties: customerProperties,
                  openCustomerModal: openCustomerModal,
                }}
              />
            )}
          </div>
        )}

        {menu === 0 && propertyApplications ? (
          <div className="p-12 flex flex-col font-sans ">
            <div className="app-info flex gap-2 font-semibold">
              <span>{`${numberComma(
                propertyApplications?.length
              )} applications submitted`}</span>
              <span>|</span>
              <span>{`${numberComma(
                waterSavings
              )} of annual site water savings`}</span>
              <span>|</span>
              <span>${`${numberComma(totalCost)} program dollars spent`}</span>
            </div>
            <ApplicationTable
              props={{ applications: propertyApplications, view: "property" }}
            />
          </div>
        ) : null}
      </motion.div>
    </Backdrop>
  );
};

export default PropertyModal;
