import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import WaterSavings from "./WaterSavings";
import { useDispatch, useSelector } from "react-redux";
import AssociatedLocation from "./AssociatedLocation";

const AssociatedLocationTable = ({ props }) => {
  const [associatedProperties, setAssociatedProperties] = useState(null);

  const fetchAssociatedLocations = () => {
    const temp = props.appPropertyList.map((el) => {
      return {
        property: props.propertyList.filter((prop) => {
          return prop.id === el.propertyId;
        })[0],
        id: el.id,
      };
    });
    console.log("tiempasdfasdf", temp);
    setAssociatedProperties(temp);
  };

  useEffect(() => {
    fetchAssociatedLocations();
  }, [props.propertyList, props.appPropertyList]);

  return (
    <div className="divide-y overflow-auto rounded shadow w-full">
      <table className="w-full">
        <thead className="bg-blue-reef text-white">
          <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap ">
            Property ID
          </th>
          <th className="p-3  font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Location ID
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Address
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Actions
          </th>
        </thead>
        <tbody className="divide-y">
          {associatedProperties &&
            associatedProperties.map((property, index) => {
              return (
                <AssociatedLocation
                  props={{
                    property: property.property,
                    index: index,
                    id: property.id,
                  }}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AssociatedLocationTable;
