import React, { useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineLogout } from "react-icons/ai";
import { HiUserGroup, HiOutlineDocumentReport } from "react-icons/hi";
import { MdAccountTree, MdWaterDrop } from "react-icons/md";
import HomeContainer from "./Admin/HomeContainer";
import ApplicationsContainer from "./Admin/ApplicationsContainer";
import ProfileContainer from "./Admin/ProfileContainer";
import AdminContainer from "./Admin/AdminContainer";
import PropertiesContainer from "./Admin/PropertiesContainer";
import CustomersContainer from "./Admin/CustomersContainer";
import ProgramsContainer from "./Admin/ProgramsContainer";
import { getUsersStatus } from "../../features/user";

import {
  selectAllPrograms,
  getProgramStatus,
  fetchPrograms,
} from "../../features/program";
import { getUploadStatus, fetchUploads } from "../../features/programUpload";
import { getFieldStatus, fetchFields } from "../../features/programField";
import { fetchProperties, getPropertyStatus } from "../../features/properties";
import {
  fetchApplicationStatus,
  getStatus,
} from "../../features/applicationStatus";

import {
  fetchApplications,
  getApplicationStatus,
} from "../../features/application";
import {
  fetchStatusList,
  getStatusListStatus,
  fetchStatusLogs,
  getStatusLogStatus,
} from "../../features/status";

import acwdLogo from "../../assets/Acwdlogo-Hi-Res_png.png";
import hubLogo from "../../assets/WaterSavingsHub_logo.png";

const AdminView = ({ props }) => {
  const dispatch = useDispatch();
  const usersStatus = useSelector(getUsersStatus);

  const programs = useSelector(selectAllPrograms);
  const programStatus = useSelector(getProgramStatus);
  const uploadStatus = useSelector(getUploadStatus);
  const propertyStatus = useSelector(getPropertyStatus);
  const fieldStatus = useSelector(getFieldStatus);
  const appStatus = useSelector(getStatus);
  const statusListStatus = useSelector(getStatusListStatus);
  const applicationStatus = useSelector(getApplicationStatus);
  const statusLogStatus = useSelector(getStatusLogStatus);

  useEffect(() => {
    programStatus === "idle" && dispatch(fetchPrograms());
    applicationStatus === "idle" && dispatch(fetchApplications());
    uploadStatus === "idle" && dispatch(fetchUploads());
    propertyStatus === "idle" && dispatch(fetchProperties());
    fieldStatus === "idle" && dispatch(fetchFields());
    appStatus === "idle" && dispatch(fetchApplicationStatus());
    statusListStatus === "idle" && dispatch(fetchStatusList());
    statusLogStatus === "idle" && dispatch(fetchStatusLogs());
  }, [
    programStatus,
    applicationStatus,
    uploadStatus,
    propertyStatus,
    fieldStatus,
    appStatus,
    statusListStatus,
    statusLogStatus,
    dispatch,
  ]);

  return (
    <div className="main-content min-h-screen flex dark:bg-gray-900 transistion duration-200">
      <div className="w-full">
        <Sidebar
          showSidebar={props.sidebarOpen}
          toggleSidebar={props.toggleSidebar}
          selectMenu={props.selectMenu}
          menuOptions={props.menuOptions}
          type={props.role}
        />

        <div className="flex flex-col w-full ">
          <div className="w-full top-0 sticky bg-[#7d7d7d] dark:bg-gray-800 h-[5rem] flex justify-end items-center text-white border-b-2 border-gray-200 font-semibold tracking-wide gap-12 pr-8 z-50 shadow-xl">
            <div className="mr-auto flex p-8 gap-4 text-lg text-white font-medium">
              <img
                src={acwdLogo}
                alt="ACWD Logo"
                className="h-8 align-middle"
              />
              {/* <img
                src={hubLogo}
                alt="Water Savings Center"
                className="h-8 align-middle"
              /> */}
              <span className="font-montserrat">ACWD WATER SAVINGS CENTER</span>
            </div>
            <ul className="text-white flex gap-4 items-center ">
              <li
                className="hover:text-gray-200  cursor-pointer rounded-lg"
                onClick={() => {
                  props.logoutUser();
                }}
              >
                <div className="flex gap-2">
                  <AiOutlineLogout size="24" />
                  <span className="">Logout</span>
                </div>
              </li>
            </ul>
          </div>
          <AnimatePresence exitBeforeEnter initial={false}>
            {/* {props.menuOptions[0] && usersStatus === "loading" && (
              <p>Loading ...</p>
            )} */}
            {props.menuOptions[0] && props.role === "ADMIN" && (
              <HomeContainer props={{ sidebarOpen: props.sidebarOpen }} />
            )}
            {/* {props.menuOptions[0] && usersStatus === "failed" && (
              <p>failed to load resources</p>
            )} */}
            {props.menuOptions[1] && props.role === "ADMIN" && (
              <ApplicationsContainer
                props={{ sidebarOpen: props.sidebarOpen }}
              />
            )}
            {props.menuOptions[0] && props.role === "CONTRACTOR" && (
              <ApplicationsContainer
                props={{ sidebarOpen: props.sidebarOpen }}
              />
            )}
            {props.menuOptions[2] && props.role === "ADMIN" && (
              <ProgramsContainer
                props={{
                  sidebarOpen: props.sidebarOpen,
                  title: "Programs",
                  icon: <MdWaterDrop size="32" />,
                  programs: programs,
                  data: [
                    [
                      "Toilet Rebate Program",
                      "Drought Resistant Landscaping Program",
                      "Program 3",
                    ],
                    [
                      {
                        step: 1,
                        status_name: "Customer Submit/Update",
                        days_pending: 7,
                      },
                      {
                        step: 2,
                        status_name: "Agency Review",
                        days_pending: 28,
                      },
                      {
                        step: 3,
                        status_name: "Pending Installation",
                        days_pending: 180,
                      },
                      {
                        step: 4,
                        status_name: "Complete",
                        days_pending: null,
                      },
                    ],
                    [
                      {
                        field_name: "# of Bathrooms in Building:",
                        field_type: "Numeric",
                      },
                      {
                        field_name: "# of Tank Toilets in Building:",
                        field_type: "Numeric",
                      },
                      {
                        field_name: "# of Flushometer Toilets in Building:",
                        field_type: "Numeric",
                      },
                      {
                        field_name: "# of Urinals in Building:",
                        field_type: "Numeric",
                      },
                    ],
                    [
                      { description: "# of Bathrooms in Building:" },
                      { description: "# of Tank Toilets in Building:" },
                      {
                        description: "# of Flushometer Toilets in Building:",
                      },
                      {
                        description: "# of Urinals in Building:",
                      },
                    ],
                  ],
                }}
              />
            )}
            {props.menuOptions[3] && props.role === "ADMIN" && (
              <CustomersContainer
                props={{
                  sidebarOpen: props.sidebarOpen,
                  title: "Customers",
                  icon: <HiUserGroup size="32" />,
                  data: [
                    {
                      id: 1,
                      name: "John Doe",
                      property: "Residential",
                      address: "17 Main St",
                      associatedApplications: 180,
                      cumulativeSavings: "n/a",
                      cumulativeCosts: "n/a",
                    },
                    {
                      id: 1,
                      name: "John Doe 2",
                      address: "17 Main St",
                      property: "Residential",
                      associatedApplications: 180,
                      cumulativeSavings: "n/a",
                      cumulativeCosts: "n/a",
                    },
                  ],
                }}
              />
            )}
            {props.menuOptions[4] && props.role === "ADMIN" && (
              <PropertiesContainer props={{ sidebarOpen: props.sidebarOpen }} />
            )}

            {props.menuOptions[6] && props.role === "ADMIN" && (
              <AdminContainer props={{ sidebarOpen: props.sidebarOpen }} />
            )}
            {props.menuOptions[7] && props.role === "ADMIN" && (
              <ProfileContainer props={{ sidebarOpen: props.sidebarOpen }} />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AdminView;
