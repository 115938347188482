import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, fetchUsers } from "../../features/user";
import {
  checkEmptyFields,
  passwordConfirmation,
} from "../../utils/FormValidation";
import InputMask from "react-input-mask";
import { Button } from "../Ui/button";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};
const UpdateUserModal = ({
  handleClose,
  user_id,
  firstName,
  lastName,
  email,
  phone,
  role,
  deleted,
  setUsers,
  allUsers,
}) => {
  const dispatch = useDispatch();

  const initialUser = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    role: role,
    deleted: deleted,
  };

  const [updatedUser, setUpdatedUser] = useState(initialUser);

  const submit = async (e) => {
    try {
      console.log("hello", e);
      e.preventDefault();
      const deletedConvert = updatedUser.deleted === "true" ? true : false;
      if (checkEmptyFields(updatedUser)) {
        let addedUser = await dispatch(
          updateUser({
            user_id: user_id,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            email: updatedUser.email,
            phone: updatedUser.phone,
            role: updatedUser.role,
            deleted: deletedConvert,
          })
        );

        if (!addedUser.payload.errors) {
          dispatch(fetchUsers());
          handleClose();
          setUpdatedUser(initialUser);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="rounded w-[50rem] bg-white dark:bg-wc-gradient bg-cover text-wc-blue dark:text-white shadow font-sans"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className=" rounded-t p-2 flex gap-2 items-center justify-center bg-gray-50 w-full text-gray-500 border-b">
          <FaEdit size="24" />
          <h2 className="">Update User Profile</h2>
        </div>
        <div className="w-full px-12 py-4 flex flex-col gap-2 line-clamp-7 min-w-12">
          <span className="">User Information</span>
          <div className="name flex flex-row gap-4 text-black">
            <input
              type="text"
              className="rounded basis-6/12  border border-gray-200 h-[2.5rem]"
              placeholder=" First name"
              defaultValue={updatedUser.firstName}
              onChange={(e) => {
                setUpdatedUser((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }));
              }}
            />
            <input
              type="text"
              className="rounded basis-6/12 border border-gray-200 h-[2.5rem]"
              placeholder=" Last name"
              defaultValue={updatedUser.lastName}
              onChange={(e) => {
                setUpdatedUser((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="name flex flex-row gap-4 text-black mb-4">
            <input
              type="text"
              className="email text-black rounded border border-gray-200 basis-6/12 h-[2.5rem]"
              placeholder=" email"
              defaultValue={updatedUser.email}
              onChange={(e) => {
                setUpdatedUser((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
            <InputMask
              type="text"
              className="phone text-black rounded  border border-gray-200 basis-6/12 h-[2.5rem]"
              mask="(999) 999-9999"
              onChange={(e) => {
                setUpdatedUser((prev) => ({
                  ...prev,
                  phone: e.target.value,
                }));
              }}
              placeholder=" Phone"
              defaultValue={updatedUser.phone}
            />
          </div>
          <span className="">Account</span>
          <select
            name="role"
            id="role"
            className=" text-black rounded border border-gray-200 h-[2.5rem] p-2"
            onChange={(e) => {
              setUpdatedUser((prev) => ({
                ...prev,
                role: e.target.value,
              }));
            }}
            defaultValue={updatedUser.role}
          >
            <option disabled value="GUEST">
              Guest
            </option>
            <option disabled value="CUSTOMER">
              User
            </option>
            <option value="ADMIN">Admin</option>
            <option value="CONTRACTOR">Contractor</option>

            <option disabled value="COUNTY">
              County
            </option>
          </select>
          <div className="flex flex-col gap-1">
            <label>User status</label>
            <select
              className=" text-black rounded border border-gray-200 h-[2.5rem] p-2"
              defaultValue={updatedUser.deleted}
              onChange={(e) => {
                setUpdatedUser((prev) => ({
                  ...prev,
                  deleted: e.target.value,
                }));
              }}
            >
              <option defaultValue disabled>
                Set user status
              </option>
              <option value={false}>ACTIVE</option>
              <option value={true}>INACTIVE</option>
            </select>
          </div>
          <div className="flex gap-4 justify-center mt-3">
            {/* <motion.button
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 1 }}
              className="text-white bg-green-reef hover:bg--700 focus:ring-4 focus:ring-green-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
              onClick={submit}
            >
              Save
            </motion.button> */}
            <Button
              variant="outline"
              className="bg-green-reef text-white rounded w-2/12"
              onClick={(e) => {
                submit(e);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateUserModal;
