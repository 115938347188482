import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import WaterSavings from "./WaterSavings";

const WaterSavingsTable = ({ props }) => {
  const [waterSavings, setWaterSavings] = useState(null);
  useEffect(() => {
    setWaterSavings(props.waterSavings);
  });

  return (
    <div className="divide-y min-h-[25rem] overflow-auto">
      <table className="w-full">
        <thead className="border-b-2 border-gray-200 bg-gray-50 text-gray-900">
          <th className="p-3 text-sm font-medium tracking-wider text-start whitespace-nowrap ">
            Water Savings (gal per year)
          </th>
          <th className="p-3  font-medium tracking-wider text-sm text-start whitespace-nowrap ">
            Date Updated
          </th>
          <th className="p-3 font-medium tracking-wider text-sm text-start whitespace-nowrap ">
            Changed By
          </th>
          <th className="p-3 font-medium tracking-wider text-sm text-start whitespace-nowrap ">
            Comment
          </th>
        </thead>
        <tbody className="divide-y">
          {waterSavings &&
            waterSavings.map((saving, index) => {
              return (
                <WaterSavings props={{ waterSaving: saving, index: index }} />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default WaterSavingsTable;
