import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { addUser, fetchUsers } from "../../features/user";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { FaUserPlus } from "react-icons/fa";
import { Button } from "../Ui/button";
import { selectAllPrograms } from "../../features/program";
import DateRangePicker from "../Ui/DateRangePicker";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Ui/select";

import {
  checkEmptyFields,
  passwordConfirmation,
} from "../../utils/FormValidation";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddUserModal = ({ props }) => {
  const dispatch = useDispatch();

  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    role: "",
  };
  const [user, setUser] = useState(initialUser);
  const programList = useSelector(selectAllPrograms);
  const [programId, setProgramId] = useState(null);
  //methods
  const submit = async (e) => {
    try {
      e.preventDefault();
      if (checkEmptyFields(user) && passwordConfirmation(user)) {
        let addedUser = await dispatch(
          addUser({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            phone: user.phone,
            role: user.role,
          })
        );

        if (!addedUser.payload.errors) {
          await dispatch(fetchUsers());
          props.handleClose();
          setUser(initialUser);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop
      onClick={() => {
        props.handleClose();
      }}
    >
      <motion.div
        className="rounded w-full max-w-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-lg font-sans flex flex-col items-center"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-lg p-4 font-semibold border-b border-gray-300 w-full">
          Application Export
        </h2>

        <div className="p-6">
          <span className="block mb-4">
            Optional: filter only for applications in a “Complete” workflow step
            that was entered within this date range.
          </span>
          <DateRangePicker
            props={{
              startDate: props.startDate,
              setStartDate: props.setStartDate,
              endDate: props.endDate,
              setEndDate: props.setEndDate,
            }}
          />
        </div>
        <div className="flex flex-col justify-center gap-6 w-full p-6">
          <div className="w-full">
            <Button
              variant="outline"
              className="bg-blue-reef text-white rounded w-full py-2"
              onClick={() => {
                props.exportExcel();
              }}
            >
              Export Applications table (all Programs)
            </Button>
          </div>
          <div className="flex flex-col gap-4 w-full">
            <Select
              onValueChange={(id) => {
                setProgramId(id);
              }}
              className="w-full"
            >
              <SelectTrigger className="w-full rounded border border-gray-300">
                <SelectValue placeholder="Select program" />
              </SelectTrigger>
              <SelectContent className="bg-white border border-gray-300 rounded">
                {[...programList]
                  ?.sort((a, b) => {
                    return a.programName.toLowerCase() >
                      b.programName.toLowerCase()
                      ? 1
                      : b.programName.toLowerCase() >
                        a.programName.toLowerCase()
                      ? -1
                      : 0;
                  })
                  .map((x) => {
                    return (
                      <SelectItem value={x.id} className="cursor-pointer p-2">
                        {x.programName}
                      </SelectItem>
                    );
                  })}
              </SelectContent>
            </Select>
            <Button
              variant="outline"
              className="bg-blue-reef text-white w-full rounded py-2"
              onClick={() => {
                props.exportAppDetails(programId);
              }}
            >
              Export Application Details
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddUserModal;
