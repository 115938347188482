import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const descriptions = {
  program: [
    "Sucessfully Added Program",
    "Incomplete Program Fields",
    "Error Adding Program",
    "Program Successfully Saved",
    "Successfully Delete Program Upload",
  ],
  programField: ["Successfully Added Program Field"],
  user: [
    "Sorry - the username password combination you provided is incorrect. Please try again or click Reset Password.",
    "Session Expired",
  ],
  dropdown: ["max 10 dropdown values reached", "1 dropdown value required"],
  customer: [
    "Registration successful! Please login now.",
    "Error Registering",
    "Successfully updated contact information",
  ],
  application: [
    "Please Select a Property Before Submitting Application",
    "Missing Required Field",
    "Successfully Updated Application",
    "Select next status",
    "Message required",
    "Successfully unlocked customer application",
    "Error unlocking customer application",
    "Select an associated property",
    "Successfully resubmitted application",
    "Error - Location ID not found in Cayenta Location table",
    "Please select a next status and staff assignment",
    "Error importing applications",
    "Successfully imported applications",
    "Your application was submitted successfully! You can check its status in the My Applications tab.",
    "Your application was successfully saved for later. You can continue in the My Applications tab",
    "Confirmation email successfully sent",
    "Option already exists in My List",
  ],
};

export const setToast = (payload) => {
  console.log("prog", payload.description);

  //program
  if (payload.index === 0)
    toast(descriptions[payload.type][payload.description], { type: "success" });
  if (payload.index === 1)
    toast(descriptions[payload.type][payload.description], { type: "error" });
  if (payload.index === 2)
    toast(descriptions[payload.type][payload.description], { type: "warning" });
  if (payload.index === 3)
    toast(descriptions[payload.type][payload.description], { type: "Info" });

  //user
  if (payload.index === 4)
    toast(descriptions[payload.type][payload.description], { type: "error" });
};
