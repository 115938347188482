import React, { useEffect, useState } from "react";
import { selectAllApplications } from "../../features/application";
import { selectAllPrograms } from "../../features/program";
import { selectAllStatus } from "../../features/applicationStatus";
import { useDispatch, useSelector } from "react-redux";

const Customer = ({
  props,
  index,
  openDeleteModal,
  openUpdateModal,
  openPopupModal,
  openCustomerModal,
}) => {
  const appStatusList = useSelector(selectAllStatus);
  const applications = useSelector(selectAllApplications);
  const [customerApplications, setCustomerApplications] = useState([]);

  const programs = useSelector(selectAllPrograms);
  const [appWaterSavings, setAppWaterSavings] = useState(null);
  const [appProgramCost, setAppProgramCost] = useState(null);

  function numberComma(input) {
    console.log(input);
    let nf = new Intl.NumberFormat("en-US");
    input = nf.format(input);
    return input;
  }

  //filter applications by customer id on mount
  useEffect(() => {
    if (applications && programs) {
      const customerApplications = applications.filter((app) => {
        return app.customerId === props.id;
      });

      const customerApplicationStatus = customerApplications?.map((x) => {
        return appStatusList?.filter((y) => {
          return y.applicationId === x?.id && y?.statusId !== 0;
        }).length > 0
          ? customerApplications?.filter((z) => {
              return (
                z.id ===
                appStatusList.filter((y) => {
                  return y.applicationId === x?.id && y?.statusId !== 0;
                })[0].applicationId
              );
            })[0]
          : null;
      });

      const validCustomerApps = customerApplicationStatus.filter((x) => {
        return x !== null;
      });

      if (validCustomerApps.length > 0) {
        var appWaterSavings = 0;
        var programCost = 0;
        for (var i = 0; i < validCustomerApps.length; i++) {
          appWaterSavings += validCustomerApps[i].waterSavings;
          programCost += validCustomerApps[i].cost;
        }

        setAppWaterSavings(numberComma(appWaterSavings));
        setAppProgramCost(numberComma(programCost));
      }

      setCustomerApplications(validCustomerApps);
    }
  }, [applications, props.id, programs]);
  return (
    <tr
      className={`rounded-lg dark:text-white text-black text-start hover:bg-blue-50 cursor-pointer `}
      onClick={() => {
        openPopupModal(props.id);

        if (openCustomerModal) {
          openCustomerModal(props.id);
        }
      }}
    >
      <td className="p-3 py-1 text-sm text-blue-500 dark:text-white underline whitespace-nowrap">
        {props.id}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">{`${props.firstName} ${props.lastName}`}</td>
      <td
        className="p-3 text-sm dark:text-white hover cursor-pointer whitespace-nowrap"
        onClick={() => {
          openPopupModal(props.id);
        }}
      >
        {props.email}
      </td>

      {customerApplications && (
        <td className="p-3 text-sm whitespace-nowrap">
          {customerApplications.length}
        </td>
      )}
      <td className="p-3 text-sm whitespace-nowrap">
        {appWaterSavings ? appWaterSavings : 0}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        ${appProgramCost ? appProgramCost : 0}
      </td>
    </tr>
  );
};

export default Customer;
