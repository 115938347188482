import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};
const CloseWarningModal = ({ props }) => {
  //   console.log("dleete", deleteFunc, "id", id);
  return (
    <Backdrop>
      <motion.div
        className="rounded max-w-lg min-w-sm bg-white dark:bg-wc-gradient dark:text-white bg-cover text-wc-blue
        shadow-md font-sans p-12 flex flex-col gap-4"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        {/* <h2 className="">{heading}</h2>
        <p className="">{text}</p> */}
        <span>Are you sure you want to exit without saving?</span>
        <div className="flex justify-center">
          <motion.button
            whileHover={{ scale: 1.0 }}
            whileTap={{ scale: 1.0 }}
            className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
            onClick={() => {
              props.modalController.closeWarningModal();
            }}
          >
            Cancel
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.0 }}
            whileTap={{ scale: 1.0 }}
            className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
            onClick={() => {
              props.modalController.closeWarningModal();
              props.modalController.closeAppModal();
            }}
          >
            Exit
          </motion.button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default CloseWarningModal;
