import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { selectAllValues } from "../../features/applicationValue";
import { selectAllFields } from "../../features/programField";
import { selectAllPrograms } from "../../features/program";
import { formatFullAddress } from "../../utils/formatAddress";
import { selectAllStatus } from "../../features/applicationStatus";
import { selectAllAppProperties } from "../../features/applicationProperties";

const Property = ({ props, index, applications, modalController }) => {
  console.log("Property props , : ", props);

  const values = useSelector(selectAllValues);
  const fields = useSelector(selectAllFields);
  const programs = useSelector(selectAllPrograms);
  const statusList = useSelector(selectAllStatus);
  const appProperties = useSelector(selectAllAppProperties);

  const [propertyApps, setPropertyApps] = useState(null);
  const [waterSavings, setWaterSavings] = useState(null);
  const [programCost, setProgramCost] = useState(null);
  useEffect(() => {}, [propertyApps, waterSavings, programCost]);

  useEffect(() => {
    const associatedAppIds = appProperties?.filter((x) => {
      return x.propertyId === props.id;
    });
    const associatedApps = associatedAppIds?.map((x) => {
      return applications?.filter((y) => {
        return y?.id === x?.applicationId;
      })[0];
    });
    const temp = applications?.filter((app) => {
      return app?.propertyId === props?.id;
    });
    let tempCombined = null;

    if (associatedApps.length > 0) {
      tempCombined = [...associatedApps, ...temp];
    } else {
      tempCombined = temp;
    }
    const tempStatus = tempCombined.map((x) => {
      const s = statusList?.filter((y) => {
        return y?.applicationId === x?.id;
      })[0];
      if (s?.statusId !== 0) {
        return x;
      } else {
        return null;
      }
    });

    const tempFinal = tempStatus?.filter((x) => {
      return x !== null;
    });

    const uniqueData = Array.from(new Set(tempFinal?.map((obj) => obj.id))).map(
      //consider 0 applications
      (id) => {
        return tempFinal?.find((obj) => obj.id === id);
      }
    );
    setPropertyApps(uniqueData);
    if (uniqueData.length > 0) {
      //grab last water savings log for each application and total them up
      const ws = uniqueData?.filter((el) => {
        return el.waterSavings !== null;
      });
      if (ws.length > 0) {
        const water_savings = ws.map((el) => {
          return el.waterSavings;
        });
        const waterSavingsTotal = water_savings?.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue;
          }
        );

        setWaterSavings(numberComma(waterSavingsTotal));
      }

      //calculate cumulative program cost
      const cost = uniqueData?.filter((el) => {
        return el.cost !== null;
      });
      const costOnly = cost?.map((el) => {
        return el.cost;
      });
      //faulty data remove after new data
      if (costOnly.length > 0) {
        const appProgramCost = costOnly?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        });

        setProgramCost("$" + numberComma(appProgramCost));
      }
    } else {
      setWaterSavings(0);
      setProgramCost(0);
    }
  }, [applications, values, fields, programs, props.id]);

  function numberComma(input) {
    console.log(input);
    let nf = new Intl.NumberFormat("en-US");
    input = nf.format(input);
    return input;
  }

  return (
    <tr
      className={` dark:text-white text-black text-start cursor-pointer hover:bg-blue-50 `}
      onClick={() => {
        modalController.openPropertyModal(props, waterSavings, programCost);
      }}
    >
      <td className="p-3 py-1 text-sm whitespace-nowrap text-blue-reef underline">{`${props.id} `}</td>
      <td className="p-3 text-sm whitespace-nowrap">
        {formatFullAddress([
          props?.mfcStreetNo,
          props?.streetPfxDir,
          props?.mfcStreetName,
          props?.streetNmSfx,
          props?.streetSfxDir,
          props?.secAddrId,
          props?.secAddrRange,
          props?.city,
          props?.provinceCd,
          props?.postalCode,
        ])}
      </td>

      {propertyApps ? (
        <td className="p-3 text-sm whitespace-nowrap">{propertyApps.length}</td>
      ) : (
        <td className="p-3 text-sm whitespace-nowrap">0</td>
      )}
      {waterSavings ? (
        <td className="p-3 text-sm whitespace-nowrap">{waterSavings}</td>
      ) : (
        <td className="p-3 text-sm whitespace-nowrap">0</td>
      )}

      {programCost ? (
        <td className="p-3 text-sm whitespace-nowrap">{programCost}</td>
      ) : (
        <td className="p-3 text-sm whitespace-nowrap">0</td>
      )}
    </tr>
  );
};

export default Property;
