import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllApplications } from "../../features/application";
import { getApplicationStatus } from "../../features/application";
import CustomerApplication from "./CustomerApplication";
import HashLoader from "react-spinners/HashLoader";
import Paginate from "../Misc/Paginate";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const CustomerApplicationTable = ({ props }) => {
  const applications = useSelector(selectAllApplications);

  const [apps, setApps] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const fetchCustomerProperty = async (customerApps) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/customer/property/fetch-by-id`
          : `http://localhost:3000/customer/property/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: parseInt(localStorage.getItem("user_id")) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const customerProperties = res.data.data;
      const validAccountArr = customerProperties?.filter((cp) => {
        return cp.accountNo !== null;
      });
      const validAccounts = validAccountArr?.map((va) => {
        return va.accountNo;
      });

      let associatedApplications = [];
      for (let i = 0; i < validAccounts?.length; i++) {
        const assocApp = applications?.filter((app) => {
          return (
            app.accountNo === validAccounts[i] &&
            app.customerId !== parseInt(localStorage.getItem("user_id"))
          );
        });

        if (assocApp.length > 0) {
          associatedApplications = [...assocApp];
        }
      }

      const allCustomerApplications = customerApps
        ? [...associatedApplications, ...customerApps]
        : [...associatedApplications];
      setApps(allCustomerApplications);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const temp =
      applications?.filter((el) => {
        return el.customerId === parseInt(localStorage.getItem("user_id"));
      }).length > 0
        ? applications?.filter((el) => {
            return el.customerId === parseInt(localStorage.getItem("user_id"));
          })
        : null;

    temp && fetchCustomerProperty(temp);
  }, [applications]);

  const lastPostIndex = currentPage * 50;
  const firstPostIndex = lastPostIndex - 50;
  //get program to be passed to status bar. Id not being passed to status bar on customer side
  return (
    <div className="w-full flex flex-col">
      <div className="overflow-auto w-full rounded-t max-h-[50rem]">
        <table className="divide-y w-full text-gray-800 ">
          <thead className="sticky top-0 bg-white z-30">
            {false === true ? (
              <tr>
                {" "}
                <th className="p-2 text-start font-medium">Application ID</th>
              </tr>
            ) : (
              <tr>
                <th className="p-2 text-start font-medium">Application ID</th>
                <th className="p-2 text-start font-medium">Submission Date</th>
                <th className="p-2 text-start font-medium">Property Address</th>
                <th className="p-2 text-start font-medium">Applicant Name</th>
                <th className="p-2 text-start font-medium">Program Name</th>
                <th className="p-2 text-start font-medium">Status</th>
              </tr>
            )}
          </thead>
          <tbody className="">
            {apps
              ? apps
                  .sort((a, b) => {
                    return b.id - a.id;
                  })
                  .slice(firstPostIndex, lastPostIndex)
                  .map((application, index) => {
                    return (
                      <CustomerApplication
                        props={{
                          app: application,
                          index: index,
                          appModalController: props,
                          openUnlockModal: props.openUnlockModal,
                        }}
                        key={index}
                      />
                    );
                  })
              : null}

            {apps?.length === 0 && (
              <>
                <tr>
                  <td colSpan={5} className="text-center p-5">
                    <span className="text-black">
                      No applications submitted by you or another user with a
                      matching Account ID - please click on “Start Application”
                      to start your first application!
                    </span>
                  </td>
                </tr>
              </>
            )}
            {!apps && (
              <tr>
                <td className="">
                  <span className="text-start text-black">
                    No applications have been submitted by you or a different
                    user who registered a property with the same Account ID.
                    Start a new application by visiting the “Start Application”
                    tab!
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Paginate
        props={{
          totalItems: apps?.length,
          itemsPerPage: 50,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default CustomerApplicationTable;
