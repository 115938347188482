import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "../components/User/LoginForm";
import ConfirmEmailModal from "./ConfirmEmailModal";
import { motion, AnimatePresence } from "framer-motion";
import Register from "../components/User/Register";
import { FcNext, FcPrevious } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../features/user";
import { loginCustomer } from "../features/customer";
import { clearProgramUploads } from "../features/programUpload";
import { clearProgramFields } from "../features/programField";
import { clearPrograms } from "../features/program";
import { clearValues } from "../features/applicationValue";
import { clearApplications } from "../features/application";
import { clearDropdownValues } from "../features/dropdownValue";
import { clearApplicationProperties } from "../features/applicationProperties";
import { clearApplicationStatus } from "../features/applicationStatus";
import { clearProperties } from "../features/properties";
import { clearStatusList } from "../features/status";
import { logout } from "../features/user";
import { logoutCustomer } from "../features/customer";

import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const dropIn = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: { y: "100vh", opacity: 0 },
  };
  const dispatch = useDispatch();

  // //clear user state
  const clearRedux = () => {
    dispatch(logout());
    dispatch(logoutCustomer());
    localStorage.clear();
    dispatch(clearProperties());
    dispatch(clearStatusList());
    dispatch(clearProgramUploads());
    dispatch(clearProgramFields());
    dispatch(clearPrograms());
    dispatch(clearValues());
    dispatch(clearApplications());
    dispatch(clearApplicationStatus());
    dispatch(clearDropdownValues());
    dispatch(clearApplicationProperties());
    navigate("/");
  };

  const [showRegister, setShowRegister] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(null);

  const [value, setValue] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const openConfirmModal = (payload) => {
    setVerifyEmail(payload);
    setConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const openShowRegister = () => {
    setShowRegister(true);
  };
  const closeShowRegister = () => {
    setShowRegister(false);
  };

  const [loginController, setLoginController] = useState({
    loginType: "CUSTOMER",
  });

  const [mdown, setMdown] = useState(
    `This is *italic* text and this is **bold**`
  );

  const guestSignIn = async (e) => {
    try {
      e.preventDefault();
      clearRedux();
      const res = await dispatch(
        loginCustomer({
          email: "guest@woodardcurran.com",
          password: "test123",
        })
      );

      navigate("dashboard");
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {confirmModal && (
        <ConfirmEmailModal
          handleClose={closeConfirmModal}
          email={verifyEmail}
        />
      )}
      <ToastContainer theme="light" />
      {showRegister === true && (
        <Register props={{ closeShowRegister: closeShowRegister }} />
      )}

      <motion.div
        className={`flex h-screen transform justify-center bg-cover transition duration-200 md:bg-[#7d7d7d]`}
      >
        <div className="w-full font-sans text-white sm:hidden md:flex md:flex-col md:items-center md:justify-center md:gap-1 md:text-3xl">
          <div className="sm:hidden md:flex md:h-[40rem] md:w-[70rem] md:flex-col md:items-center md:bg-ws md:bg-[length:70%] md:bg-center md:bg-no-repeat"></div>
        </div>
        {loginController.loginType === "ADMIN" && (
          <motion.div
            className="flex w-6/12 flex-col items-center justify-center gap-12 bg-white"
            variants={dropIn}
          >
            <div className="h-[5rem] w-[21rem] bg-test1 bg-cover"></div>
            <Login
              props={{
                openShowRegister: openShowRegister,
                type: "ADMIN",
                setLoginController: setLoginController,
              }}
            />
            <div className="landing-btns flex flex-col gap-4 p-2 text-sm"></div>
          </motion.div>
        )}
        {loginController.loginType === "CUSTOMER" && (
          <motion.div
            className="relative flex w-6/12 flex-col items-center justify-center gap-8 bg-white"
            variants={dropIn}
            shadow
          >
            <div className="landing-btns absolute right-0 top-0 flex flex-col gap-4 p-2 text-sm">
              <div className="flex gap-2">
                {loginController.loginType === "ADMIN" && <FcPrevious />}
              </div>
            </div>
            <div className="flex h-[5rem] w-[21rem] bg-test1 bg-cover"></div>
            <Login
              props={{
                openShowRegister: openShowRegister,
                type: "CUSTOMER",
                openConfirmModal: openConfirmModal,
                closeConfirmModal: closeConfirmModal,
                setVerifyEmail: setVerifyEmail,
                setLoginController: setLoginController,
              }}
            />
            <div className="flex flex-col gap-2">
              <div className="landing-footer flex w-full items-center justify-center gap-1 bg-white text-center text-wc-green">
                <span className="">
                  Apply for rebates, track application status, and more.
                </span>
                <span
                  className="cursor-pointer underline hover:text-blue-500"
                  onClick={() => {
                    openShowRegister();
                  }}
                >
                  Register now
                </span>
              </div>
              <div>
                <button
                  className="border-0 border-black bg-blue-reef mb-3 mt-3 h-button w-full cursor-pointer rounded text-white hover:opacity-80"
                  onClick={(e) => {
                    guestSignIn(e);
                  }}
                >
                  View current programs
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default LandingPage;
