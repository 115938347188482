import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { FaUserPlus } from "react-icons/fa";
import { setToast } from "../../utils/ToastNotification";
import {
  checkEmptyFields,
  passwordConfirmation,
} from "../../utils/FormValidation";

import {
  budgetById,
  selectAllProgramBudgets,
  selectAllBudgets,
  fetchBudgets,
} from "../../features/programBudgetLog";

import ProgramBudgetTable from "./ProgramBudgetTable";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const ProgramBudgetLog = ({ props }) => {
  const [budgets, setBudgets] = useState(null);

  const getBudgets = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/program/budget/fetch-by-id`
          : `http://localhost:3000/program/budget/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.budgetLogController.programId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const program_budgets = res.data.data;
      program_budgets.sort((a, b) => {
        return a.id - b.id;
      });
      setBudgets(program_budgets);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBudgets();
  }, [props]);

  return (
    <Backdrop
      onClick={() => {
        props.budgetLogController.closeBudgetLog();
        getBudgets();
      }}
    >
      <motion.div
        className=" rounded w-[80rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans "
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 min-w-12">
          <div className="rounded w-full flex gap-2 items-center justify-start ">
            <h2 className="flex gap-2 text-blue-reef">Program Budget Log</h2>
          </div>
          <hr />
          {budgets && (
            <ProgramBudgetTable
              props={{ programBudgets: budgets, getBudgets: getBudgets }}
            />
          )}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ProgramBudgetLog;
