import React, { useState, useEffect } from "react";
import { FcHome, FcDepartment, FcCamcorder, FcLandscape } from "react-icons/fc";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { createProperty, fetchProperties } from "../../features/properties";
import { customerById } from "../../features/customer";

import { ToastContainer, toast } from "react-toastify";

import TextInput from "react-autocomplete-input";
import tblData from "../../assets/Location-UM00100M full results.csv";
import shpData from "../../assets/testShp.csv";
import Papa from "papaparse";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};

const RegisterAccount = ({ props }) => {
  const dispatch = useDispatch();
  const [property, setProperty] = useState({
    customerId: parseInt(localStorage.getItem("user_id")),
    ACCOUNT_NO: null,
    propertyYear: null,
    owner: false,
    tenant: false,
    singleHomeFamily: false,
    multiFamilyComplex: false,
    mfcStreetNo: null,
    mfcStreetName: "",
    mfcUnitNo: "",
    commercial: false,
    residential: false,
    landscape: false,
    noBillingAccount: false,
    isLandlord: false,
    dontHaveAccountNumber: false,
    isAccountHolder: false,
    isLandscaper: false,
    isPropertyManager: false,
    isOtherRelationship: false,
    landscaperContact: "",
    otherRelationshipText: "",
    isHoa: false,
    HOAname: "",
  });

  const submit = async () => {
    try {
      console.log("Current submitted property: ", property);
      await dispatch(
        createProperty({
          customerId: property.customerId || null,
          ACCOUNT_NO: property.ACCOUNT_NO || null,
          propertyYear: 1990 || 1990, //default no longer needed.
          owner: property.owner || false,
          tenant: property.tenant || false,
          singleHomeFamily: property.singleHomeFamily || false,
          multiFamilyComplex: property.multiFamilyComplex || false,
          mfcStreetNo: parseInt(property.mfcStreetNo) || 0,
          mfcStreetName: property.mfcStreetName || "",
          mfcUnitNo: parseInt(property.mfcUnitNo) || 0,
          commercial: property.commercial || false,
          residential: property.residential || false,
          landscape: property.landscape || false,
          noBillingAccount: property.noBillingAccount || false,
          isLandlord: property.isLandlord || false,
          dontHaveAccountNumber: property.dontHaveAccountNumber || false,
          isAccountHolder: property.isAccountHolder || false,
          isLandscaper: property.isLandscaper || false,
          isPropertyManager: property.isPropertyManager || false,
          isOtherRelationship: property.isOtherRelationship || false,
          landscaperContact: property.landscaperContact || "",
          otherRelationshipText: property.otherRelationshipText || "",
          isHoa: property.isHoa || false,
          HOAname: property.HOAname || "",
        })
      );
      await dispatch(customerById(parseInt(localStorage.getItem("user_id"))));
      props.setRegisterOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const [showShapeLookup, setShowShapeLookup] = useState(false);
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [tableAddressIDs, setTableAddressIDs] = useState([]);
  const [tableAddressArray, setTableAddressArray] = useState([]);
  const [shapeAddressIDs, setShapeAddressIDs] = useState([]);
  const [shapeAddressArray, setShapeAddressArray] = useState([]);

  useEffect(() => {
    Papa.parse(tblData, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results.data);
        // modify to fit table address array in a nice way
        let tmp = results.data.map((x) => {
          return (
            (x.HOUSE_NO != "NULL" ? x.HOUSE_NO : "") +
            " " +
            (x.STREET_PFX_DIR != "NULL" ? x.STREET_PFX_DIR : "") +
            " " +
            (x.STREET_NM != "NULL" ? x.STREET_NM : "") +
            " " +
            (x.STREET_NM_SFX != "NULL" ? x.STREET_NM_SFX : "") +
            " " +
            (x.STREET_SFX_DIR != "NULL" ? x.STREET_SFX_DIR : "") +
            " " +
            (x.SEC_ADDR_ID != "NULL" ? x.SEC_ADDR_ID : "") +
            " " +
            (x.SEC_ADDR_RANGE != "NULL" ? x.SEC_ADDR_RANGE : "") +
            " " +
            (x.CITY != "NULL" ? x.CITY : "") +
            " " +
            (x.PROVINCE_CD != "NULL" ? x.PROVINCE_CD : "")
          )
            .replace(/\s+/g, " ")
            .trim();
        });
        setTableAddressArray(tmp);
        tmp = results.data.map((x) => {
          return x.LOCATION_NO;
        });
        setTableAddressIDs(tmp);
        console.log("Location IDS:", tmp);
      },
    });

    Papa.parse(shpData, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results.data);
        // modify to fit table address array in a nice way
        let tmp = results.data.map((x) => {
          return (
            x.SitusStreetNumber +
            " " +
            x.SitusStreetName +
            " " +
            x.SitusUnit +
            " " +
            x.SitusCity +
            " " +
            x.SitusZip
          )
            .replace(/\s+/g, " ")
            .trim();
        });
        setShapeAddressArray(tmp);
        console.log(tmp);
      },
    });
  }, []);

  const setPropertyType = (input) => {
    setProperty((prev) => ({
      ...prev,
      singleHomeFamily: false,
      multiFamilyComplex: false,
      commercial: false,
      residential: false,
      landscape: false,
    }));
    switch (input) {
      case "singleHomeFamily":
        setProperty((prev) => ({
          ...prev,
          singleHomeFamily: true,
        }));
        break;
      case "multiFamilyComplex":
        setProperty((prev) => ({
          ...prev,
          multiFamilyComplex: true,
        }));
        break;
      case "commercial":
        setProperty((prev) => ({
          ...prev,
          commercial: true,
        }));
        break;
      case "residential":
        setProperty((prev) => ({
          ...prev,
          residential: true,
        }));
        break;
      case "landscape":
        setProperty((prev) => ({
          ...prev,
          landscape: true,
        }));
        break;
    }
  };

  const getAccount = async (input) => {
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
        : `http://localhost:3000/account/fetch-by-id`;

    const res = await axios.post(
      url,
      { id: parseInt(input) },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  };

  const checkRequiredFieldsEntered = async (currentTab) => {
    let check = "";

    // make sure there is some kind of address or account number

    if (
      !property.ACCOUNT_NO &&
      !property.noBillingAccount &&
      !property.billingNoNumber
    )
      check =
        "Please enter an Account Number or select one of the two checkboxes next to its input box.";
    if (property.ACCOUNT_NO) {
      const res = await getAccount(parseInt(property.ACCOUNT_NO));
      if (!res.data.id || res.data.cutoffDt !== "NULL")
        check =
          "Sorry - this Account Number is invalid or inactive. Check the Account Number, or clear it and use the checkbox options below.";
    }
    if (property.shapeAddressVal && property.tableAddressVal)
      check = "Please only enter values to one dropdown."; // only one of the two should be entered
    if (
      property.shapeAddressVal &&
      !shapeAddressArray.includes(property.shapeAddressVal)
    )
      check = "Please select a value from the dropdown.";
    if (
      property.tableAddressVal &&
      !tableAddressArray.includes(property.tableAddressVal)
    )
      check = "Please select a value from the dropdown.";
    if (
      (property.manualAddress1 && property.tableAddressVal) ||
      (property.manualAddress1 && property.shapeAddressVal)
    )
      check = "Please clear the address you selected from the dropdown.";

    if (
      showManualAddress == 1 &&
      !(
        property.manualAddress1 &&
        property.manualAddress2 &&
        property.manualZip
      )
    ) {
      check = "Please fill in information on this page to proceed.";
    }

    // top one
    if (
      property.billingNoNumber == 1 &&
      !property.shapeAddressVal &&
      !property.exactAddress
    ) {
      check = "Please fill in information on this page to proceed.";
    }

    // bottom one
    if (
      property.billingNoNumber == 1 &&
      !property.tableAddressVal &&
      !showManualAddress
    ) {
      check = "Please fill in information on this page to proceed.";
    }
    if (
      ![0, 3].includes(
        [
          property.manualAddress1,
          property.manualAddress2,
          property.manualZip,
        ].filter((x) => !x).length
      )
    )
      check = "Please enter all fields for the manual address entry.";

    {
      /* 
    // tr commented out because idk when this is needed
    // check to see if the Location ID is good if table values were sent in
    if (
      property.billingNoNumber &&
      property.tableAddressVal &&
      property.tableAddressID
    ) {
      const res = await getAccountByLocationId(
        parseInt(property.tableAddressID)
      );
      console.log(res);
    }

    */
    }

    // make sure a property relationship is selected
    if (
      ![
        property.owner,
        property.isAccountHolder,
        property.tenant,
        property.isLandscaper,
        property.isPropertyManager,
        property.isLandlord,
        property.isOtherRelationship,
      ].filter((x) => x).length
    )
      check =
        "Please select at least one value for your relationship with the property.";

    // make sure a property type is selected

    if (
      ![
        property.singleHomeFamily,
        property.multiFamilyComplex,
        property.commercial,
        property.residential,
        property.landscape,
      ].filter((x) => x).length
    )
      check = "Please select a property type.";

    if (property.isLandscaper && !property.landscaperContact)
      check = "Please provide an answer in the Landscaper box.";

    if (property.commercial && !property.TypeofCommercialProperty)
      check = "Please select a dropdown under Commercial.";

    console.log(property);
    if (check) {
      toast(check, { type: "error" });
    } else {
      // register the thing
      submit();
      console.log("cleared!");
    }
  };

  return (
    <motion.div
      className="flex flex-col gap-8 items-center"
      variants={slideIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      <span className="text-lg text-green-reef font-semibold tracking-wide p-4">
        LINK A PROPERTY TO YOUR ACCOUNT
      </span>
      <div className="w-full flex justify-center">
        <form
          className="contact-form flex gap-24  bg-white"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex flex-col gap-4 w-[33%]">
            <div className="flex flex-col w-full ">
              <label htmlFor="">Account Number (from your water bill)</label>
              <input
                type="text"
                className="border-2 border-gray-400 p-2 rounded shadow-sm focus:outline-none bg-gray-50"
                disabled={
                  property.noBillingAccount || property.dontHaveAccountNumber
                }
                onChange={(e) => {
                  setProperty((prev) => ({
                    ...prev,
                    ACCOUNT_NO: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                className="w-[1rem]"
                disabled={property.ACCOUNT_NO || property.dontHaveAccountNumber}
                onChange={(e) => {
                  setProperty((prev) => ({
                    ...prev,
                    noBillingAccount: e.target.checked,
                  }));
                }}
              />
              <label htmlFor="">
                My property does not have a billing account with ACWD
              </label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                className="w-[1rem]"
                disabled={property.ACCOUNT_NO || property.noBillingAccount}
                onChange={(e) => {
                  setProperty((prev) => ({
                    ...prev,
                    dontHaveAccountNumber: e.target.checked,
                  }));
                }}
              />
              <label htmlFor="">
                My property has a billing account, but I don't know what the
                number is or I live in a unit within an HOA with a single master
                meter
              </label>
            </div>
            {property.noBillingAccount == true && (
              <>
                <div className="flex flex-col w-full">
                  <label className="flex row">
                    Property Street Address Location Lookup
                  </label>
                  <label className="text-gray-400">
                    Begin typing your street address and select the best option
                    that comes up.
                  </label>
                </div>
                <TextInput
                  className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                  Component="input"
                  trigger=""
                  spacer=""
                  regex="^[a-zA-Z0-9_\- ]+$"
                  matchAny={true}
                  options={tableAddressArray}
                  value={property.tableAddressVal}
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      tableAddressVal: e,
                      tableAddressID:
                        tableAddressIDs[tableAddressArray.indexOf(e)], // index in the array of IDs that was pulled from the same file as the address array
                    }));
                  }}
                />
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    className="w-[1rem]"
                    onChange={(e) => {
                      setShowShapeLookup(e.target.checked);
                    }}
                  />
                  <label htmlFor="">
                    My exact address isn't showing up in the lookup options
                    above.
                  </label>
                </div>
              </>
            )}

            {(property.dontHaveAccountNumber == true ||
              showShapeLookup == true) && (
              <>
                <div className="flex flex-col w-full">
                  <label className="flex row">
                    Property Street Address Assessor Lookup
                  </label>
                  <label className="text-gray-400">
                    Begin typing your street address and select the best option
                    that comes up.
                  </label>
                </div>
                <TextInput
                  className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                  Component="input"
                  trigger=""
                  spacer=""
                  regex="^[a-zA-Z0-9_\- ]+$"
                  matchAny={true}
                  options={shapeAddressArray}
                  value={property.shapeAddressVal}
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      shapeAddressVal: e,
                    }));
                  }}
                />
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    className="w-[1rem]"
                    onChange={(e) => {
                      setShowManualAddress(e.target.checked);
                    }}
                  />
                  <label htmlFor="">
                    My exact address isn't showing up in the lookup options
                    above.
                  </label>
                </div>
              </>
            )}
            {showManualAddress && (
              <>
                <div className="flex flex-col w-full">
                  <label className="flex row">Manual Address Entry</label>
                  <label className="text-gray-400">
                    Please provide your property location information in the
                    fields below.“Note: ACWD only serves Fremont, Newark, and
                    Union City. Please only enter and register a property in
                    Fremont, Newark, or Union City.”
                  </label>
                  <div>
                    <input
                      type="text"
                      className="w-2/5 mr-2 border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                      placeholder="Street Number and Name"
                      value={property.manualAddress1}
                      onChange={(e) => {
                        setProperty((prev) => ({
                          ...prev,
                          manualAddress1: e.target.value,
                        }));
                      }}
                    />
                    {/* <input
                      type="text"
                      className="w-1/4 mr-2 border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                      placeholder="City"
                      value={property.manualAddress2}
                      onChange={(e) => {
                        setProperty((prev) => ({
                          ...prev,
                          manualAddress2: e.target.value,
                        }));
                      }}
                    /> */}
                    <select
                      className="text-gray-900 rounded border-2 border-gray-200 h-[2.5rem]"
                      onChange={(e) => {
                        setProperty((prev) => ({
                          ...prev,
                          manualAddress2: e.target.value,
                        }));
                      }}
                    >
                      <option default>Please select city</option>
                      <option value="FREMONT">FREMONT</option>
                      <option value="NEWARK">NEWARK</option>
                      <option value="UNION CITY">UNION CITY</option>
                    </select>
                    <input
                      type="text"
                      className="w-1/4 mr-2 border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                      placeholder="Zip"
                      value={property.manualZip}
                      onChange={(e) => {
                        setProperty((prev) => ({
                          ...prev,
                          manualZip: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {/*
            <div className="flex flex-col w-full">
              <label htmlFor="">Property Year Built</label>
              <input
                type="text"
                className="border-2 border-gray-400 p-2 rounded shadow-sm focus:outline-none bg-gray-50"
                onChange={(e) => {
                  setProperty((prev) => ({
                    ...prev,
                    propertyYear: e.target.value,
                  }));
                }}
              />
            </div>
            */}
            <hr />
            <div className="flex flex-col w-full">
              <label className="flex row">
                Your relationship with the Property
              </label>
              <label className="text-gray-400">
                (Please check all that apply.)
              </label>
            </div>
            <div className="flex justify-between gap-4">
              <div className="flex gap-2 w-[33%]">
                <input
                  type="checkbox"
                  className="w-[1.5rem] h-[2rem]"
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      owner: !prev.owner,
                    }));
                  }}
                />
                <label htmlFor="">Owner</label>
              </div>
              <div className="flex gap-2 w-[33%]">
                <input
                  type="checkbox"
                  className="w-[1.5rem] h-[2rem]"
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      tenant: !prev.tenant,
                    }));
                  }}
                />
                <label htmlFor="">Tenant</label>
              </div>
              <div className="flex gap-2 w-[33%]">
                <input
                  type="checkbox"
                  className="w-[1.5rem] h-[2rem]"
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      isAccountHolder: !prev.isAccountHolder,
                    }));
                  }}
                />
                <label htmlFor="">Account Holder</label>
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="flex gap-2 w-[33%]">
                <input
                  type="checkbox"
                  className="w-[1.5rem] h-[2rem]"
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      isLandscaper: !prev.isLandscaper,
                    }));
                  }}
                />
                <label htmlFor="">Landscaper</label>
              </div>
              <div className="flex gap-2 w-[33%]">
                <input
                  type="checkbox"
                  className="w-[1.5rem] h-[2rem]"
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      isPropertyManager: !prev.isPropertyManager,
                    }));
                  }}
                />
                <label htmlFor="">Property Manager</label>
              </div>
              <div className="flex gap-2 w-[33%]">
                <input
                  type="checkbox"
                  className="w-[1.5rem] h-[2rem]"
                  onChange={(e) => {
                    setProperty((prev) => ({
                      ...prev,
                      isLandlord: !prev.isLandlord,
                    }));
                  }}
                />
                <label htmlFor="">Landlord</label>
              </div>
            </div>

            <div className="flex gap-4 justify-center p-4">
              <button
                className="rounded bg-blue-reef py-2 text-white hover:bg-blue-reef tracking-wide px-10"
                onClick={() => {
                  props.setRegisterOpen(false);
                }}
              >
                CANCEL
              </button>
              <button
                className="rounded-md bg-green-reef py-2 text-white hover:bg-green-600 tracking-wide px-10"
                onClick={(e) => {
                  //submit(e);
                  checkRequiredFieldsEntered();
                }}
              >
                ADD PROPERTY
              </button>
            </div>
          </div>
          <div className="flex flex-col w-[67%] gap-4 p-8 rounded-md">
            <div className="flex gap-8 items-center">
              <div className="rounded-full bg-teal-500 ">
                <FcHome size="58" />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center  ">
                  <input
                    type="radio"
                    name="propertyType"
                    className="w-[2rem] h-[1.5rem]"
                    onChange={(e) => {
                      setPropertyType("singleHomeFamily");
                    }}
                  />
                  <span>Single Family Home?</span>
                </div>
                <span className="text-gray-300">
                  (if you have your own separate water account in a single
                  house, condo, townhouse or apartment.)
                </span>
              </div>
            </div>
            <hr />
            <div className="flex flex-col">
              <div className="flex gap-8 items-center">
                <div className="rounded-full bg-yellow-500 ">
                  <FcDepartment size="58" />
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center  ">
                    <input
                      type="radio"
                      name="propertyType"
                      className="w-[2rem] h-[1.5rem]"
                      onChange={(e) => {
                        setPropertyType("multiFamilyComplex");
                      }}
                    />
                    <span>
                      Multi-Family Complex or HOA?{" "}
                      <span className="text-red-500">*</span>
                    </span>
                  </div>
                  <span className="text-gray-300">
                    (Use this if you are a tenant, HOA unit owner, or appartment
                    manager for a multi-family property)
                  </span>
                </div>
              </div>
              {property.multiFamilyComplex == true && (
                <>
                  <div className="flex justify-between gap-8 py-4">
                    <div className="flex flex-col w-full">
                      <span>
                        Street No. <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        className="border-2 border-gray-400 rounded focus:outline-none bg-gray-50 p-2"
                        onChange={(e) => {
                          setProperty((prev) => ({
                            ...prev,
                            mfcStreetNo: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <span>
                        Street Name <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        className="border-2 border-gray-400 rounded focus:outline-none bg-gray-50 p-2"
                        onChange={(e) => {
                          setProperty((prev) => ({
                            ...prev,
                            mfcStreetName: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <span>
                        Unit No. <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        className="border-2 border-gray-400 rounded focus:outline-none bg-gray-50 p-2"
                        onChange={(e) => {
                          setProperty((prev) => ({
                            ...prev,
                            mfcUnitNo: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 w-[33%]">
                    <input
                      type="checkbox"
                      className="w-[1.5rem] h-[2rem]"
                      onChange={(e) => {
                        setProperty((prev) => ({
                          ...prev,
                          isHoa: !prev.isHoa,
                        }));
                      }}
                    />
                    <label htmlFor="">I live in an HOA Complex</label>
                  </div>
                </>
              )}
              <hr />
              <div className="flex gap-8 items-center py-4">
                <div className="rounded-full bg-purple-400 text-white">
                  <FcCamcorder size="58" />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center  ">
                    <input
                      type="radio"
                      name="propertyType"
                      className="w-[2rem] h-[1.5rem]"
                      onChange={(e) => {
                        setPropertyType("commercial");
                      }}
                    />
                    <span>Commercial?</span>
                  </div>
                  <span className="text-gray-300">
                    (Use this if you are responsible for the water account at a
                    commerical, industrial or institutional location.)
                  </span>
                  {property.commercial && (
                    <>
                      <div className="flex gap-2 items-center">
                        <span>
                          Please select the option that best represents the use
                          of this commercial property:
                        </span>
                      </div>
                      <select
                        className="flex gap-2 items-center mr-3 h-16 whitespace-pre-wrap border-2 border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white w-1/2"
                        onChange={(e) => {
                          setProperty((prev) => ({
                            ...prev,
                            TypeofCommercialProperty: e.target.value,
                          }));
                        }}
                      >
                        <option disabled selected>
                          Select One
                        </option>
                        <option value="Sales">
                          Sales (e.g., retail, shopping centers/malls, grocery
                          stores and food markets)
                        </option>
                        <option value="Services">
                          Services (e.g., auto, personal)
                        </option>
                        <option value="Lodging">
                          Lodging (e.g., hospitality, retirement homes)
                        </option>
                        <option value="Food/Beverage">
                          Food/Beverage (e.g., full service, fast food)
                        </option>
                        <option value="Offices">Offices</option>
                        <option value="Healthcare">
                          Healthcare (e.g., hospitals, medical offices, medical
                          and laboratory equipment and processes)
                        </option>
                        <option value="Public Services">
                          Public Services (e.g., government, prisons and
                          correctional facilities)
                        </option>
                        <option value="Recreation, non-water">
                          Recreation, non-water (e.g., athletic facilities,
                          entertainment facilities, parks/cemeteries, golf
                          course)
                        </option>
                        <option value="Water Recreation">
                          Water Recreation (e.g., public pools/water parks)
                        </option>
                        <option value="Laundry">
                          Laundry (e.g., laundromats, commercial/industrial
                          laundries)
                        </option>
                        <option value="Vehicle Wash">Vehicle Wash</option>
                        <option value="Religious Buildings">
                          Religious Buildings
                        </option>
                        <option value="Education">Education</option>
                        <option value="Industrial, non-manufacturing">
                          Industrial, non-manufacturing (e.g.,
                          temperature-controlled warehouses, non
                          temperature-controlled warehouses)
                        </option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Utility">Utility</option>
                        <option value="Mixed Use Commercial">
                          Mixed Use Commercial (e.g., strip malls, shopping
                          centers, and other commercial spaces that have
                          multiple uses and/or are subject to frequent changes
                          of tenants with different water use patterns)
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </>
                  )}
                </div>
              </div>
              <hr />
              <div className="flex gap-8 items-center py-4">
                <div className="rounded-full bg-teal-700 text-white">
                  <FcDepartment size="58" />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center  ">
                    <input
                      type="radio"
                      name="propertyType"
                      className="w-[2rem] h-[1.5rem]"
                      onChange={(e) => {
                        setPropertyType("residential");
                      }}
                    />
                    <span>
                      Mixed Use Comm/Residential?
                      <span className="text-red-500">*</span>
                    </span>
                  </div>
                  <span className="text-gray-300">
                    (Use this if your building includes both commercial and
                    residential units.)
                  </span>
                </div>
              </div>
              <hr />
              <div className="flex gap-8 items-center py-4">
                <div className="rounded-full bg-yellow-400 text-white">
                  <FcLandscape size="58" />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center  ">
                    <input
                      type="radio"
                      name="propertyType"
                      className="w-[2rem] h-[1.5rem]"
                      onChange={(e) => {
                        setPropertyType("landscape");
                      }}
                    />
                    <span>
                      Landscape (Irrigation Account)?
                      <span className="text-red-500">*</span>
                    </span>
                  </div>
                  <span className="text-gray-300">
                    (Use this if your account is for landscape irrigation only.)
                  </span>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default RegisterAccount;
