import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../components/Misc/Backdrop";
import { setToast } from "../utils/ToastNotification";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const ConfirmEmailModal = ({
  handleClose,
  text,
  setUsers,
  allUsers,
  email,
}) => {
  const dispatch = useDispatch();
  const [verifySent, setVerifySent] = useState(null);
  const sendVerification = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/email-confirmation/add`
          : `http://localhost:3000/email-confirmation/add`;

      const res = await axios.post(url, { email: email });

      if (process.env.REACT_APP_PRODUCTION === "true") {
        const response1 = await axios.post(
          `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
          {
            subject: `Confirm Water Savings Center Registration`,
            body: `Hello! Thanks for registering for the ACWD Water Savings Center. As a one-time account setup step, please click this link to verify your email address and confirm your registration: https://watersavingscenter.acwd.org/${email}-${res.data.data.Token}`,
            to: [email],
            fromName: "ACWD Water Savings Center",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response1) {
          const toastPayload = {
            index: 0,
            description: 15,
            type: "application",
          };
          setToast(toastPayload);
          setVerifySent(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="flex flex-col rounded w-[40rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans gap-4 p-8"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <span>{`${email} exists in our system but is not yet verfied.`}</span>
        {verifySent !== true ? (
          <a
            className="text-blue-500 cursor-pointer"
            onClick={() => {
              sendVerification();
            }}
          >
            Click here to re-send email verification.
          </a>
        ) : (
          <span>{`Confirmation email sent to ${email}`}</span>
        )}
      </motion.div>
    </Backdrop>
  );
};

export default ConfirmEmailModal;
