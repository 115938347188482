import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Moment from "react-moment";
import moment from "moment";

// import { updateBudget } from "../../features/programBudgetLog";
import { selectAllUsers } from "../../features/user";

import { useSelector } from "react-redux";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const WaterSavings = ({ props }) => {
  console.log("WaterSavings2", props);
  const [waterSavingComment, setWaterSavingComment] = useState(
    props.waterSaving.comment
  );

  useEffect(() => {
    setWaterSavingComment(props.waterSaving.comment);
  });

  const updateWaterSaving = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/water-savings/update`
          : `http://localhost:3000/application/water-savings/update`;

      const res = await axios.post(
        url,
        { id: props.waterSaving.id, comment: e },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedWaterSaving = res.data.data;
      console.log(updatedWaterSaving);
    } catch (err) {
      console.log(err);
    }
  };

  const [user, setUser] = useState(null);
  const users = useSelector(selectAllUsers);

  useEffect(() => {
    if (users) {
      const temp = users.filter((user) => {
        return user.id === props.waterSaving.userId;
      });
      console.log("userssss", temp[0]);
      setUser(temp[0]);
    }
  });

  return (
    <tr
      className={`rounded-lg dark:text-white text-black text-start p-3 cursor-pointer hover:bg-blue-50 `}
    >
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {props.waterSaving.value.toLocaleString()}
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {/* <Moment format="MM/DD/YYYY">
          {moment.utc(props.waterSaving.createdAt).format("MM/DD/YYYY")}
        </Moment> */}
        <Moment format="MM/DD/YYYY">{props.waterSaving.createdAt}</Moment>
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {user && `${user.email}`}
      </td>
      <td className="p-3 font-bold text-blue-500 dark:text-white hover:underline r whitespace-nowrap">
        {/* fix in future there shouldn't be two inputs determined by budgetComment */}
        <textarea
          onChange={(e) => {
            // setWaterSavingComment(e.target.value);
            updateWaterSaving(e.target.value);
          }}
          defaultValue={waterSavingComment}
          className="text-black border border-gray-200 rounded"
        />

        {/* {budgetComment === "" && (
          <textarea
            onChange={(e) => {
              setBudgetComment(e.target.value);
            }}
            defaultValue={budgetComment}
            className="text-black border border-gray-200 rounded"
          />
        )} */}
      </td>
    </tr>
  );
};

export default WaterSavings;
