import React, { useEffect, useState } from "react";
import Backdrop from "../Misc/Backdrop";
import { motion } from "framer-motion";
import { selectAllCustomers, customerById } from "../../features/customer";
import { selectAllApplications } from "../../features/application";
import { useDispatch, useSelector } from "react-redux";
import CustomerPopupModalProperty from "./CustomerPopupModalProperty";
import { current } from "@reduxjs/toolkit";
import HashLoader from "react-spinners/HashLoader";

import ApplicationTable from "../Applications/ApplicationTable";
import {
  selectAllProperties,
  fetchProperties,
} from "../../features/properties";
import InputMask from "react-input-mask";
import { selectAllStatus } from "../../features/applicationStatus";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const CustomerPopupModal = ({ handleClose, props }) => {
  console.log("Customer Popup Modal", props);
  const dispatch = useDispatch();
  const customer = useSelector(selectAllCustomers);
  const appStatusList = useSelector(selectAllStatus);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [currentTab, setCurrentTab] = useState("APPLICATION");

  const [customerProperties, setCustomerProperties] = useState(null);
  const [allProperties, setAllProperties] = useState([]);

  console.log("CustomerProperties:", customerProperties);
  console.log("allProperties:", allProperties);
  // console.log("Current customer:", currentCustomer);

  const applications = useSelector(selectAllApplications);
  const [customerApplications, setCustomerApplications] = useState(null);

  const [waterSavings, setWaterSavings] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const properties = useSelector(selectAllProperties);

  useEffect(() => {
    const getAllProperties = async () => {
      let tmpArr = await dispatch(fetchProperties());
      let tmpArr2 = tmpArr.payloadl;
      console.log("tmpArr:", tmpArr, tmpArr2);
      setAllProperties(tmpArr2);
    };
    getAllProperties();
  }, []);

  useEffect(() => {
    const getCustomerProperties = async () => {
      try {
        let res = await dispatch(customerById(props.id));

        let customer = res.payload;

        const url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/customer-property/customer/fetch-by-id`
            : `http://localhost:3000/customer-property/customer/fetch-by-id`;

        if (customer && properties) {
          setCurrentCustomer(customer);

          res = await axios.post(
            url,
            { id: customer.id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          const associatedProperties = res.data.data;

          setCustomerProperties(associatedProperties);
        }

        const customerAppList = applications.filter((el) => {
          return el.customerId === props.id;
        });

        console.log("customerApplications: ", customerApplications);
        const customerAppsStatus = customerAppList?.map((x) => {
          return appStatusList?.filter((y) => {
            return y.applicationId === x.id && y.statusId !== 0;
          }).length > 0
            ? customerAppList?.filter((z) => {
                return (
                  z.id ===
                  appStatusList?.filter((y) => {
                    return y.applicationId === x.id && y.statusId !== 0;
                  })[0].applicationId
                );
              })[0]
            : null;
        });

        console.log("customerStatus: ", customerAppsStatus);
        const customerApps = customerAppsStatus?.filter((x) => {
          return x !== null;
        });

        const appWaterSavings = customerApps?.map((el) => {
          return el.waterSavings;
        });
        setCustomerApplications(customerApps);
        if (appWaterSavings?.length > 0) {
          const waterSavingsTotal = appWaterSavings?.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue;
            }
          );
          setWaterSavings(waterSavingsTotal);
        }

        const cost = customerApps?.map((el) => {
          return el.cost;
        });
        if (cost?.length > 0) {
          const costTotal = cost?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          });
          setTotalCost(costTotal);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getCustomerProperties();
  }, [props.id, properties]);

  const [modalMenu, setModalMenu] = useState({
    menu: [true, false, false],
    setMenu: (payload) => {
      const temp = [false, false, false];
      temp[payload] = true;
      console.log("Modal Menu Init:", temp);
      setModalMenu((prev) => ({
        ...prev,
        menu: temp,
      }));
    },
  });

  function numberComma(input) {
    console.log(input);
    let nf = new Intl.NumberFormat("en-US");
    input = nf.format(input);
    return input;
  }

  return (
    <Backdrop
      onClick={() => {
        if (handleClose) {
          handleClose();
        } else {
          props.closePopupModal();
        }
      }}
    >
      <motion.div
        className="flex flex-col gap-8  overflow-y-auto max-h-[50rem]"
        onClick={(e) => e.stopPropagation()}
        // variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        {/* {customerProperties && ( */}
        <div className="w-full border border-gray-100 rounded-t shadow bg-white">
          <div className="flex justify-center sticky top-0 w-full border border-gray-200 rounded rounded-r-lg z-50 bg-white">
            <div
              className={` px-2 py-2 cursor-pointer flex justify-center w-full border-r border-gray-200 ${
                modalMenu.menu[0] ? "!bg-blue-reef text-white" : ""
              }`}
              onClick={() => {
                setCurrentTab("APPLICATION");
                modalMenu.setMenu(0);
              }}
            >
              <span className="tracking-wider">Application History</span>
            </div>
            <div
              className={` px-2 py-2 cursor-pointer flex w-full justify-center border-r bg-white border-gray-200 ${
                modalMenu.menu[1] ? "!bg-blue-reef text-white" : ""
              }`}
              onClick={() => {
                setCurrentTab("CUSTOMER");
                modalMenu.setMenu(1);
              }}
            >
              <span className="tracking-wider ">Customer Details</span>
            </div>
          </div>
          {customerProperties ? (
            <form className="contact-form flex flex-col p-8 gap-4 bg-white">
              {currentTab == "APPLICATION" && (
                <>
                  {/* The totals on the top */}

                  <div className="app-info flex gap-2 font-semibold mb-2 text-blue-reef">
                    {/* <span>
                      {false || "##"} applications submitted | {false || "##"}{" "}
                      gal of annual water savings | ${false || "##"} program
                      dollars spent
                    </span> */}
                    <span>{`${numberComma(
                      customerApplications?.length
                    )} applications submitted | `}</span>
                    <span>{`${numberComma(
                      waterSavings
                    )} gal of annual site water savings | `}</span>
                    <span>
                      ${`${numberComma(totalCost)} program dollars spent`}
                    </span>
                  </div>
                  {/* The table */}
                  {customerApplications && (
                    <ApplicationTable
                      props={{
                        applications: customerApplications,
                        view: "property",
                      }}
                    />
                  )}
                </>
              )}
              {currentTab == "CUSTOMER" && (
                <>
                  <div className="flex gap-4 font-sans">
                    <div className="flex items-center gap-2">
                      <span>Customer ID</span>
                      <input
                        type="text"
                        defaultValue="CUSTOMER ID"
                        value={currentCustomer.id}
                        className="border border-gray-200 rounded focus:outline-none w-24 h-[2.5rem] pl-2"
                        disabled={true}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <span>First Name</span>
                      <input
                        type="text"
                        defaultValue="FIRST NAME"
                        value={currentCustomer.firstName}
                        className="border border-gray-200 rounded focus:outline-none h-[2.5rem] pl-2"
                        disabled={true}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <span>Last Name</span>
                      <input
                        type="text"
                        defaultValue="LAST NAME"
                        value={currentCustomer.lastName}
                        className="border border-gray-200 rounded h-[2.5rem] focus:outline-none pl-2"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 mb-8">
                    <div className="flex items-center gap-2">
                      <span>Email</span>
                      <input
                        type="text"
                        defaultValue="EMAIL"
                        value={currentCustomer.email}
                        className="border border-gray-200 rounded focus:outline-none w-80 h-[2.5rem] pl-2"
                        disabled={true}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <span>Telephone</span>
                      <InputMask
                        type="text"
                        mask="(999) 999-9999"
                        defaultValue="TELEPHONE"
                        value={currentCustomer.phone}
                        className="border border-gray-200 rounded focus:outline-none h-[2.5rem] pl-2"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <span className="font-semibold text-blue-reef">
                    Properties Registered to Customer
                  </span>

                  <div className="rounded shadow">
                    <table className="w-full">
                      <thead className="border-b-2 bg-gray-50 text-gray-900">
                        <tr>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Property ID
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Account Number
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Account Status
                          </th>
                          {/* <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Street
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            City
                          </th> */}
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Property Address
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Property Type
                          </th>

                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Owner
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Tennant
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wide text-center whitespace-nowrap ">
                            Account Holder
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wide text-center whitespace-nowrap ">
                            Landscaper
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wide text-center whitespace-nowrap ">
                            Property Manager
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wide text-center whitespace-nowrap ">
                            Landlord
                          </th>
                          <th className="p-3 text-sm font-semibold tracking-wider text-left whitespace-nowrap ">
                            Other Relationship to Property
                          </th>
                        </tr>
                      </thead>
                      {/* for the table body use a renderlist similar to that used for the customer table here. it would have to generate application table entries though */}
                      <tbody className="divide-y">
                        {customerProperties.map((property, index) => {
                          return (
                            <CustomerPopupModalProperty
                              props={{
                                property: property,
                                propertyExt: allProperties?.filter(
                                  (x) => x.id == property.propertyId
                                )[0],
                                customer: currentCustomer,
                                modalController: props.modalController,
                                index: index,
                              }}
                              key={index}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div className="flex gap-4 justify-center "></div>
            </form>
          ) : (
            <div className="h-[20rem] flex justify-center gap-2 items-center">
              <span>Loading customer details</span>
              <HashLoader size={40} color={"#A3AEB9"} />
            </div>
          )}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default CustomerPopupModal;
