import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/check/value";
const local_base_url = "http://localhost:3000/check/value";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  checkValues: [],
  status: "idle",
  error: null,
};

export const fetchCheckValues = createAsyncThunk(
  "check/fetchCheckValues",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const values = res.data.data;
      return values;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addCheckValue = createAsyncThunk(
  "check/addCheckValue",
  async (value) => {
    try {
      const res = await axios.post(`${active_base_url}/add`, value, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addValue = res.data.data;
      return addValue;
    } catch (err) {
      console.log(err);
    }
  }
);

export const checkValueSlice = createSlice({
  name: "checkValue",
  initialState: { value: initStateValue },
  reducers: {
    clearCheckValues: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCheckValues.pending, (state, action) => {
        state.value.status = "loading";
        state.value.checkValues = action.payload;
      })
      .addCase(fetchCheckValues.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.checkValues = action.payload;
      })
      .addCase(fetchCheckValues.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllCheckValues = (state) =>
  state.checkValue.value.checkValues;

export const getCheckValueStatus = (state) => state.checkValue.value.status;

export const { clearCheckValues } = checkValueSlice.actions;

export default checkValueSlice.reducer;
