import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { fetchFields } from "../../features/programField";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const ProgramField = ({ index, field, props }) => {
  useEffect(() => {
    console.log("Hi from inside program field");
  }, []);
  const [deleteHover, setDeleteHover] = useState(false);

  const [editHover, setEditHover] = useState(false);

  const [required, setRequired] = useState(field.required);
  const updateRequired = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/program/field/required/update`
          : `http://localhost:3000/program/field/required/update`;

      const res = await axios.post(
        url,
        { id: field.id, required: required },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedField = res.data.data;
      console.log(updatedField);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    updateRequired();
    fetchFields();
  }, [required]);

  return (
    <tr
      onClick={() => {
        props.fieldController.openUpdateModal(field);
      }}
      className={`rounded-lg dark:text-white text-black text-start hover:bg-blue-50`}
    >
      <td className="pl-2 py-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {field.fieldName}
      </td>

      <td className="pl-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {field.fieldType}
      </td>
      <td className="pl-2 text-center text-sm dark:text-white cursor-pointer whitespace-nowrap">
        {field.applicationOrder}
      </td>
      <td className="pl-2">
        {required !== null && (
          <input
            type="checkbox"
            checked={required}
            onChange={() => {
              setRequired(!required);
            }}
          />
        )}
      </td>

      <td className="pl-2 text-sm text-gray-700 whitespace-nowrap flex gap-1 justify-start">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            props.fieldController.openUpdateModal(field);
          }}
        >
          <BsFillPencilFill
            size="18"
            className="cursor-pointer dark:text-white"
          />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        {localStorage.getItem("role") === "ADMIN" && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={(e) => {
              e.stopPropagation();
              props.fieldController.openDeleteModal(field);
            }}
          >
            <BsFillTrashFill
              size="18"
              className="cursor-pointer dark:text-white"
            />
            {deleteHover && (
              <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
                delete
              </div>
            )}
          </motion.button>
        )}
      </td>
    </tr>
  );
};

export default ProgramField;
