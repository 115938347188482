import React, { useState } from "react";
import { motion } from "framer-motion";
import ApplicationStatus from "./ApplicationStatus";

export const ApplicationStatusHistory = ({ props }) => {
  console.log("commHistory", props);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`application-table bg-white dark:bg-gray-700 p-4 w-full duration-400 ease-in-out transition-spacing shadow font-sans flex flex-col gap-4`}
    >
      <div className="overflow-auto w-full text-white rounded- py-4 px-8">
        <table className="table-auto w-full text-left mt-4 shadow">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50 text-gray-900 ">
              <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                Date Changed
              </th>
              <th className="p-3 text-sm  tracking-wide text-start whitespace-nowrap">
                Status #
              </th>
              <th className="p-3 text-sm  tracking-wide text-start whitespace-nowrap">
                Status Name
              </th>
              <th className="p-3 text-sm  tracking-wide text-start whitespace-nowrap">
                Assigned Staff
              </th>
              <th className="p-3 text-sm  tracking-wide text-start whitespace-nowrap">
                Changed By
              </th>
              <th className="p-3 text-sm  tracking-wide text-start whitespace-nowrap ">
                Comment
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {props.statusLogs &&
              props.statusLogs.map((status, index) => {
                return (
                  <ApplicationStatus
                    props={{
                      status: status,
                      getStatusLogs: props.getStatusLogs,
                    }}
                    key={index}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default ApplicationStatusHistory;
