import Landing from "./pages/LandingPage";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Dashboard from "./pages/Dashboard.js";
import ForogtPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import RegistrationSuccess from "./pages/RegistrationSuccess.js";
import CurrentPrograms from "./pages/CurrentPrograms.js";
import "./config.js";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
  }, [location.search]);
  return (
    <div className="App">
      <Routes location={location} keys={location.pathname}>
        <Route path="/" element={<Landing />} />
        <Route path="forgotPassword" element={<ForogtPassword />} />
        <Route path="registration" element={<RegistrationSuccess />} />
        <Route path="currentPrograms" element={<CurrentPrograms />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
