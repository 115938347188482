import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { FaUserPlus } from "react-icons/fa";
import { Button } from "../Ui/button";

import { fetchUploads, updateUpload } from "../../features/programUpload";
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const UpdateUploadModal = ({ props }) => {
  const dispatch = useDispatch();
  console.log("radf", props);

  //   const initialUser = {
  //     name: "",
  //     description: props.uploadController.currentUpload.description,
  //     programId: props.uploadController.currentUpload.programId,
  //   };
  const [upload, setUpload] = useState({
    description: props.uploadController.currentUpload.description,
    id: props.uploadController.currentUpload.id,
    name: props.uploadController.currentUpload.name,
    required: props.uploadController.currentUpload.required,
  });

  const submit = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      await dispatch(updateUpload(upload));
      await dispatch(fetchUploads());
      //props.handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={props.uploadController.closeUploadUpdateModal}>
      <motion.div
        className="rounded w-[45rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 ">
          <div className=" rounded w-full flex gap-2 items-center justify-start ">
            <h2 className="text-lg font-bold text-center flex gap-2 text-green-tropical">
              {/* <FaUserPlus size="28" /> */}
              Update Document Upload
            </h2>
          </div>
          <div className="flex flex-col gap-1">
            <span className=" text-blue-reef">Document Name</span>
            <input
              type="text"
              className="text-black rounded  border border-gray-200"
              placeholder="Name"
              defaultValue={upload.name}
              name="uploadName"
              onChange={(e) => {
                setUpload((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
            <span>
              <span>Required Document</span>
              <input
                type="checkbox"
                className="ml-3 w-4 h-4"
                checked={upload.required}
                onChange={(e) => {
                  setUpload((prev) => ({
                    ...prev,
                    required: e.target.checked,
                  }));
                }}
              />
            </span>
            <span className=" text-blue-reef">Document Description</span>
            <textarea
              className="text-black rounded  border border-gray-200 resize-none h-28"
              placeholder=" Description"
              defaultValue={upload.description}
              name="uploadDescription"
              rows={3}
              onChange={(e) => {
                setUpload((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
          </div>

          {/* <motion.button
            whileHover={{ scale: 1 }}
            whileTap={{ scale: 1 }}
            className="marginXAuto text-white bg-green-reef hover:bg--700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
            onClick={(e) => {
              submit(e);
            }}
          >
            SAVE
          </motion.button> */}
          <Button
            variant="outline"
            className="bg-green-reef text-white rounded w-2/12 ml-auto"
            onClick={() => {
              props.exportExcel();
            }}
          >
            Save
          </Button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateUploadModal;
