import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Ui/button";

import { createPasswordReset } from "../features/passwordReset";

import "react-toastify/dist/ReactToastify.css";

import "react-quill/dist/quill.snow.css";

const ForogtPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const submitPasswordReset = async () => {
    try {
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
      const res = await dispatch(
        createPasswordReset({
          email: email,
          url: `${window.location.protocol}//${window.location.host}`,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <div
        className={`flex h-screen transform items-center justify-center bg-gray-50 bg-cover font-sans transition duration-200`}
      >
        <div className="flex flex-col gap-4 p-12 sm:w-8/12 md:w-4/12">
          <div className="mx-auto mb-12 flex h-[5rem] w-[21rem] bg-test1 bg-cover"></div>

          <div className="flex flex-col gap-2">
            <span className="font-medium">Forgot Password?</span>
            <span>
              Enter your email address to receive a link to reset your password.
              Remember your credentials?{" "}
              <a
                className="cursor-pointer text-blue-400"
                onClick={() => {
                  navigate("/");
                }}
              >
                Sign in
              </a>{" "}
              to your account.
            </span>
          </div>
          <motion.div className="flex w-full flex-col rounded border border-gray-100 bg-white p-4 shadow">
            <span>Email</span>
            <input
              type="text"
              className="mb-4 h-[2rem] border border-gray-100"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Button
              variant="outline"
              className="mx-auto w-3/12 rounded bg-green-reef text-white"
              onClick={() => {
                submitPasswordReset();
              }}
            >
              Submit
            </Button>
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default ForogtPassword;
