import React, { useEffect, useState } from "react";
import Status from "./Status";
import ProgramField from "./ProgramField";
import ProgramUpload from "./ProgramUpload";
import { motion } from "framer-motion";
import { deleteField, fetchFields } from "../../features/programField";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuSelect, setMenuSelect } from "../../features/program";
import { Button } from "../Ui/button";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import ReactQuill, { Quill } from "react-quill";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.8,
      type: "spring",
      damping: 100,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};

const ApprovalSteps = ({ props }) => {
  const dispatch = useDispatch();
  const [programFields, setProgramFields] = useState(null);
  const [programUploads, setProgramUploads] = useState(null);
  const [programStatus, setProgramStatus] = useState(null);
  const menuSelect = useSelector(selectMenuSelect);

  useEffect(() => {
    const temp = props.fields?.filter((field) => {
      return field?.programId === props.program[0].id;
    });

    console.log("Application Order: ", temp);
    const sortByAppOrder = temp.sort((a, b) => {
      return a.applicationOrder - b.applicationOrder;
    });
    setProgramFields(sortByAppOrder);
  }, [props.fields, props.program]);

  useEffect(() => {
    const temp = props.uploads?.filter((upload) => {
      return upload?.programId === props.program[0].id;
    });
    setProgramUploads(temp);
  }, [props.uploads, props.program]);

  useEffect(() => {
    const temp = props.statusList?.filter((status) => {
      return status?.programId === props.program[0].id;
    });

    const sorted_status = temp?.sort((a, b) => {
      return parseInt(a.statusId) - parseInt(b.statusId);
    });

    setProgramStatus(sorted_status);
  }, [props.statusList, props.program]);

  //field delete

  const [fieldController, setFieldController] = useState({
    deleteModal: false,
    deleteFieldId: null,
    deleteFieldFunction: async (fieldId) => {
      try {
        await dispatch(deleteField(fieldId));
        await dispatch(fetchFields());
      } catch (err) {
        console.log(err);
      }
    },
    openDeleteModal: (res) => {
      setFieldController((prev) => ({
        ...prev,
        deleteModal: true,
        deleteFieldId: res.id,
      }));
    },
    closeDeleteModal: () => {
      setFieldController((prev) => ({
        ...prev,
        deleteModal: false,
      }));
    },
    updateModal: false,
    currentField: null,
    openUpdateModal: (field) => {
      setFieldController((prev) => ({
        ...prev,
        updateModal: true,
        currentField: field,
      }));
    },
    closeUpdateModal: () => {
      setFieldController((prev) => ({
        ...prev,
        updateModal: false,
      }));
    },
  });

  const updateConfigMenu = async (index) => {
    try {
      const temp = [false, false, false];
      temp[index] = true;
      await dispatch(setMenuSelect({ menuState: temp }));
    } catch (err) {
      console.log(err);
    }
  };

  const [modalMenu, setModalMenu] = useState({
    menu: [true, false, false],
    setMenu: (payload) => {
      const temp = [false, false, false, false];
      temp[payload] = true;
      setModalMenu((prev) => ({
        ...prev,
        menu: temp,
      }));
    },
  });
  return (
    <motion.div
      className="flex flex-col gap-2  relative w-full h-screen"
      variants={slideIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      <div className="flex gap-1 ">
        <Button
          variant="outline"
          className=" text-white rounded bg-gray-400 h-[3rem]"
          onClick={() => {
            props.workflowController.closeModal();
          }}
        >
          Program Configuration
        </Button>
        <div className="flex justify-center sticky top-0 w-full border rounded mb-4  z-50 font-sans shadow">
          <div
            className={` px-2 py-2 cursor-pointer flex justify-center w-full border-r  border-gray-400 hover:underline bg-gray-100 font-semibold text-gray-900 text-lg ${
              menuSelect[0] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              updateConfigMenu(0);
              modalMenu.setMenu(0);
            }}
          >
            <span className="tracking-wider">Workflow Configuration</span>
          </div>
          <div
            className={` px-2 py-2 cursor-pointer flex w-full justify-center border-r bg-gray-100 border-gray-400 hover:underline font-semibold text-gray-900 text-lg  ${
              menuSelect[1] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              updateConfigMenu(1);
              modalMenu.setMenu(1);
            }}
          >
            <span className="tracking-wider ">Application Fields</span>
          </div>
          <div
            className={`px-2 py-2 cursor-pointer flex w-full justify-center bg-gray-100 hover:underline border-r border-gray-400 font-semibold text-gray-900 text-lg ${
              menuSelect[2] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              updateConfigMenu(2);
              modalMenu.setMenu(2);
            }}
          >
            <span className="tracking-wider">Document Uploads</span>
          </div>
          <div
            className={`px-2 py-2 cursor-pointer flex w-full justify-center bg-gray-100 hover:underline font-semibold text-gray-900 text-lg  ${
              menuSelect[3] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              updateConfigMenu(3);
              modalMenu.setMenu(3);
            }}
          >
            <span className="tracking-wider">Email Templates</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 rounded  shadow-md overflow-auto">
        {menuSelect[0] === true && (
          <div className="steps flex flex-col gap-4 border-2 shadow rounded">
            <div className="text w-full bg-gray-50 text-black shadow-md tracking-wider p-2 sticky top-0 bottom-0 rounded-t flex items-center gap-4 ">
              <span>Workflow Configurations</span>

              <Button
                variant="outline"
                className="bg-blue-reef text-white rounded"
                onClick={() => {
                  props.openAddStatusModal();
                }}
              >
                Add
              </Button>
            </div>
            <span className="pl-2 italic text-blue-500">
              Reminder - every workflow must have Status Name “Complete” and
              Status Name “Cancelled” rows somewhere in the workflow in order to
              correctly populate the dashboard reports. Any Status Name that
              does not match those two options is considered in progress.
            </span>
            <div className="rounded">
              <table className="w-full shadow">
                <thead className="border-b-2 border-gray-200 bg-blue-reef text-white">
                  <th className="pl-2 py-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50 ">
                    Step #
                  </th>
                  <th className="pl-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50 ">
                    Customer Step #
                  </th>
                  <th className="pl-2  font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50 ">
                    Status Name
                  </th>
                  <th className="pl-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50 ">
                    Customer Status Name
                  </th>
                  <th className="pl-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50 ">
                    # Days Pending For Alert
                  </th>
                  <th className="pl-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50">
                    Actions
                  </th>
                </thead>
                <tbody className="divide-y">
                  {programStatus &&
                    programStatus.map((el, index) => {
                      return (
                        <Status
                          index={index}
                          status={el}
                          key={index}
                          props={{ statusController: props.statusController }}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {menuSelect[1] === true && (
          <div className="steps flex flex-col gap-4 relative border-2 shadow rounded ">
            <div className="text w-full bg-gray-50 text-black shadow-md tracking-wider rounded p-2 sticky top-0 bottom-0 rounded-t flex items-center gap-4 ">
              <span>Application Fields</span>
              <Button
                variant="outline"
                className="bg-blue-reef text-white rounded"
                onClick={() => {
                  props.openAddFieldModal();
                }}
              >
                Add
              </Button>
            </div>

            <div className="">
              <table className="w-full shadow-lg">
                <thead className="border-b-2 border-gray-200 bg-blue-reef text-white">
                  <th className="pl-2 py-2 font-semibold tracking-widerr text-sm text-start whitespace-nowrap border-r border-gray-50">
                    Field Name
                  </th>

                  <th className="pl-2 font-semibold tracking-widerr text-sm text-start whitespace-nowrap border-r border-gray-50">
                    Field Type
                  </th>
                  <th className="pl-2 font-semibold tracking-widerr text-sm text-start whitespace-nowrap border-r border-gray-50">
                    Application Order
                  </th>
                  <th className="pl-2 font-semibold tracking-widerr text-sm text-start whitespace-nowrap border-r border-gray-50">
                    Required
                  </th>
                  <th className="pl-2 font-semibold tracking-widerr text-sm text-start  whitespace-nowrap border-r ">
                    Actions
                  </th>
                </thead>
                <tbody className="divide-y">
                  {programFields &&
                    programFields?.map((el, index) => {
                      return (
                        <ProgramField
                          index={index}
                          field={el}
                          key={index}
                          props={{ fieldController: props.fieldController }}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {menuSelect[2] === true && (
          <div className="steps flex flex-col gap-4  relative border-2 shadow rounded">
            <div className="text w-full bg-gray-50 text-black tracking-wider p-2 sticky top-0 bottom-0 rounded-t flex items-center gap-4 shadow-md">
              <span>Required Documentation Upload</span>

              <Button
                variant="outline"
                className="bg-blue-reef text-white rounded"
                onClick={() => {
                  props.openAddUploadModal();
                }}
              >
                Add
              </Button>
            </div>
            <div className="flex flex-col">
              <table className="w-full shadow-lg">
                <thead className="border-b-2 border-gray-200 bg-blue-reef text-white">
                  <th className="pl-2 py-2 font-semibold tracking-widerr text-start text-sm whitespace-nowrap border-r border-gray-50">
                    Document Name
                  </th>

                  <th className="pl-2 font-semibold tracking-widerr text-start text-sm whitespace-nowrap">
                    Actions
                  </th>
                </thead>
                <tbody className="divide-y">
                  {programUploads &&
                    programUploads.map((el, index) => {
                      return (
                        <ProgramUpload
                          upload={el}
                          key={index}
                          props={{
                            uploadController: props.uploadController,
                            openUploadModal: props.openUploadModal,
                          }}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {menuSelect[3] === true && (
          <div className="steps flex flex-col gap-4  relative border-2 shadow rounded">
            <div className="text w-full bg-gray-50 text-black tracking-wider p-2 sticky top-0 bottom-0 rounded-t flex items-center gap-4 shadow-md">
              <span>Email Templates</span>

              <Button
                variant="outline"
                className="bg-blue-reef text-white rounded"
                onClick={() => {
                  props.openAddTemplateModal({ type: "add" });
                }}
              >
                Add
              </Button>
            </div>
            <div className="flex flex-col">
              {/* <MainTable
                props={{
                  columns: ["Email Name", "Subject Line", "Email Body"],
                }}
              /> */}
              <table className="w-full shadow-lg">
                <thead className="border-b-2 border-gray-200 bg-blue-reef text-white">
                  <th className="pl-2 py-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50">
                    Email Name
                  </th>

                  <th className="pl-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap border-r border-gray-50">
                    Subject Line
                  </th>
                  <th className="pl-2 font-semibold tracking-wider text-start w-6/12 text-sm whitespace-nowrap border-r border-gray-50">
                    Email Body
                  </th>
                  <th className="pl-2 font-semibold tracking-wider text-start text-sm whitespace-nowrap">
                    Actions
                  </th>
                </thead>
                <tbody className="divide-y">
                  {props.templates.map((x) => {
                    return (
                      <tr
                        className="rounded-lg dark:text-white text-black text-start hover:bg-blue-50"
                        onClick={() => {
                          props.openAddTemplateModal({
                            type: "edit",
                            id: x.id,
                            emailName: x.emailName,
                            subjectLine: x.subjectLine,
                            bodyText: x.bodyText,
                          });
                        }}
                      >
                        <td className="pl-2 py-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
                          {x.emailName}
                        </td>
                        <td className="pl-2 py-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
                          {x.subjectLine}
                        </td>
                        <td className="pl-2 py-2 text-sm dark:text-white cursor-pointer whitespace-nowrap">
                          <ReactQuill
                            className="w-full rounded h-[5rem] mb-4 cursor-pointer"
                            readOnly={true}
                            modules={{ toolbar: false }}
                            value={x.bodyText}
                          />
                        </td>
                        <td className="pl-2 py-2 text-sm dark:text-white cursor-pointer whitespace-nowrap flex gap-1">
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => {
                              props.openAddTemplateModal({
                                type: "edit",
                                id: x.id,
                                emailName: x.emailName,
                                subjectLine: x.subjectLine,
                                bodyText: x.bodyText,
                              });
                            }}
                          >
                            <BsFillPencilFill
                              size="18"
                              className="cursor-pointer dark:text-white"
                            />
                          </motion.button>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              props.openDeleteTemplate(x.id);
                            }}
                          >
                            <BsFillTrashFill
                              size="18"
                              className="cursor-pointer dark:text-white"
                            />
                          </motion.button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ApprovalSteps;
