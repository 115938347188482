import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { selectAllProperties } from "../../features/properties";
import { useDispatch, useSelector } from "react-redux";
import { formatFullAddress } from "../../utils/formatAddress";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const CustomerPopupModalProperty = ({ props }) => {
  console.log("Specific Customer Table Row Props", props);
  console.log("customer");
  const properties = useSelector(selectAllProperties);
  const [customerProperty, setCustomerProperty] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [city, setCity] = useState(null);

  const getAccount = async (input) => {
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
        : `http://localhost:3000/account/fetch-by-id`;

    const res = await axios.post(
      url,
      { id: parseInt(input) },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    // console.log("Account data: ", res);
    return res.data;
  };

  const setAccountStatusText = async () => {
    let tmp = await getAccount(props?.property?.accountNo);
    setAccountStatus(
      tmp.data
        ? tmp.data?.cutoffDt && tmp.data?.cutoffDt != "NULL"
          ? "Not Active at Property"
          : "Active at Property"
        : "N/A"
    );
  };

  setAccountStatusText();

  const [accountStatus, setAccountStatus] = useState("Loading...");

  useEffect(() => {
    if (properties) {
      // fetchCustomerProperties();
      const propertyInfo = properties.filter((property) => {
        return property.id === props.property?.propertyId;
      })[0];

      console.log("propsInfo", propertyInfo);
      setCustomerProperty(propertyInfo);

      setStreetName(
        formatFullAddress([
          propertyInfo?.mfcStreetNo,
          propertyInfo?.streetPfxDir,
          propertyInfo?.mfcStreetName,
          propertyInfo?.streetNmSfx,
          propertyInfo?.streetSfxDir,
          propertyInfo?.secAddrId,
          propertyInfo?.secAddrRange,
          propertyInfo?.city,
          propertyInfo?.provinceCd,
          propertyInfo?.postalCode,
        ])
      );
      setCity(propertyInfo?.city);
    }
  }, [properties, props]);
  const [deleteHover, setDeleteHover] = useState(false);
  const deleteMouseOver = () => {
    setDeleteHover(true);
  };
  const deleteMouseOut = () => {
    setDeleteHover(false);
  };
  const [editHover, setEditHover] = useState(false);
  const editMouseOver = () => {
    setEditHover(true);
  };
  const editMouseOut = () => {
    setEditHover(false);
  };

  return (
    <>
      {props.property && (
        <tr
          className={`rounded-lg dark:text-white text-black text-start p-3 cursor-pointer hover:bg-blue-50 `}
        >
          <td
            className="p-3 dark:text-white underline text-blue-reef whitespace-nowrap"
            onClick={() => {
              //   openPopupModal(props.id);
              console.log("hilo");
              console.log("props.property", props.property);
              // if (props.modalController && customerProperty) {
              // console.log("propertysss", customerProperty);

              props.modalController.openPropertyModal(customerProperty, 0, 0);

              // }
            }}
          >
            {props.property?.propertyId}
          </td>

          <td className="p-3 text-sm whitespace-nowrap">
            {props.property?.accountNo && props.property?.accountNo}
          </td>

          <td className="p-3 text-sm whitespace-nowrap">
            {accountStatus && props?.property?.accountNo
              ? accountStatus
              : "N/A"}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Street */}
            {streetName ? streetName : ""}
          </td>

          <td className="p-3 text-sm whitespace-nowrap">
            {/* Property Type */}
            {props.property?.singleHomeFamily && (
              <div>
                <span>Single Family Home</span>
              </div>
            )}
            {props.property?.multiFamilyComplex && (
              <div>
                <span>Multi Family Complex or HOA</span>
              </div>
            )}
            {props.property?.mfcUnitNo && props?.property?.mfcUnitNo != 0 && (
              <div>
                <span>{`Unit: ${props?.property?.mfcUnitNo}`}</span>
              </div>
            )}
            {props.property?.hoAname && (
              <div>
                <span>HOA Name: {props.property.hoAname}</span>
              </div>
            )}
            {props.property?.residential && (
              <div>
                <span>Mixed Use Commercial / Residential</span>
              </div>
            )}
            {props.property?.commercial && (
              <div className="flex flex-col">
                <span>Commercial</span>
              </div>
            )}
            {props.property?.typeofCommercialProperty &&
              props.property?.typeofCommercialProperty != "null" && (
                <span>{props.property?.typeofCommercialProperty}</span>
              )}
            {props.property?.landscape && (
              <div>
                <span>Landscape (Irrigation Account)</span>
              </div>
            )}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Owner? */}
            <input
              type="checkbox"
              className="mr-3"
              checked={props.property?.owner}
            />
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Tenant? */}
            <input
              type="checkbox"
              className="mr-3"
              checked={props.property?.tenant}
            />
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Account holder */}
            <input
              type="checkbox"
              className="mr-3"
              checked={props.property?.isAccountHolder}
            />
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Account holder */}
            <input
              type="checkbox"
              className="mr-3"
              checked={props.property?.isLandscaper}
            />
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Account holder */}
            <input
              type="checkbox"
              className="mr-3"
              checked={props.property?.isPropertyManager}
            />
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Account holder */}
            <input
              type="checkbox"
              className="mr-3"
              checked={props.property?.isLandlord}
            />
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {/* Other Relationship */}
            {props.property?.otherRelationshipText}
          </td>
          {/* <td className="p-3 text-sm whitespace-nowrap">{props.address}</td>
        <td className="p-3 text-sm whitespace-nowrap">{props.property}</td>
        <td className="p-3 text-sm whitespace-nowrap">
          {props.associatedApplications}
        </td> */}
          {/* <td className="p-3 text-sm whitespace-nowrap">
          {props.cumulativeSavings}
        </td> */}
          {/* <td className="p-3 text-sm whitespace-nowrap">{props.cumulativeCosts}</td>
        <td className="p-3 text-sm text-gray-700 whitespace-nowrap flex gap-1 justify-center"> */}
          {/* <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              // openUpdateModal({
              //   id: user.id,
              //   firstName: user.firstName,
              //   lastName: user.lastName,
              //   email: user.email,
              //   phone: user.phone,
              //   deleted: user.deleted,
              //   role: user.role,
              // });
              // openUpdateModal();
            }}
            onMouseOver={editMouseOver}
            onMouseOut={editMouseOut}
          >
            <BsFillPencilFill
              size="18"
              className="cursor-pointer dark:text-white"
            />
            {editHover && (
              <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
                edit
              </div>
            )}
          </motion.button> */}

          {/* {localStorage.getItem("role") === "ADMIN" && (
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                // user.deleted
                //   ? alert("user is already inactive")
                //   : openDeleteModal(user.id);
                //   openDeleteModal();
              }}
              onMouseOver={deleteMouseOver}
              onMouseOut={deleteMouseOut}
            >
              <BsFillTrashFill
                size="18"
                className="cursor-pointer dark:text-white"
              />
              {deleteHover && (
                <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
                  delete
                </div>
              )}
            </motion.button>
          )} */}
          {/* </td> */}
        </tr>
      )}
    </>
  );
};

export default CustomerPopupModalProperty;
