import React, { useState, useRef } from "react";
import SidebarFooter from "./SidebarFooter";

import { IoIosHome } from "react-icons/io";
import { MdAccountTree, MdWaterDrop } from "react-icons/md";
import { HiUserGroup, HiOutlineDocumentReport } from "react-icons/hi";
import { FaUserCog } from "react-icons/fa";

import useDarkMode from "../../hook/useDarkMode";

import { MdHomeWork } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";

import { AnimatePresence } from "framer-motion";

import { HiOutlineClipboardList } from "react-icons/hi";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FiMinimize2 } from "react-icons/fi";
import { BiExpand } from "react-icons/bi";

const CustomerSidebar = ({
  toggleSidebar,
  showSidebar,
  selectMenu,
  menuOptions,
  type,
}) => {
  const [customerMenuIndex, setCustomerMenuIndex] = useState(
    Array(4).fill(false)
  );

  const customerMenuToggleOn = (index) => {
    const tempArr = Array(4).fill(false);
    tempArr[index] = true;
    setCustomerMenuIndex(tempArr);
    // console.log("customerMenu", customerMenuIndex);
  };
  const customerMenuToggleOff = () => {
    const tempArr = Array(4).fill(false);
    setCustomerMenuIndex(tempArr);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-[16rem] text-white inset-y-0 transform transition duration-200 ease-in-out font-sans z-20 mt-[5rem] shadow-xl ${
        showSidebar ? "" : "-translate-x-3/4 "
      }`}
    >
      <div className="fixed top-0 left-0 flex flex-col min-h-screen w-[16em] bg-[#7d7d7d] bg-gradient-to-b from-[#7d7d7d] to-[#cacaca] dark:bg-transparent dark:bg-gray-800 transition duration-200 text-white shadow-xl">
        <AnimatePresence exitBeforeEnter initial={false}>
          <div className="text-lg mb-[2rem] mt-[3rem]">
            <div className="flex flex-col gap-4">
              <ul className="flex flex-col gap-4 ml-[1.8rem]">
                <li
                  className={`flex items-center cursor-pointer rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  } ${menuOptions[0] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(0, "CUSTOMER");
                  }}
                  onMouseOver={() => {
                    customerMenuToggleOn(0);
                  }}
                  onMouseOut={() => {
                    customerMenuToggleOff(0);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "w-8 h-8 mr-2 text-black flex items-center justify-center rounded "
                        : "pl-4 w-16 h-16 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                    }`}
                  >
                    <span
                      className={`rounded p-1  ${
                        customerMenuIndex[0] === true && !menuOptions[0]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[0] && "bg-wc-green"}`}
                    >
                      <HiOutlineClipboardList
                        size={`${showSidebar ? "22" : "32"}`}
                      />
                    </span>
                  </div>

                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    My Applications
                  </span>
                </li>
                <li
                  className={`flex items-center cursor-pointer rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  }  ${menuOptions[1] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(1, "CUSTOMER");
                  }}
                  onMouseOver={() => {
                    customerMenuToggleOn(1);
                  }}
                  onMouseOut={() => {
                    customerMenuToggleOff(1);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "w-8 h-8 mr-2 text-black flex items-center justify-center rounded "
                        : "pl-4 w-16 h-16 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                    }`}
                  >
                    <span
                      className={`rounded p-1  ${
                        customerMenuIndex[1] === true && !menuOptions[1]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[1] && "bg-wc-green"}`}
                    >
                      <AiOutlineFileAdd size={`${showSidebar ? "22" : "32"}`} />
                    </span>
                  </div>

                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Start Application
                  </span>
                </li>
                <li
                  className={`flex items-center cursor-pointer rounded hover:bg-wc-green ${
                    showSidebar ? "w-11/12" : "w-full"
                  }  ${menuOptions[2] && "bg-wc-green shadow-md"}`}
                  onClick={() => {
                    selectMenu(2, "CUSTOMER");
                  }}
                  onMouseOver={() => {
                    customerMenuToggleOn(2);
                  }}
                  onMouseOut={() => {
                    customerMenuToggleOff(2);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "w-8 h-8 mr-2 text-black flex items-center justify-center rounded "
                        : "pl-4 w-16 h-16 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                    }`}
                  >
                    <span
                      className={`rounded p-1  ${
                        customerMenuIndex[2] === true && !menuOptions[2]
                          ? "text-white"
                          : "text-white"
                      } ${menuOptions[2] && "bg-wc-green"}`}
                    >
                      {/*<AiOutlineFileAdd size={`${showSidebar ? "22" : "32"}`} />*/}
                      <IoIosHome
                        size={`${showSidebar ? "22" : "32"}`}
                        //className={`${menuOptions[0] && "text-white"}`}
                      />
                    </span>
                  </div>

                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    My Profile
                  </span>
                </li>
                {/* <li
                  className="flex items-center cursor-pointer rounded"
                  onClick={() => {
                    selectMenu(3, "CUSTOMER");
                  }}
                  onMouseOver={() => {
                    customerMenuToggleOn(3);
                  }}
                  onMouseOut={() => {
                    customerMenuToggleOff(3);
                  }}
                >
                  <div
                    className={`"" ${
                      showSidebar
                        ? "w-8 h-8 mr-2 text-black flex items-center justify-center rounded "
                        : "w-16 h-16 mr-[.48em] text-black flex items-center justify-center rounded  ml-auto relative"
                    }`}
                  >
                    <span
                      className={`rounded p-1  ${
                        customerMenuIndex[3] === true && !menuOptions[3]
                          ? "text-gray-200"
                          : "text-gray-500"
                      } ${menuOptions[3] && "bg-gray-200"}`}
                    >
                      <AiOutlineFileAdd size={`${showSidebar ? "22" : "32"}`} />
                    </span>
                  </div>

                  <span className={`${showSidebar ? "basis-8/12" : "hidden"}`}>
                    Properties
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
        </AnimatePresence>
        <div className="flex justify-end mt-3 mb-3 mb-[2rem] pr-5 cursor-pointer">
          <span className="border-white border rounded hover:bg-gray-300 shadow">
            {showSidebar ? (
              <FiMinimize2
                size="24"
                className="text-white"
                onClick={() => {
                  toggleSidebar();
                }}
              />
            ) : (
              <BiExpand
                size="24"
                className="text-white"
                onClick={() => {
                  toggleSidebar();
                }}
              />
            )}
          </span>
        </div>
        <hr
          className={
            showSidebar
              ? "opacity-40 w-10/12 mx-auto mb-12"
              : "opacity-40 w-full mb-12"
          }
        />
        {showSidebar && (
          <div className="flex flex-col items-center justify-center gap-1 text-sm">
            {/* <span className="text-center text-light-green-reef">
              Powered By Woodard & Curran
            </span> */}
            <span className="text-white w-11/12 text-start ">
              Contact Us - Contact our Water Use Efficiency team by calling
              (510) 668-4218 or by{" "}
              <a
                className="cursor-pointer text-white underline"
                href="https://www.acwd.org/forms.aspx?fid=116"
                target="_blank"
              >
                emailing us
              </a>.
            </span>
            <a
              className="rounded-xl tracking-wider cursor-pointer w-11/12 text-start text-white underline"
              href="http://www.acwd.org"
              target="_blank"
            >
              www.acwd.org
            </a>
          </div>
        )}
        {/* 
        <SidebarFooter toggle={toggleSidebar} showSidebar={showSidebar} /> */}
      </div>
    </div>
  );
};

export default CustomerSidebar;
