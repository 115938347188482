import React from "react";

const ProgramSummaryCircleSeparator = ({
  props,
  label,
}) => {
  return (
    <div className="align-middle mt-auto mb-auto">
        <div className="w-auto flex flex-col  ">
            <div className={`flex relative transition-all duration-600 s after:bg-gray-400 `} >
                <div className={`border-0 w-[2rem] h-[0.25rem] flex justify-center items-center z-40 transition-all duration-800 border-blue-700 bg-gray-700`}>
                    <span className="text-white z-50">
                        {label}
                    </span>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ProgramSummaryCircleSeparator;
