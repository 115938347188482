import React, { useState, useEffect } from "react";
import CustomerContactTable from "./CustomerContactTable";
import { useDispatch, useSelector } from "react-redux";
import UnlockApplicationModal from "./UnlockApplicationModal";
import { setToast } from "../../utils/ToastNotification";
import ReactQuill, { Quill } from "react-quill";
import { selectAllPrograms } from "../../features/program";
import { selectAllApplications } from "../../features/application";
import { selectAllCustomers } from "../../features/customer";
import { emailTemplateByProgramId } from "../../features/emailTemplate";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const CommunicationHistory = ({ props }) => {
  const dispatch = useDispatch();
  const programs = useSelector(selectAllPrograms);
  const applicationList = useSelector(selectAllApplications);
  const customers = useSelector(selectAllCustomers);
  const [addNote, setAddNote] = useState(false);
  const openAddNote = () => {
    setAddNote(true);
  };
  const closeAddNote = () => {
    setAddNote(false);
  };

  const [appModal, setAppModal] = useState(false);
  const openModal = () => {
    setAppModal(true);
  };
  const closeModal = () => {
    setAppModal(false);
  };

  const [sortedStatus, setSortedStatus] = useState(null);
  useEffect(() => {
    const sorted_status = props.statusList.slice().sort((a, b) => {
      return parseInt(a.statusId) - parseInt(b.statusId);
    });

    setSortedStatus(sorted_status);
  }, [props.statusList]);

  const [note, setNote] = useState(null);
  const saveNote = async (index) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/communication-history/add`
          : "http://localhost:3000/application/communication-history/add";

      if (index === 0) {
        const res = await axios.post(
          url,
          {
            applicationId: props.applicationId,
            userId: parseInt(localStorage.getItem("user_id")),
            value: note,
            unlockNote: false,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (index === 1) {
        const res = await axios.post(
          url,
          {
            applicationId: props.applicationId,
            userId: parseInt(localStorage.getItem("user_id")),
            value: "Application unlocked with note: " + unlockDescription,
            unlockNote: true,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const commLog = res.data.data;
      }
      setNote("");
      setAddNote(false);
      props.getCommunicationLogs();
    } catch (err) {
      console.log(err);
    }
  };
  ///

  const [templateAddress, setTemplateAddress] = useState("");

  useEffect(() => {
    const street =
      props.property.manualEntry === null
        ? props.formatAddress([
            props.property.location?.houseNo,
            props.property.location?.streetPfxDir,
            props.property.location?.streetNm,
            props.property.location?.streetNmSfx,
            props.property.location?.streetSfxDir,
            props.property.location?.secAddrId,
            props.property.location?.secAddRange,
          ])
        : `${props.property.mfcStreetNo ? props.property.mfcStreetNo : ""} ${
            props.property.mfcStreetName ? props.property.mfcStreetName : ""
          }`;

    const temp = `${street.trim()}, ${props.property?.city.trim()}, ${props.property?.postalCode.trim()}`;

    setTemplateAddress(temp);
  }, [props]);

  function convertWordsInBracketsToLowerCase(inputString) {
    // Regular expression to match words inside square brackets
    const regex = /\[([^\]]+)\]/g;

    // Replace each match with the lowercase version of the word
    const processedString = inputString.replace(regex, (match, word) => {
      return `[${word.trim().toLowerCase()}]`;
    });

    return processedString;
  }

  const addVars = (payload) => {
    let temp = payload.replace(/\[application id\]/g, props.applicationId);

    temp = temp.replace(/\[cost\]/g, `$${props.cost}`);

    temp = temp.replace(/\[property address\]/g, templateAddress);

    return temp;
  };

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({
    subjectLine: "",
    bodyText: "",
  });

  const [subjectLine, setSubjectLine] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [emailNote, setEmailNote] = useState("");

  const resetEmailVars = () => {
    setBodyText("");
    setSubjectLine("");
  };

  function fixEncoding(text) {
    return text.replace(/â€™/g, "’");
  }

  useEffect(() => {
    // const bodyWithoutP = bodyText.substring(3, bodyText.length - 4);
    const bodyWithoutP = bodyText;

    const bodyWithHT = bodyWithoutP.replace(/"/g, ""); //removed double quotes to accept hyperlinks

    const temp = `Email Subject: ${subjectLine}\nEmail Text: ${bodyWithHT}`;

    console.log("body-text: ", bodyText);
    setEmailNote(temp);
  }, [bodyText, subjectLine]);

  const getSelectedTemplate = (id) => {
    const template = templates.filter((t) => {
      return t.id === parseInt(id);
    })[0];

    setBodyText(template.bodyText);
    setSubjectLine(template.subjectLine);
  };

  const getEmailTemplates = async () => {
    try {
      const programId = applicationList?.filter((x) => {
        return x.id === props.applicationId;
      })[0].programId;
      const res = await dispatch(emailTemplateByProgramId({ id: programId }));
      const sorted = res.payload.data.sort((a, b) => {
        return a.id - b.id;
      });
      setTemplates([...sorted]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEmailTemplates();
  }, []);

  const sendEmail = async () => {
    try {
      let bodyWithHT = bodyText.replace(/"/g, "");
      let emailNoteWithHT = emailNote.replace(/"/g, "");

      let sccBodyWithHT = convertWordsInBracketsToLowerCase(bodyWithHT); //scc = short code conversion format
      let sccSubjectLine = convertWordsInBracketsToLowerCase(subjectLine);
      let sccEmailNote = convertWordsInBracketsToLowerCase(emailNoteWithHT);

      //add conversion units
      let bodyWithHT_formatted = addVars(sccBodyWithHT);
      let subjetLine_formatted = addVars(sccSubjectLine);
      let emailNote_formatted = addVars(sccEmailNote);

      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/communication-history/add`
          : "http://localhost:3000/application/communication-history/add";

      let res = await axios.post(
        url,
        {
          applicationId: props.applicationId,
          userId: parseInt(localStorage.getItem("user_id")),
          value: emailNote_formatted,
          unlockNote: false,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const customerEmail = customers?.filter((x) => {
        return x.id === parseInt(props.customerId);
      })[0].email;

      // if (process.env.REACT_APP_PRODUCTION === "true") {
      console.log("CUSTOMER EMAIL: ", bodyWithHT_formatted);
      await axios.post(
        `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
        {
          subject: subjetLine_formatted,
          body: bodyWithHT_formatted,
          to: [`${customerEmail}`],
          fromName: "ACWD Water Savings Center",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // }

      resetEmailVars();
      props.getCommunicationLogs();
    } catch (err) {
      console.log(err);
    }
  };
  //add next_status
  const [nextStatus, setNextStatus] = useState(null);
  const [unlockDescription, setUnlockDescription] = useState(null);
  useEffect(() => {
    console.log(unlockDescription);
  }, [unlockDescription]);
  const unlockApplication = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/unlock`
          : `http://localhost:3000/application/unlock`;

      if (nextStatus && unlockDescription) {
        const res = await axios.post(
          url,
          {
            id: props.applicationId,
            unlocked: true,
            next_status: nextStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const app = res.data.data;

        saveNote(1);

        if (app) {
          const toastPayload = {
            index: 0,
            description: 5,
            type: "application",
          };
          setToast(toastPayload);
          props.getCommunicationLogs();
          setUnlockDescription("");
          setNextStatus(null);
          console.log("ID: ", props.customerId);
          const customerEmail = customers?.filter((x) => {
            return x.id === parseInt(props.customerId);
          })[0].email;

          const programId = applicationList?.filter((x) => {
            return x.id === props.applicationId;
          })[0].programId;
          console.log("EMAIL: ", localStorage.getItem("email"));
          if (process.env.REACT_APP_PRODUCTION === "true") {
            const res = await axios.post(
              `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
              {
                subject: `Application ${props.applicationId} (${
                  programs.filter((x) => {
                    return x.id === programId;
                  })[0].programName
                }) in the Water Savings Center needs your attention`,
                body: `Hello! Your Application ${props.applicationId} (${
                  programs.filter((x) => {
                    return x.id === programId;
                  })[0].programName
                }) has been unlocked and returned to you in the Water Savings Center with the following note from ACWD staff: ${unlockDescription} Please log into Water Savings Center and click on the row for Application ${
                  props.applicationId
                } in your My Applications list to update information and resubmit back for continued review`,
                to: [`${customerEmail}`],
                fromName: "ACWD Water Savings Center",
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
          }

          console.log("Sent email: ", res);
          closeAddNote();
        } else {
          const toastPayload = {
            index: 1,
            description: 6,
            type: "application",
          };
          setToast(toastPayload);
        }
      } else {
        if (!nextStatus) {
          const toastPayload = {
            index: 1,
            description: 3,
            type: "application",
          };
          setToast(toastPayload);
        }
        if (!unlockDescription) {
          const toastPayload = {
            index: 1,
            description: 4,
            type: "application",
          };
          setToast(toastPayload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col w-full p-6">
      {appModal && (
        <UnlockApplicationModal
          props={{
            closeAppModal: closeModal,
            unlockApplication: unlockApplication,
          }}
        />
      )}

      <div className="main-content flex flex-col gap-6">
        <div className="bg-gray-50 p-4 rounded shadow">
          <span className="tracking-wide font-semibold text-blue-reef">
            Unlock Application for Customer Follow-Up
          </span>
          <div className="flex gap-8 mt-4">
            <div className="bg-white w-8/12 h-[14.5rem]">
              <ReactQuill
                theme="snow"
                className="w-full rounded h-[12rem] "
                value={unlockDescription}
                onChange={setUnlockDescription}
              />
            </div>

            <div className="w-4/12 flex flex-col gap-2">
              <select
                name=""
                id=""
                className="border w-full h-[2.5rem] rounded"
                onChange={(e) => {
                  setNextStatus(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select Next Status
                </option>
                {sortedStatus &&
                  sortedStatus.map((status, index) => {
                    return (
                      <option value={status.statusId} key={index}>
                        {`${status.number} - ${status.name}`}
                      </option>
                    );
                  })}
              </select>
              <button
                className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                onClick={() => {
                  openModal();
                }}
              >
                Unlock Application and Notify Customer
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1 bg-gray-50 p-4 rounded shadow">
          <span className="tracking-wide mb-2 font-semibold text-blue-reef">
            Generate Customer Email
          </span>

          <div className="flex flex-col gap-1">
            <span>Select Template (optional):</span>
            <select
              className="rounded border-gray-300 border-2 p-2 w-6/12"
              onChange={(e) => {
                // setEmailTemplate();
                getSelectedTemplate(e.target.value);
              }}
            >
              <option selected>{""}</option>
              {templates?.map((t) => {
                return <option value={t.id}>{t.emailName}</option>;
              })}
            </select>
          </div>

          <div className="flex flex-col gap-1">
            <span>Email Subject Line</span>
            <input
              className="p-2 border w-6/12 border-gray-300 rounded"
              type="text"
              value={subjectLine}
              onChange={(e) => {
                setSubjectLine(e.target.value);
              }}
            />
          </div>

          <div className="flex items-center gap-8">
            <div className="flex flex-col gap-1 w-9/12">
              <span>Email Body Text</span>
              <div className="bg-white h-[12rem]">
                <ReactQuill
                  theme="snow"
                  className="w-full rounded text-gray-900 h-[9.5rem]"
                  value={bodyText}
                  onChange={setBodyText}
                />
              </div>
            </div>
            <div className="w-3/12">
              <button
                className="text-white bg-wc-green rounded py-2 px-4 hover:bg-blue-700"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  sendEmail();
                }}
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-2 bg-gray-50 p-6 rounded">
          <span className="font-semibold text-blue-reef mb-2">
            Customer Contact Tracking
          </span>

          <div className="flex flex-col w-full items-start gap-4">
            <div className="flex gap-2">
              {!addNote && (
                <button
                  className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                  onClick={() => {
                    openAddNote();
                  }}
                >
                  Add a New Note
                </button>
              )}
              {addNote && (
                <button
                  className="text-white  bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
                  onClick={() => {
                    closeAddNote();
                  }}
                >
                  Cancel
                </button>
              )}
              {addNote && (
                <button
                  className="text-white bg-green-reef hover:bg--700 focus:ring-4 focus:ring-green-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
                  onClick={() => {
                    saveNote(0);
                  }}
                >
                  Save
                </button>
              )}
            </div>

            {addNote && (
              <textarea
                className="w-full border border-gray-300 rounded p-2"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            )}
            <CustomerContactTable
              props={{
                communicationHistory: props.communicationHistory,
                setNote: setNote,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationHistory;
