import React from "react";
import Downshift from "downshift";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = ({ props }) => {
  return (
    <Downshift
      onChange={(e) => props.setter(e)}
      itemToString={(item) => (item ? item : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <div className="flex flex-col border border-gray-200 rounded">
          {/* <label {...getLabelProps()}>Enter a fruit</label> */}
          <div
            className="flex items-center p-1"
            {...getRootProps({}, { suppressRefError: true })}
          >
            <AiOutlineSearch size="20" className="text-gray-400" />
            <input
              className=" w-full rounded focus:outline-none"
              {...getInputProps()}
            />
          </div>
          <ul className="overflow-auto " {...getMenuProps()}>
            {isOpen
              ? props.options
                  ?.filter(
                    (item) =>
                      !inputValue ||
                      item.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .slice(0, 7)
                  .map((item, index) => (
                    <li
                      className="cursor-pointer pl-2"
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                        },
                      })}
                    >
                      {item}
                    </li>
                  ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

export default SearchBar;
