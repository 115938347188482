import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedCustomer } from "../../features/customer";
import InputMask from "react-input-mask";
import { updateCustomer } from "../../features/customer";
import { setToast } from "../../utils/ToastNotification";

const CustomerProfile = ({ props }) => {
  const dispatch = useDispatch();
  const customer = useSelector(getLoggedCustomer);

  const [editDisable, setEditDisable] = useState(true);
  const [userInfo, setUserInfo] = useState({
    id: customer.id,
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
    phone: localStorage.getItem("phone"),
    address: null,
    city: null,
    state: null,
    zip: null,
    company: null,
    title: null,
    deleted: false,
  });

  const submit = async () => {
    try {
      const res = await dispatch(updateCustomer(userInfo));
      console.log("updateUser: ", res);
      const toastPayload = {
        index: 0,
        description: 2,
        type: "customer",
      };

      res.payload && setToast(toastPayload);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col gap-8 items-start sm:w-full lg:w-6/12 rounded ">
      <div className="flex flex-col gap-4 font-sans w-full">
        <div className="sm:flex sm:flex-col lg:flex lg:flex-row gap-4 w-full">
          <div className="flex flex-col tracking-wide w-full">
            <label htmlFor="">First Name </label>
            <input
              type="text"
              defaultValue={userInfo?.firstName}
              disabled={editDisable}
              className={`border border-gray-300 bg-white rounded p-2 w-full ${
                editDisable && "text-gray-300"
              }`}
              onChange={(e) => {
                setUserInfo((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col tracking-wide w-full">
            <label htmlFor="">Last Name </label>
            <input
              type="text"
              defaultValue={userInfo?.lastName}
              className={`border border-gray-300 rounded p-2 w-full bg-white ${
                editDisable && "text-gray-300"
              }`}
              onChange={(e) => {
                setUserInfo((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="sm:flex sm:flex-col lg:flex lg:flex-row gap-4 w-full">
          <div className="flex flex-col w-full">
            <label htmlFor="">Email </label>
            <input
              type="text"
              defaultValue={userInfo?.email}
              className={`border border-gray-300 bg-white rounded p-2 full ${
                editDisable && "text-gray-300"
              }`}
              onChange={(e) => {
                setUserInfo((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col w-full">
            <label htmlFor="">Phone </label>
            <InputMask
              type="text"
              mask="(999) 999-9999"
              defaultValue={userInfo?.phone}
              className={`border border-gray-300 bg-white rounded p-2 ${
                editDisable && "text-gray-300"
              }`}
              onChange={(e) => {
                setUserInfo((prev) => ({
                  ...prev,
                  phone: e.target.value,
                }));
              }}
            />
          </div>
        </div>
      </div>

      {!editDisable ? (
        <div className="flex gap-1">
          <button
            className="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-blue-300 font-medium rounded px-5 py-2  dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500 w-fit"
            onClick={() => {
              setEditDisable(true);
            }}
          >
            Cancel
          </button>

          <button
            className="text-white bg-green-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500 w-fit"
            onClick={() => {
              submit();
              setEditDisable(!editDisable);
            }}
          >
            Save Contact Information
          </button>
        </div>
      ) : (
        <button
          className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500 w-fit"
          onClick={() => {
            setEditDisable(!editDisable);
          }}
        >
          Update Contact Information
        </button>
      )}
    </div>
  );
};

export default CustomerProfile;
