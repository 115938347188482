import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStatusList } from "../../features/status";
import Status from "./Status";
import { selectAllStatus } from "../../features/applicationStatus";
import { selectAllUsers } from "../../features/user";
import { getLoggedUser } from "../../features/user";

export const StatusBar = ({ props }) => {
  const userList = useSelector(selectAllUsers);
  const statusList = useSelector(selectStatusList);
  const applicationStatusList = useSelector(selectAllStatus);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentAppStatus, setCurrentAppStatus] = useState(null);
  const [sortedStatus, setSortedStatus] = useState(null);

  const [displayStatus, setDisplayStatus] = useState(null);
  const [appLength, setApplength] = useState(null);
  const loggedUser = useSelector(getLoggedUser);
  useEffect(() => {
    if (applicationStatus) {
      const sorted_status = props.statusList.slice().sort((a, b) => {
        return parseInt(a.statusId) - parseInt(b.statusId);
      });

      setSortedStatus(sorted_status);

      //extra status are being passed so index doesn't align with counter
      let counter = 0;
      const temp = [];
      for (let i = 0; i < sorted_status.length; i++) {
        if (
          sorted_status[i].milestone ||
          parseInt(sorted_status[i].statusId) ===
            parseInt(applicationStatus.statusId)
        ) {
          counter += 1;
          temp.push(sorted_status[i]);
        }
      }
      setApplength(counter);
      setDisplayStatus(temp);
    }
  }, [props.statusList, applicationStatus]);

  useEffect(() => {
    if (applicationStatusList && applicationStatusList.length > 0) {
      const temp = applicationStatusList.filter((status) => {
        return status.applicationId === props.appId;
      });

      if (temp.length > 0) {
        const statusId = temp[0].statusId;
        if (statusList.length > 0) {
          const statusInfo = statusList.filter((status) => {
            return parseInt(status.statusId) === statusId;
          });
          setCurrentAppStatus(statusInfo[0]);
        }
      }
    }
  }, [applicationStatusList, props.appId]);

  useEffect(() => {
    if (applicationStatusList) {
      const temp = applicationStatusList.filter((status) => {
        return status.applicationId === props.appId;
      });
      if (temp.length > 0) {
        const statusId = temp[0].statusId;
        const statusObj = statusList.filter((status) => {
          return (
            parseInt(status.statusId) === statusId &&
            status.programId === props.program.id
          );
        });
        setApplicationStatus(statusObj[0]);
      }
    }
  }, [applicationStatusList, props.program]);

  const setStatus = async (status) => {
    try {
      const statusObj = {
        id: applicationStatusList?.filter((status) => {
          return status.applicationId === props.appId;
        })[0].id,
        statusId: parseInt(status),
        applicationId: props.appId,
        adminRespond: false,
      };
      props.saveStatus(statusObj);
    } catch (err) {
      console.log(err);
    }
  };

  const [statusDescription, setStatusDescription] = useState("");
  return (
    <div className={`gap-8  ${props.format === "table" ? "" : "gap-4"}`}>
      {loggedUser && loggedUser.role === "ADMIN" && (
        <div className="p-4 flex flex-col">
          <span className="tracking-wide font-bold text-blue-reef">
            Workflow
          </span>
          <span>{`Application ID: ${props.appId}`}</span>
        </div>
      )}
      {applicationStatus && appLength && (
        <div className="flex gap-5 justify-center p-4">
          {displayStatus &&
            displayStatus.map((status, index) => {
              return (
                <div>
                  <Status
                    key={index}
                    index={index}
                    currentStep={currentStep}
                    className="text-black"
                    props={status}
                    customer={props.customer}
                    statusLength={appLength}
                    status={applicationStatus}
                  />
                </div>
                // )
              );
            })}
        </div>
      )}
      {/* <div className="flex gap-2 text-white text-sm tracking-wide">
        <button
          className="px-4 py-2 rounded-md bg-blue-reef"
          onClick={() => {
            updateStep(1);
          }}
        >
          MOVE TO STEP 4
        </button>
        <button
          className="px-4 py-2 rounded-md bg-red-400"
          onClick={() => {
            updateStep(1);
          }}
        >
          SEND BACK TO CUSTOMER
        </button>
        <button
          className="px-4 py-2 rounded-md bg-wc-grey"
          onClick={() => {
            updateStep(1);
          }}
        >
          CANCEL
        </button>
      </div> */}
      {props.customer === false && currentAppStatus && (
        <div className="flex flex-col justify-center gap-4 p-4">
          {currentAppStatus && applicationStatus && (
            <div className="flex gap-2">
              <label htmlFor="">Current Status Description: </label>
              <span>{applicationStatus.description}</span>
            </div>
          )}
          {/* {props.statusList.length > 0 ? ( */}
          <div className="flex gap-2 items-center">
            <label htmlFor="">Change Status to: </label>
            <select
              name=""
              id=""
              className="border w-[20rem] h-[2.5rem] rounded"
              onChange={(e) => {
                console.log("STATUS VALUES: ", e.target.value);
                const currentName = e.target.value.split("/")[1];
                console.log("currentName: ", currentName);
                const currentDescription = e.target.value.split("/")[2];
                console.log("currentDescription: ", currentDescription);
                const currentNumber = e.target.value.split("/")[3];
                console.log("currentNumber: ", currentNumber);
                props.addStatusNumber(currentNumber);
                setStatusDescription(currentDescription);
                props.addStatusName(currentName);
                setStatus(e.target.value.split("-")[0]);
              }}
            >
              <option value="" disabled selected>
                Select Next Status
              </option>
              {sortedStatus &&
                sortedStatus.map((status, index) => {
                  return (
                    <option
                      value={`${status.statusId}/${status.name}/${status.description}/${status.number}`}
                      key={index}
                    >
                      {`${status.number} - ${status.name}`}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="flex gap-2">
            <span>New Status Description:</span>
            <span>{statusDescription}</span>
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="">Change Staff Assignment To: </label>
            <select
              name=""
              id=""
              className="border w-[20rem] h-[2.5rem] rounded"
              onChange={(e) => {
                props.addAsignee(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Select Staff Name
              </option>
              {userList &&
                userList.map((user, index) => {
                  if (!user.deleted) {
                    return (
                      <option value={user.id} key={index}>
                        {`${user.firstName} ${user.lastName}`}
                      </option>
                    );
                  }
                })}
            </select>
          </div>

          {/* {props.statusList.length === 0 && <span>hello</span>} */}
        </div>
      )}
    </div>
  );
};

export default StatusBar;
