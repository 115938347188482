import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  lookupCharacteristics: [],
  status: "idle",
  error: null,
};

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/lookup-characteristic";
const local_base_url = "http://localhost:3000/lookup-characteristic";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchLookupCharacteristics = createAsyncThunk(
  "lookup-characteristic/fetch-all",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const lc = res.data.data;
      return lc;
    } catch (err) {
      console.log(err);
    }
  }
);

export const lookupCharacteristicById = createAsyncThunk(
  "lookup-characteristic/fetch-by-id",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-by-id`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const lc = res.data.data;
      return lc;
    } catch (err) {
      console.log(err);
    }
  }
);

export const createLookupCharacteristic = createAsyncThunk(
  "lookup-characteristic/create",
  async (lookupCharacteristic) => {
    try {
      const res = await axios.post(
        `${active_base_url}/create`,
        lookupCharacteristic,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const lc = res.data.data;
      return lc;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateLookupCharacteristic = createAsyncThunk(
  "lookup-characteristic/update",
  async (lookupCharacteristic) => {
    try {
      const res = await axios.post(
        `${active_base_url}/update`,
        lookupCharacteristic,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const lc = res.data.data;
      return lc;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteLookupCharacteristic = createAsyncThunk(
  "lookup-characteristic/delete",
  async (id) => {
    try {
      const res = await axios.post(`${active_base_url}/delete`, id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const lc = res.data.data;
      return lc;
    } catch (err) {
      console.log(err);
    }
  }
);

export const lookupCharacteristicSlice = createSlice({
  name: "lookupCharacteristic",
  initialState: { value: initStateValue },
  reducers: {
    clearLookupCharacteristics: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLookupCharacteristics.pending, (state, action) => {
        state.value.status = "loading";
        state.value.lookupCharacteristics = action.payload;
      })
      .addCase(fetchLookupCharacteristics.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.lookupCharacteristics = action.payload;
      })
      .addCase(fetchLookupCharacteristics.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllLookupCharacteristics = (state) =>
  state.lookupCharacteristic.value.fields;
export const getLookupCharacteristicStatus = (state) =>
  state.lookupCharacteristic.value.status;
export const { clearLookupCharacteristics } = lookupCharacteristicSlice.actions;
export default lookupCharacteristicSlice.reducer;
