import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";





const ApplicationTableFilterModal = ({props}) => {

    console.log("DATA: ", props.data)
    console.log("FILTER: ", props.filter)

    const [modalValues, setModalValues] = useState([]);

    const getModalData = () => {
        let tmpVal = [... new Set(props.data.map(x=>x[props.filter]))];
        console.log("Filtered values: ", tmpVal);

        // set the stuff that is currently being filtered      
        return tmpVal;  
    }

    const submitModalValues = () => {
        let tmpObj = {};
        tmpObj[props.filter] = modalValues
        props.setFilter(((prev)=>({...prev, ...tmpObj})));
        props.closeModal();
    }

    const displayFilters = getModalData().map(x=>{
        return (
            <tr>
                <td className="border-2 border-gray-200 p-2">
                    {x ? <span>{x}</span> : <span className="italic">Null / Empty</span>}
                </td>
                <td className="border-2 border-gray-200 p-2 text-center">
                    <input 
                        type={"checkbox"}
                        value={x}
                        onChange={(e)=>{
                            if (e.target.checked){
                                setModalValues([...modalValues, x])
                            }
                            else {
                                setModalValues(modalValues.filter(y=>y!=x));
                            }
                            console.log("Modal Values: ", modalValues)
                        }}
                    />
                </td>
            </tr>
        );
    });

    useEffect(()=>{
        getModalData();
    },[])


    return (
        <Backdrop
          onClick={() => {
            props.closeModal();
          }}
        >
          <motion.div
            className=" rounded-md w-1/2 h-auto bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans relative "
            onClick={(e) => e.stopPropagation()}
            variants={null}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
          >
            <div className="w-full p-12 flex flex-col gap-4 line-clamp-7 min-w-12">
              <div className="flex flex-row w-max text-center m-auto p-auto items-center justify-center absolute top-0 pt-4">
                <h2 className="flex flex-col w-full font-bold text-center w-full text-wc-blue rounded-t tracking-wider text-lg">
                  Filter Options
                </h2>
              </div>
              <div className="flex flex-col items-start gap-1 mt-8">
                <table className="border-solid border-1 border-black-100 w-full">
                    <thead>
                        <tr>
                            <th className="border-2 border-gray-200 p-2 ">
                                Name
                            </th>
                            <th className="border-2 border-gray-200 p-2">
                                Visible
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayFilters}
                    </tbody>
                </table>
              </div>
          
              <div className="flex flex-row justify-center">

                <motion.button
                    // whileHover={{ scale: 1.1 }}
                    // whileTap={{ scale: 0.9 }}
                    className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
                    onClick={() => {
                        props.closeModal();
                    }}
                >
                    Close
                </motion.button>
                <motion.button
                    // whileHover={{ scale: 1.1 }}
                    // whileTap={{ scale: 0.9 }}
                    className="text-white bg-blue-reef hover:bg--700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                    onClick={() => {
                        console.log("Submitted Modal Values:", modalValues);
                        submitModalValues();
                    }}
                >
                    Filter
                </motion.button>
              </div>
              
            </div>
          </motion.div>
        </Backdrop>
    );
}

export default ApplicationTableFilterModal;