import React, { useEffect, useState } from "react";
import { selectAllCustomers } from "../../features/customer";
import { selectAllProperties } from "../../features/properties";
import { selectAllValues } from "../../features/applicationValue";
import { selectAllPrograms } from "../../features/program";
import { useSelector } from "react-redux";
import { selectStatusList } from "../../features/status";
import { selectAllApplications } from "../../features/application";
import { selectAllStatus } from "../../features/applicationStatus";
import StatusBar from "./StatusBar";
import Moment from "react-moment";
import { GoAlert } from "react-icons/go";
import { formatFullAddress } from "../../utils/formatAddress";
import moment from "moment";

const CustomerApplication = ({ props }) => {
  const customerList = useSelector(selectAllCustomers); //Redux Select
  const properties = useSelector(selectAllProperties); //Statements
  const programs = useSelector(selectAllPrograms);
  const statusList = useSelector(selectStatusList);
  const values = useSelector(selectAllValues);
  const applications = useSelector(selectAllApplications);
  const appStatusList = useSelector(selectAllStatus);

  const [propertyList, setPropertyList] = useState(null);

  const [appData, setAppData] = useState({
    customer: null,
    setCustomer: (payload) => {
      setAppData((prev) => ({
        ...prev,
        customer: payload,
      }));
    },
    appValues: null,
    setAppValues: (values) => {
      setAppData((prev) => ({
        ...prev,
        appValues: values,
      }));
    },
    property: null,
    setProperty: (property) => {
      setAppData((prev) => ({
        ...prev,
        property: property,
      }));
    },
    program: null,
    setProgram: (program) => {
      setAppData((prev) => ({
        ...prev,
        program: program,
      }));
    },
    status: null,
    setStatusList: (status) => {
      setAppData((prev) => ({
        ...prev,
        status: status,
      }));
    },
    applicationStatus: null,
    setApplicationStatus: (status) => {
      setAppData((prev) => ({
        ...prev,
        applicationStatus: status,
      }));
    },
  });

  useEffect(() => {
    if (customerList && customerList.length > 0) {
      const temp = customerList.filter((customer) => {
        return customer.id === props.app.customerId;
      });

      appData.setCustomer(temp[0]);
    }
  }, [customerList]);

  useEffect(() => {
    if (applications && appStatusList && applications.length > 0) {
      const currentStatus = appStatusList.filter((status) => {
        return status.applicationId === props.app.id;
      });
      if (currentStatus.length > 0) {
        const currentStatusId = currentStatus[0].statusId;
        const application = applications.filter((app) => {
          return app.id === props.app.id;
        });
        // console.log("asdf", application[0].programId);
        if (programs && programs.length > 0) {
          const program = programs.filter((program) => {
            return program.id === application[0].programId;
          });
          if (statusList && statusList.length > 0) {
            const status = statusList.filter((status) => {
              return status.programId === program[0].id;
            });

            //configure customer status'
            if (status.length > 0) {
              const milestones = status.filter((stat) => {
                return (
                  stat.milestone === true ||
                  parseInt(stat.statusId) === currentStatusId
                );
              });

              const finalStatusList = [];
              const finalStatusSet = new Set();

              for (let i = 0; i < milestones.length; i++) {
                if (
                  !finalStatusSet.has(parseInt(milestones[i].customerNumber))
                ) {
                  finalStatusSet.add(parseInt(milestones[i].customerNumber));
                  finalStatusList.push(milestones[i]);
                } else {
                  if (parseInt(milestones[i].statusId) === currentStatusId) {
                    const temp = finalStatusList.pop();

                    finalStatusList.push(milestones[i]);
                  }
                }
              }

              appData.setStatusList(finalStatusList);
            }
          }
        }
      }
    }
  }, [statusList, applications, programs, props.application, appStatusList]);

  useEffect(() => {
    if (values && values.length > 0) {
      const temp = values.filter((value) => {
        return value.applicationId === props.app.id;
      });

      appData.setAppValues(temp[0]);
    }
  }, [values]);

  useEffect(() => {
    if (properties && properties.length > 0) {
      const temp = properties.filter((property) => {
        return property.id === props.app.propertyId;
      });

      setPropertyList(
        formatFullAddress([
          temp[0]?.mfcStreetNo,
          temp[0]?.streetPfxDir,
          temp[0]?.mfcStreetName,
          temp[0]?.streetNmSfx,
          temp[0]?.streetSfxDir,
          temp[0]?.secAddrId,
          temp[0]?.secAddrRange,
          temp[0]?.city,
          temp[0]?.provinceCd,
          temp[0]?.postalCode,
        ])
      );
    }
  }, [properties]);

  useEffect(() => {
    const programId = props.app.programId;
    if (programs && programs.length > 0) {
      const appProgram = programs.filter((program) => {
        return program.id === programId;
      });
      appData.setProgram(appProgram[0]);
    }
  }, [values]);

  return (
    <tr
      className={` dark:text-white text-black text-start hover:bg-blue-50 cursor-pointer border-b border-gray-200`}
      onClick={() => {
        props.app.unlocked === true
          ? props.openUnlockModal({
              programId: appData.program.id,
              appId: props.app.id,
            })
          : props.appModalController.modalController.openAppModal({
              programId: appData.program.id,
              appId: props.app.id,
            });
      }}
    >
      <td className="p-3 text-sm w-1/12">
        <div className="flex gap-1 items-center ">
          {props.app.unlocked === true && (
            <span className="text-yellow-400">
              <GoAlert size="20" />
            </span>
          )}
          <a className="text-blue-500 cursor-pointer">{props.app.id}</a>
        </div>
      </td>
      {props.app.createdAt && !props.app.unlocked ? ( //customer table date need to update date on resubmit
        <td className="p-3 text-sm w-1/12">
          {/* <Moment format="MM/DD/YYYY">
            {moment.utc(props.app.createdAt).format("MM/DD/YYYY")}
          </Moment> */}
          <Moment format="MM/DD/YYYY">{props.app.createdAt}</Moment>
        </td>
      ) : (
        <td className="p-3 text-sm w-1/12">{""}</td>
      )}

      <td className="p-3 text-sm w-1/12">
        {propertyList ? propertyList : <span>loading propery address</span>}
      </td>

      <td className="p-3 text-sm w-1/12">
        {appData.customer
          ? appData?.customer?.firstName + " " + appData?.customer?.lastName
          : "Admin"}
      </td>
      {appData.program && (
        <td className="p-3 text-sm w-1/12">{appData.program.programName}</td>
      )}
      <td className="flex justify-start w-6/12">
        {appData.status ? (
          <StatusBar
            props={{
              statusList: appData.status,
              customer: true,
              appId: props.app.id,
              type: "CUSTOMER",
              program: appData.program,
            }}
          />
        ) : (
          "No Status Available"
        )}
      </td>
    </tr>
  );
};

export default CustomerApplication;
