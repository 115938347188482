import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPropertiesPublic } from "../features/properties";
import { fetchProgramsPublic } from "../features/program";
import defaultPicture from "../assets/Pictures-and-organization-logo-default-program-photo.jpg";

import { motion } from "framer-motion";
import Backdrop from "../components/Misc/Backdrop";
import { setToast } from "../utils/ToastNotification";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const CurrentPrograms = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [applicationModal, setApplicationModal] = useState(false);
  const [displayPrograms, setDisplayPrograms] = useState(null);
  const [displayPictures, setDisplayPictures] = useState(null);

  const getPrograms = async () => {
    try {
      const res = await dispatch(fetchProgramsPublic());
      return res;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPrograms().then(async (res) => {
      const validPrograms = res.payload.filter((program) => {
        const startDate = new Date(program.programStart);
        const endDate = new Date(program.programEnd);
        const currentDate = new Date();
        return currentDate >= startDate && currentDate <= endDate;
      });

      await setDisplayPrograms(validPrograms);
      await getDisplayPictures(validPrograms);
    });
  }, []);

  const getDisplayPictures = async (input) => {
    let tmp = await Promise.all(
      input.map(async (x) => {
        return await getPhoto(x.id); //programId
      })
    );
    let tmpArr = [];
    tmp.map((x) => {
      if (x) if (x.id) tmpArr[x.referenceId] = x.fileName; //referenceId = programId
    });

    let dataArr = await Promise.all(
      tmpArr.map(async (x) => {
        if (x) return await downloadFile(x);
      })
    );
    let photoArr = dataArr.map((x) => {
      if (x) return createBlobUrl(x);
    });
    setDisplayPictures(photoArr);
  };

  const getPhoto = async (input) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/fetch-by-type`
          : `http://localhost:3000/reference/fetch-by-type`;

      const res = await axios.post(
        url,
        {
          referenceId: input,
          referenceType: "program_picture",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      return res.data?.data?.[0]; // info on link for photo
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const createBlobUrl = (byteStream) => {
    if (!byteStream) return null;
    const blob = new Blob([byteStream], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  };

  const downloadFile = async (input) => {
    let tmpObj = {
      fileName: input,
    };
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/download`
          : `http://localhost:3000/blobstorage/download`;

      const res = await axios.post(url, tmpObj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "arraybuffer",
      });

      return res.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <div className="w-full">
        <div className="flex flex-col w-full gap-8 justify-center items-center ">
          <div className="w-full rounded-md">
            <h2 className="w-full font-sans p-2 font-medium text-xl text-gray-900 mb-4">
              Start Application
            </h2>

            <div className="">
              <div className="flex flex-wrap w-full">
                {displayPrograms && displayPictures ? (
                  displayPrograms.map((program, index) => {
                    return (
                      <motion.button
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => {
                          //   openApplicationModal(program);
                        }}
                        key={index}
                        className={`rounded sm:w-full lg:w-[45%] dark:bg-wc-grey flex flex-col bg-white shadow-md tracking-wider m-3`}
                      >
                        <div className="w-full">
                          <div className="bg-white rounded overflow-hidden">
                            <img
                              src={
                                displayPictures?.[program?.id]
                                  ? displayPictures?.[program?.id]
                                  : defaultPicture
                              }
                              alt={program.programName}
                              className="w-full object-cover object-center"
                              style={{ height: "15rem" }}
                            />
                            <div className="p-4">
                              <h5 className="text-gray-900 text-lg font-medium mb-2">
                                {program.programName}
                              </h5>
                              {/* Additional information can go here */}
                            </div>
                          </div>
                        </div>
                      </motion.button>
                    );
                  })
                ) : (
                  <div className="w-full flex justify-center items-center gap-2">
                    <span>Loading Application List</span>
                    {/* <HashLoader size={40} color={"#A3AEB9"} /> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default CurrentPrograms;
