import React from "react";
import { selectAllProperties } from "../../../features/properties";
import { useSelector } from "react-redux";
import PropertyTable from "../../Properties/PropertyTable";

const PropertiesContainer = ({ props }) => {
  const propertyList = useSelector(selectAllProperties);
  return (
    <div
      className={`h-full bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 p-8 ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content overflow-auto">
        {propertyList && <PropertyTable props={propertyList} />}
      </div>
    </div>
  );
};

export default PropertiesContainer;
