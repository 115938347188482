import React from "react";
import Backdrop from "../Misc/Backdrop";
import { motion } from "framer-motion";
import { selectCustomer } from "../../features/customer";
import { useDispatch, useSelector } from "react-redux";

const UpdateCustomerModal = ({ handleClose }) => {
  const customer = useSelector(selectCustomer);
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="flex flex-col w-4/12 gap-8 "
        onClick={(e) => e.stopPropagation()}
        // variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        {customer && (
          <div className="w-full border border-gray-100 rounded-md shadow-xl bg-white">
            <form className="contact-form flex flex-col p-5 gap-4 bg-white">
              <span className="text-lg text-blue-reef font-semibold tracking-wide ">
                UPDATE CONTACT INFO
              </span>
              <div className="flex gap-12 ">
                <div className="flex flex-col w-full ">
                  <label htmlFor="">
                    FIRST NAME <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none "
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     firstName: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="">
                    LAST NAME <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    value={customer.lastName}
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     lastName: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-6/12 pr-[1.5rem]">
                <label htmlFor="">
                  TELEPHONE <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                  placeholder="(444)444-4444"
                  // onChange={(e) => {
                  //   setContact((prev) => ({
                  //     ...prev,
                  //     phone: e.target.value,
                  //   }));
                  // }}
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="">
                  ADDRESS <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white "
                  // onChange={(e) => {
                  //   setContact((prev) => ({
                  //     ...prev,
                  //     address: e.target.value,
                  //   }));
                  // }}
                />
              </div>
              <div className="flex gap-8">
                <div className="flex flex-col w-full basis-6/12">
                  <label htmlFor="">
                    CITY<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     city: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">
                    STATE <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     state: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
                <div className="flex flex-col basis-4/12">
                  <label htmlFor="">
                    ZIP<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     zip: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
              </div>
              <div className="flex gap-12 ">
                <div className="flex flex-col w-full ">
                  <label htmlFor="">
                    COMPANY <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     company: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="">
                    TITLE <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     title: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <span className="mb-3 text-blue-500">
                  CHOOSE A USERNAME & PASSWORD
                </span>
                <label htmlFor="">
                  EMAIL ADDRESS (USERNAME){" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white "
                  // onChange={(e) => {
                  //   setContact((prev) => ({
                  //     ...prev,
                  //     email: e.target.value,
                  //   }));
                  // }}
                />
              </div>

              <div className="flex gap-12 ">
                <div className="flex flex-col w-full ">
                  <label htmlFor="">
                    PASSWORD <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     password: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="">
                    CONFIRM PASSWORD <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    className="border border-gray-200  rounded-sm shadow-sm focus:outline-none bg-white"
                    // onChange={(e) => {
                    //   setContact((prev) => ({
                    //     ...prev,
                    //     confirmPassword: e.target.value,
                    //   }));
                    // }}
                  />
                </div>
              </div>
              <span className="tracking-wide text-gray-400">
                Passwords are required to be a minimum of 6 characters in
                length.
              </span>
              <div className="flex gap-4 justify-center ">
                <button
                  className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                    //props.closeShowRegister();
                  }}
                >
                  CANCEL
                </button>
                {/* <button
          className="rounded-md bg-blue-400 p-4 text-white hover:bg-blue-600 tracking-wide"
          onClick={() => {
            props.openAccount();
          }}
        >
          Add property
        </button> */}
                <button
                  className="text-white bg-green-reef hover:bg--700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                    // TODO: add update functionality here
                  }}
                >
                  UPDATE
                </button>
              </div>
            </form>
          </div>
        )}
      </motion.div>
    </Backdrop>
  );
};

export default UpdateCustomerModal;
