import React from "react";

import Profile from "../../User/ProfileSettings";

const ProfileContainer = ({ props }) => {
  return (
    <div
      className={`h-full bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 p-8 ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content overflow-auto">
        <Profile />
      </div>
    </div>
  );
};

export default ProfileContainer;
