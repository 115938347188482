import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Application from "./ApplicationFixed";
import AppModal from "./UpdateApplicationModal";
import ImportReport from "./ImportReport";
import ApplicationTableFilterModal from "./ApplicationTableFilterModal";
import { formatFullAddress, formatAddress } from "../../utils/formatAddress";

import { selectAllProperties } from "../../features/properties";
import { selectAllAppProperties } from "../../features/applicationProperties";

import { selectAllValues } from "../../features/applicationValue";
import { selectAllPrograms } from "../../features/program";
import { selectAllFields } from "../../features/programField";
import { selectAllCustomers } from "../../features/customer";
import { selectStatusList } from "../../features/status";
import { selectStatusLogs } from "../../features/status";
import { selectAllUsers } from "../../features/user";

import { selectAllApplications } from "../../features/application";
import { useDispatch, useSelector } from "react-redux";
import WaterSavingsLog from "./WaterSavingsLog";
import CloseWarningModal from "../Misc/CloseWarningModal";
import { selectAllStatus } from "../../features/applicationStatus";
import { getLoggedUser } from "../../features/user";
import ExportModal from "./ExportModal";
import Paginate from "../Misc/Paginate";

import * as XLSX from "xlsx";

import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

export const ApplicationTable = ({ props }) => {
  const properties = useSelector(selectAllProperties);
  const appValues = useSelector(selectAllValues);
  const fieldList = useSelector(selectAllFields);

  const getAccount = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
          : `http://localhost:3000/account/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: 40003185 },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedBudget = res.data.data;
      console.log(updatedBudget);
    } catch (err) {
      console.log(err);
    }
  };

  const [reviewNotes, setReviewNotes] = useState(-1);
  const getReviewNotes = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/review-note/fetch-all`
          : `http://localhost:3000/application/review-note/fetch-all`;
      const res = await axios.post(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const review_notes = res.data.data;
      review_notes.sort((a, b) => {
        return b.id - a.id;
      });

      setReviewNotes(review_notes);
    } catch (err) {
      console.log(err);
      setReviewNotes([]);
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  useEffect(() => {
    getReviewNotes();
  }, []);

  //application modal
  const [modalController, setModalController] = useState(
    {
      appModal: false,
      modalCustomerId: null,
      modalAppId: null,
      openAppModal: (payload) => {
        console.log("open", payload);
        setModalController((prev) => ({
          ...prev,
          appModal: true,
          modalCustomerId: payload.customerId,
          modalAppId: payload.appId,
        }));
      },
      closeAppModal: () => {
        setModalController((prev) => ({
          ...prev,
          appModal: false,
        }));
      },
      closeWarning: false,
      openWarningModal: () => {
        setModalController((prev) => ({
          ...prev,
          closeWarning: true,
        }));
      },
      closeWarningModal: () => {
        setModalController((prev) => ({
          ...prev,
          closeWarning: false,
        }));
      },
    },
    []
  );

  const [appData, setAppData] = useState(
    {
      customerList: useSelector(selectAllCustomers),
      customer: null,
      setCustomer: (customer) => {
        setAppData((prev) => ({
          ...prev,
          customer: customer,
        }));
      },
      values: useSelector(selectAllValues),
      appValues: null,
      setAppValues: (values) => {
        setAppData((prev) => ({
          ...prev,
          appValues: values,
        }));
      },
      properties: useSelector(selectAllProperties),
      property: null,
      setProperty: (property) => {
        setAppData((prev) => ({
          ...prev,
          property: property,
        }));
      },
      programs: useSelector(selectAllPrograms),
      program: null,
      setProgram: (program) => {
        setAppData((prev) => ({
          ...prev,
          program: program,
        }));
      },
      fields: useSelector(selectAllFields),
      appStatus: null,
      setAppStatus: (status) => {
        setAppData((prev) => ({
          ...prev,
          appStatus: status,
        }));
      },
      statusList: useSelector(selectStatusList),
      allStatus: useSelector(selectAllStatus),
      statusObj: null,
      statusLogs: useSelector(selectStatusLogs),
      users: useSelector(selectAllUsers),
      setStatus: (status) => {
        setAppData((prev) => ({
          ...prev,
          statusObj: status,
        }));
      },
      applications: useSelector(selectAllApplications),
    },
    []
  );

  const [updateState, setUpdateState] = useState(0);
  const update = () => {
    setUpdateState(updateState + 1);
  };

  // filtering
  const [filterType, setFilterType] = useState("");
  const [filterText, setFilterText] = useState("");

  const [filterOptions, setFilterOptions] = useState([]);

  const [sortOptions, setSortOptions] = useState({});

  const [showFilterModal, setShowFilterModal] = useState({});

  const loggedUser = useSelector(getLoggedUser);

  // TODO: grab current user's name instead of
  const showMyAssignments = () => {
    let input = loggedUser.firstName + " " + loggedUser.lastName;
    setFilterText("");
    setFilterType("");
    setFilterOptions({ assignedTo: [input] });
    setSortOptions({});
    console.log("input for loggeduser", input);
  };

  //pagination
  const [targetApplications, setTargetApplications] = useState([]);

  // generates the array that we use to fill the table. handles filtering in here as well.
  const configureTargetApps = () => {
    // create the initial array of data
    let tmpArray = [];
    props.applications?.map((x) => {
      let tmpProp = properties?.filter((y) => y?.id === x.propertyId)[0];
      let tmpCustomer = appData?.customerList?.filter(
        (y) => y?.id === x.customerId
      )[0];
      let tmpProgram = appData?.programs?.filter(
        (y) => y?.id === x.programId
      )[0];

      //all status is application status table
      let tmpStatusId = appData?.allStatus?.filter(
        //current status id of application
        (y) => y?.applicationId === x?.id
      )[0]?.statusId;

      let tmpStatus = appData?.statusList?.filter(
        (y) =>
          parseInt(y?.statusId) === tmpStatusId && x?.programId === y?.programId //fixed filter ****
      )[0];
      let statusLogs = appData?.statusLogs;
      let newStatusLogs = statusLogs?.filter((x) => {
        let res = 1;
        for (let y of statusLogs) {
          if (x?.id !== y?.id && x?.applicationId === y?.applicationId) {
            if (new Date(x.createdAt) < new Date(y.createdAt)) res = 0;
            else res = res || 0;
          }
        }
        return res;
      });

      let tmpStatusLog = newStatusLogs
        ?.filter((y) => y?.applicationId === x?.id)
        .slice(-1)[0];
      let tmpUser = tmpStatusLog?.userId
        ? appData?.users?.filter(
            (y) => y?.id === tmpStatusLog.staffAssignmentId
          )[0]
        : "";
      let tmpRow = {
        id: x?.id || "",
        customerId: tmpCustomer?.id || "",
        propertyId: tmpProp,
        cost: x?.cost,
        waterSavings: x?.waterSavings,
        date: x.createdAt || "",
        address: tmpProp?.location
          ? formatFullAddress([
              tmpProp?.location?.houseNo,
              tmpProp?.location?.streetPfxDir,
              tmpProp?.location?.streetNm,
              tmpProp?.location?.streetNmSfx,
              tmpProp?.location?.streeSfxDir,
              tmpProp?.location?.secAddrId,
              tmpProp?.location?.secAddrRange,
              tmpProp?.location?.city,
              tmpProp?.location?.provinceCd,
              tmpProp?.location?.postalCode,
            ])
          : formatFullAddress([
              tmpProp?.mfcStreetNo,
              tmpProp?.streetPfxDir,
              tmpProp?.mfcStreetName,
              tmpProp?.streetNmSfx,
              tmpProp?.streetSfxDir,
              tmpProp?.secAddrId,
              tmpProp?.secAddrRange,
              tmpProp?.city,
              tmpProp?.provinceCd,
              tmpProp?.postalCode,
            ]),
        name: tmpCustomer
          ? tmpCustomer?.firstName + " " + tmpCustomer?.lastName
          : "",
        programName: tmpProgram?.programName.trim() || "",
        statusObj: tmpStatus,
        status: tmpStatus?.number.trim() || "",
        currentStatus: tmpStatus?.name.trim() || "",
        statusDate: tmpStatusLog?.createdAt || "",
        assignedTo: tmpUser
          ? (tmpUser?.firstName + " " + tmpUser?.lastName).trim()
          : "",
      };

      tmpArray.push(tmpRow);
    });

    // perform the filtering / sorting with the Search By dropdown / input
    if (filterText && filterType) {
      if (filterType == "Name") {
        tmpArray = tmpArray?.filter((x) =>
          x.name?.toLowerCase().includes(filterText.toLowerCase())
        );
      }
      if (filterType == "Program") {
        tmpArray = tmpArray?.filter((x) =>
          x.programName?.toLowerCase().includes(filterText.toLowerCase())
        );
      }
      if (filterType == "Property") {
        tmpArray = tmpArray?.filter((x) =>
          x.address?.toLowerCase().includes(filterText.toLowerCase())
        );
      }
    }

    // perform the filtering based off the modal selections
    if (Object.keys(filterOptions).length) {
      let cols = Object.keys(filterOptions);
      cols.map((col) => {
        console.log(col, filterOptions[col]);
        tmpArray = tmpArray?.filter((x) =>
          filterOptions[col].includes(x[col]) ? 1 : 0
        );
      });
    }

    // perform the sorting based off the modal selections
    if (sortOptions.column) {
      if (sortOptions.sort == "asc")
        tmpArray = tmpArray.sort((a, b) => {
          return a[sortOptions.column] > b[sortOptions.column]
            ? 1
            : a[sortOptions.column] == b[sortOptions.column]
            ? 0
            : -1;
        });
      else
        tmpArray = tmpArray.sort((a, b) => {
          return a[sortOptions.column] < b[sortOptions.column]
            ? 1
            : a[sortOptions.column] == b[sortOptions.column]
            ? 0
            : -1;
        });
    } else if (!sortOptions.column) {
      tmpArray.sort((a, b) => {
        return b.id - a.id;
      });
    }
    console.log("Sorted Array: ", tmpArray);

    setTargetApplications(tmpArray);
  };

  // run the filtering with no actual filtering

  useEffect(() => {
    configureTargetApps();
  }, [props.applications, properties]);

  // run the filtering on every change to filter text

  useEffect(() => {
    configureTargetApps();
  }, [filterText, filterType, filterOptions, sortOptions]);

  // const applicationsPerPage = 18;
  // const pagesVisited = pageNumber * applicationsPerPage;

  const genSortButtons = (input) => {
    console.log("GEN sort: ", input);
    return (
      <>
        {sortOptions.column != input && (
          <TiArrowUnsorted
            onClick={(e) => {
              setSortOptions({ column: input, sort: "desc" });
            }}
            className="flex flex-col ml-auto cursor-pointer"
          />
        )}
        {sortOptions.column == input && sortOptions.sort == "asc" && (
          <TiArrowSortedUp
            onClick={(e) => {
              setSortOptions({ column: input, sort: "desc" });
            }}
            className="flex flex-col ml-auto fill-red-400 cursor-pointer"
          />
        )}
        {sortOptions.column == input && sortOptions.sort != "asc" && (
          <TiArrowSortedDown
            onClick={(e) => {
              setSortOptions({ column: input, sort: "asc" });
            }}
            className="flex flex-col ml-auto fill-red-400 cursor-pointer"
          />
        )}
      </>
    );
  };

  // potentially need to throw this into a useEffect
  const [currentPage, setCurrentPage] = useState(1);
  // const applicationsPerPage = 500;
  // const pagesVisited = pageNumber * applicationsPerPage;
  const lastPostIndex = currentPage * 50;
  const firstPostIndex = lastPostIndex - 50;
  const displayProperties = targetApplications
    .slice(firstPostIndex, lastPostIndex)
    .map((app, index) => {
      return app.status !== "0" ? (
        <Application
          props={{
            app: app,
            index: index,
            modalController: modalController,
            properties: properties,
            updateState: updateState,
            view: props.view,
          }}
          index={index}
          key={index}
        />
      ) : null;
    });

  const [waterSavingsController, setWaterSavingsController] = useState({
    waterSavingsModal: false,
    openWaterSavingsModal: () => {
      setWaterSavingsController((prev) => ({
        ...prev,
        waterSavingsModal: true,
      }));
    },
    closeWaterSavingsModal: () => {
      setWaterSavingsController((prev) => ({
        ...prev,
        waterSavingsModal: false,
      }));
    },
  });

  //import report
  const [importModal, setImportModal] = useState(false);
  const [report, setReport] = useState([]);
  const openImportModal = (payload) => {
    setReport(payload);
    setImportModal(true);
  };
  const closeImportModal = () => {
    setImportModal(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    console.log("start date from application table: ", startDate);
  }, [startDate]);
  useEffect(() => {
    console.log("end date from application table: ", endDate);
  }, [endDate]);

  const [exportModal, setExportModal] = useState(false);
  const openExportModal = () => {
    setExportModal(true);
  };
  const closeExportModal = () => {
    setExportModal(false);
    setStartDate(null);
    setEndDate(null);
  };

  const applyDateFilter = (apps) => {
    let completedApps = apps.filter((x) => {
      return x["Current Status"].toLowerCase().trim() === "complete";
    });

    if (startDate && !endDate) {
      //filter taraget applications with status = complete && status date >= startDate
      const startDateConv = new Date(startDate);

      let filteredApps = completedApps.filter((x) => {
        // let statusDate = new Date(x["Status Date"]);
        let dateParts = x["Status Date"].split("-");

        let statusDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        console.log("status date: ", x["Status Date"]);
        console.log("status date2: ", statusDate);

        return statusDate >= startDateConv;
      });

      return filteredApps;
    } else if (endDate && !startDate) {
      //filter taraget applications with status = complete && status date<= endDate
      const endDateConv = new Date(endDate);

      let filteredApps = completedApps.filter((x) => {
        let dateParts = x["Status Date"].split("-");

        let statusDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return statusDate <= endDateConv;
      });

      return filteredApps;
    } else if (startDate && endDate) {
      //filter taraget applications with status = complete && status date<= endDate && status date >= startDate
      const startDateConv = new Date(startDate);

      const endDateConv = new Date(endDate);

      let filteredApps = completedApps.filter((x) => {
        let dateParts = x["Status Date"].split("-");

        let statusDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return statusDate <= endDateConv && statusDate >= startDateConv;
      });

      return filteredApps;
    }
    return apps;
  };

  const exportExcel = () => {
    if (reviewNotes == -1) {
      setTimeout(() => {
        exportExcel();
      }, 1000);
      return false;
    }

    // create the array of data to export
    let tempFinalData = targetApplications.map((x) => {
      let tmpApp = appData?.applications?.filter((y) => y?.id === x?.id)[0];

      let tmpNote = reviewNotes?.filter((y) => y?.applicationId === x?.id)[0];
      // let accNo = x.propertyId.locationNo;

      let accNo = //acc number from application only available from account numbers associated to customer
        x?.propertyId?.customerProperty?.length > 0
          ? x?.propertyId?.customerProperty[0]?.accountNo
          : "";
      return {
        ID: x.id,
        "Application Submission Date": x.date.split("T")[0],
        "Property Address": x.address,
        "Applicant Name": x.name,
        "Program Name": x.programName,
        "Status #": x.status,
        "Current Status": x.currentStatus,
        "Status Date": x.statusDate.split("T")[0],
        "Assigned To": x.assignedTo,
        Cost: tmpApp?.cost || "", // inside of the application table
        "Water Savings (gals per year)": x.waterSavings || "",
        "Grant Funded Amount": tmpApp?.grantFundedAmount || "", // inside of the application table
        "Internal Review Notes": tmpNote?.value, // inside of the ReviewNote table. left join off of application id & select max time
        "Property ID": tmpApp?.propertyId || "", // inside of the application table
        "Customer ID": x.customerId, // inside of the application table
        "Account ID": accNo || "", // inside of the application table
      };
    });
    ///
    let finalData = applyDateFilter(tempFinalData);
    console.log("Final Data: ", finalData);
    ////
    //-- writing the excel file --//
    const workBook = XLSX.utils.book_new();

    const workSheet = XLSX.utils.json_to_sheet(finalData);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Temp");

    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Application_Export-" + Date.now() + ".xlsx");

    //console.log("Time: " + time);
  };

  const createCollisionKey = (key, obj) => {
    let searchKey = key;
    let index = 1;
    while (obj.hasOwnProperty(searchKey)) {
      let temp = searchKey.split("-")[0];
      searchKey = `${temp}-${index}`;
      index++;
    }

    return searchKey;
  };

  const exportAppDetails = (id) => {
    // catch if reviewNotes hasnt loaded yet
    if (reviewNotes == -1) {
      setTimeout(() => {
        exportExcel();
      }, 1000);
      return false;
    }

    const selectedApps = targetApplications?.filter((x) => {
      return x.statusObj?.programId === id;
    });

    console.log("selected-apps", selectedApps);
    const pfArr = [];

    let finalDataTemp = selectedApps.map((x) => {
      const pfObj = {};
      fieldList
        ?.filter((f) => {
          return f.programId === x.statusObj?.programId;
        })
        .forEach((pf, index) => {
          // console.log("field-name: ", pf.fieldName);
          let addedVal = "";
          if (pf.fieldType === "CHECK") {
            if (pf.checkValues.length > 0) {
              pf.checkValues.forEach((cv, index) => {
                const cVal = appValues?.filter((av) => {
                  return av.applicationId === x.id && av.checkValueId === cv.id;
                });

                if (cVal[0]?.value === "true")
                  if (index === pf.checkValues.length - 1) {
                    addedVal += `${cv.value}`;
                  } else {
                    addedVal += `${cv.value}, `;
                  }
              });

              if (pf.checkOther) {
                const coVal = appValues?.filter((av) => {
                  return (
                    av.applicationId === x.id &&
                    av.checkValueId === null &&
                    av.fieldId === pf.id
                  );
                });
                if (coVal.length > 0) {
                  addedVal += `, ${coVal[0].value}`;
                }
              }
            }
          } else if (pf.fieldType === "LOOKUP") {
            addedVal = "";
          } else {
            const pfVal = appValues?.filter((av) => {
              return av.applicationId === x?.id && av.fieldId === pf?.id;
            });
            if (pfVal.length === 1) {
              addedVal = pfVal[0].value;
            } else if (pfVal.length > 1) {
              addedVal = pfVal.reduce((a, b) => {
                return a.createdAt > b.createdAt ? a : b;
              }).value;
            }
          }

          let pfObjKey = createCollisionKey(pf.fieldName, pfObj);
          pfObj[pfObjKey] = addedVal;
          // pfArr.push([pf.fieldName, addedVal]);
        });

      let tmpApp = appData?.applications?.filter((y) => y?.id === x?.id)[0];
      let tmpNote = reviewNotes?.filter((y) => y?.applicationId === x?.id)[0];
      return {
        ID: x.id,
        "Application Submission Date": x.date.split("T")[0],
        "Property Address": x.address,
        "Applicant Name": x.name,
        "Program Name": x.programName,
        "Status #": x.status,
        "Current Status": x.currentStatus,
        "Status Date": x.statusDate.split("T")[0],
        "Assigned To": x.assignedTo,
        Cost: tmpApp?.cost || "", // inside of the application table
        "Water Savings (gals per year)": x.waterSavings || "",
        "Grant Funded Amount": tmpApp?.grantFundedAmount || "", // inside of the application table
        "Internal Review Notes": tmpNote?.value, // inside of the ReviewNote table. left join off of application id & select max time
        "Property ID": tmpApp?.propertyId || "", // inside of the application table
        "Customer ID": x.customerId, // inside of the application table
        "Account ID": tmpApp?.accountNo || "", // inside of the application table
        ...pfObj,
      };
    });

    console.log("final-data: ", finalDataTemp);
    let finalData = applyDateFilter(finalDataTemp);

    console.log("final: ", finalData);
    const workBook = XLSX.utils.book_new();

    const workSheet = XLSX.utils.json_to_sheet(finalData);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Temp");

    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Application_Export-" + Date.now() + ".xlsx");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`application-table bg-white dark:bg-gray-800 dark:text-white rounded duration-400 ease-in-out transition-spacing font-sans flex flex-col gap-4 max-h-[50rem]`}
    >
      {modalController.appModal && (
        <AppModal
          props={{
            modalController: modalController,
            waterSavingsController: waterSavingsController,
            update: update,
          }}
        />
      )}
      {waterSavingsController.waterSavingsModal && (
        <WaterSavingsLog
          props={{
            modalController: modalController,
            waterSavingsController: waterSavingsController,
          }}
        />
      )}
      {modalController.closeWarning && (
        <CloseWarningModal props={{ modalController: modalController }} />
      )}

      {exportModal && (
        <ExportModal
          props={{
            handleClose: closeExportModal,
            exportExcel: exportExcel,
            exportAppDetails: exportAppDetails,
            startDate: startDate,
            endDate: endDate,
            setStartDate: setStartDate,
            setEndDate: setEndDate,
          }}
        />
      )}

      {props.view && (
        <Header
          props={{
            openImportModal: openImportModal,
            setFilterType: setFilterType,
            setFilterText: setFilterText,
            setFilterOptions: setFilterOptions,
            setSortOptions: setSortOptions,
            showMyAssignments: showMyAssignments,
            exportExcel: exportExcel,
            setExportModal: setExportModal,
            exportModal: exportModal,
            view: props.view,
          }}
        />
      )}
      {importModal && (
        <ImportReport
          props={{ report: report, closeImportModal: closeImportModal }}
        />
      )}
      {/* <hr /> */}
      {/* current column heads are hardcoded for sorting. need to find an elegant way to provide this to the component */}
      <div className="overflow-auto w-full text-white rounded border border-gray-100 mt-4 max-h-[50rem]">
        <table className=" font-sans shadow w-full">
          <thead className="border-b-2 border-gray-200 bg-wc-green sticky top-0">
            <tr>
              <th className="p-2 py-1 text-sm font-semibold tracking-wider text-start border-r border-gray-50 ">
                <span className="flex flex-col cursor-pointer">
                  Application ID
                </span>
                {genSortButtons("id")}
              </th>
              <th className="p-2 text-sm font-semibold tracking-wider text-start border-r border-gray-50 ">
                <span className="flex flex-col cursor-pointer">
                  Application Submission Date
                </span>
                {genSortButtons("date")}
              </th>

              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                <span className="flex flex-col cursor-pointer">
                  Property Address
                </span>
                {genSortButtons("address")}
              </th>

              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                <span className="flex flex-col cursor-pointer">
                  Applicant Name
                </span>
                {genSortButtons("name")}
              </th>
              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                <span
                  className="flex flex-col cursor-pointer"
                  onClick={(e) => {
                    setShowFilterModal((prev) => ({
                      ...prev,
                      type: "programName",
                      show: 1,
                    }));
                  }}
                >
                  Program Name
                </span>
                {genSortButtons("programName")}
              </th>
              {props.view === "app" && (
                <th
                  className={
                    "p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start " +
                    (Object.keys(filterOptions)[0] == "status"
                      ? "bg-blue-400"
                      : "")
                  }
                >
                  <span
                    className="flex flex-col cursor-pointer"
                    onClick={(e) => {
                      setShowFilterModal((prev) => ({
                        ...prev,
                        type: "status",
                        show: 1,
                      }));
                    }}
                  >
                    Status #
                  </span>
                  {genSortButtons("status")}
                </th>
              )}
              {props.view === "property" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                  <span>Water Savings</span>
                  {genSortButtons("status")}
                </th>
              )}
              {props.view === "property" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                  <span>Cost</span>
                  {genSortButtons("status")}
                </th>
              )}

              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                <span
                  className="flex flex-col cursor-pointer"
                  onClick={(e) => {
                    setShowFilterModal((prev) => ({
                      ...prev,
                      type: "currentStatus",
                      show: 1,
                    }));
                  }}
                >
                  Current Status
                </span>
                {genSortButtons("currentStatus")}
              </th>
              {props.view === "app" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                  <span
                    className="flex flex-col cursor-pointer"
                    onClick={(e) => {
                      setShowFilterModal((prev) => ({
                        ...prev,
                        type: "currentStatus",
                        show: 1,
                      }));
                    }}
                  >
                    Date
                  </span>
                  {genSortButtons("statusDate")}
                </th>
              )}

              {props.view === "app" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start ">
                  <span
                    className="flex flex-col cursor-pointer"
                    onClick={(e) => {
                      setShowFilterModal((prev) => ({
                        ...prev,
                        type: "assignedTo",
                        show: 1,
                      }));
                    }}
                  >
                    Assigned To
                  </span>
                  {genSortButtons("assignedTo")}
                </th>
              )}

              {props.view === "app" && (
                <th className="p-2 text-sm font-semibold tracking-wider text-start ">
                  <span
                    className="flex flex-col cursor-pointer"
                    onClick={(e) => {
                      setShowFilterModal((prev) => ({
                        ...prev,
                        type: "alerts",
                        show: 1,
                      }));
                    }}
                  >
                    Alerts
                  </span>
                  {genSortButtons("alert")}
                </th>
              )}
            </tr>
          </thead>

          {props && props.applications && (
            <tbody className="divide-y">{displayProperties}</tbody>
          )}
        </table>
      </div>
      <Paginate
        props={{
          totalItems: targetApplications?.length,
          itemsPerPage: 50,
          setCurrentPage: setCurrentPage,
        }}
      />

      {showFilterModal?.show == 1 && (
        <ApplicationTableFilterModal
          props={{
            closeModal: setShowFilterModal,
            data: targetApplications,
            filter: showFilterModal.type,
            setFilter: setFilterOptions,
          }}
        />
      )}
    </motion.div>
  );
};

export default ApplicationTable;
