import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import LineChart from "../../Charts/LineChart";
import AssignmentChart from "../../Charts/AssignmentChart";

import { useSelector } from "react-redux";
import { selectAllApplications } from "../../../features/application";
import { selectAllStatus } from "../../../features/applicationStatus";
import { selectAllPrograms } from "../../../features/program";
import { selectAllUsers } from "../../../features/user";
import { selectStatusList, selectStatusLogs } from "../../../features/status";
import ProgramSummary from "../../Home/ProgramSummary";

const HomeContainer = ({ props }) => {
  const [applicationData, setApplicationData] = useState({});
  const applicationList = useSelector(selectAllApplications);
  const appStatus = useSelector(selectAllStatus);
  const programList = useSelector(selectAllPrograms);
  const users = useSelector(selectAllUsers);
  const statusLogs = useSelector(selectStatusLogs);
  const statusList = useSelector(selectStatusList);

  const [asignees, setAsignees] = useState({});

  const [programShortcutList, setProgramShortcutList] = useState(null);
  const [hideInactivePrograms, setHideInactivePrograms] = useState(true);
  const pageRefs = useRef([]);
  pageRefs.current = [];

  const addToRefs = (x) => {
    if (x && !pageRefs.current.includes(x)) {
      pageRefs.current.push(x);
    }
  };

  useEffect(() => {
    if (hideInactivePrograms) {
      const validPrograms = programList?.filter((program) => {
        const endDate = new Date(program.programEnd);
        return endDate >= new Date();
      });
      setProgramShortcutList(validPrograms);
    } else {
      setProgramShortcutList(programList);
    }
  }, [programList, hideInactivePrograms]);

  //////////
  function subtractMonths(date, months) {
    var day = date.getDate();
    date.setMonth(date.getMonth() - months);
    if (date.getDate() !== day) date.setDate(0);
    return date;
  }

  useEffect(() => {
    if (applicationList && applicationList.length > 0) {
      let tmpArr = [0, 1, 2, 3, 4, 5].map((x) => {
        return applicationList?.filter((y) => {
          const statusId = appStatus?.filter((a) => {
            return y.id === a.applicationId;
          })[0]?.statusId;
          if (statusId && statusId > 0) {
            const td = new Date();

            let tmpDateS = subtractMonths(td, x);
            let tempMonthS = tmpDateS.getMonth();
            let tmpDateC = new Date(y.createdAt).getMonth();

            return tempMonthS === tmpDateC;
          }
        });
      });
      let programs = [
        ...new Set(tmpArr.map((x) => x.map((y) => y.programId)).flat()),
      ];
      let programNames = programs.map(
        (x) =>
          programList?.filter((y) => y.id === x)[0]?.programName ||
          "No name in system"
      );
      let programHits = [
        ...new Set(tmpArr.map((x) => x.map((y) => y.programId))),
      ];

      setApplicationData({
        names: programNames,
        hits: programHits,
        programIds: programs,
      });
    }
  }, [applicationList, appStatus, programList, setApplicationData]);
  // function subtractMonths(date, months) {
  //   const day = date.getDate();
  //   date.setMonth(date.getMonth() - months);
  //   if (date.getDate() !== day) date.setDate(0);
  //   return date;
  // }

  // useEffect(() => {
  //   if (applicationList && applicationList.length > 0) {
  //     const tmpArr = [0, 1, 2, 3, 4, 5].map((x) => {
  //       const td = new Date();
  //       const tmpDateS = subtractMonths(td, x);
  //       const tempMonthS = tmpDateS.getMonth();

  //       return applicationList.filter((y) => {
  //         const statusId = appStatus?.find(
  //           (a) => y.id === a.applicationId
  //         )?.statusId;
  //         if (statusId && statusId > 0) {
  //           const tmpDateC = new Date(y.createdAt).getMonth();
  //           return tempMonthS === tmpDateC;
  //         }
  //         return false;
  //       });
  //     });

  //     const programs = [...new Set(tmpArr.flat().map((y) => y.programId))];
  //     const programNames = programs.map(
  //       (x) =>
  //         programList.find((y) => y.id === x)?.programName ||
  //         "No name in system"
  //     );
  //     const programHits = [...new Set(tmpArr.flat().map((y) => y.programId))];

  //     setApplicationData({
  //       names: programNames,
  //       hits: programHits,
  //       programIds: programs,
  //     });
  //   }
  // }, [applicationList, appStatus, programList, setApplicationData]);
  ////////////

  useEffect(() => {
    if (statusLogs) {
      const temp = {};
      let newStatusLogs = statusLogs.filter((x) => {
        let res = 1;
        for (let y of statusLogs) {
          if (x.id !== y.id && x.applicationId === y.applicationId) {
            if (new Date(x.createdAt) < new Date(y.createdAt)) res = 0;
            else res = res || 0;
          }
        }
        return res;
      });

      // map over the latest status logs
      newStatusLogs.map((statusLog) => {
        if (!temp[statusLog.staffAssignmentId])
          temp[statusLog.staffAssignmentId] = 1;
        else temp[statusLog.staffAssignmentId] += 1;
      });

      users?.map((user) => {
        Object.entries(temp).map((e) => {
          if (e[0] == user.id) {
            temp[user.firstName + " " + user.lastName] = e[1];
            delete temp[e[0]];
          }
        });
      });

      // change null to No staff Assigned
      temp["No Staff Assigned"] = temp["null"];
      delete temp["null"];

      const final = Object.entries(temp).map((x) => {
        return {
          first: x[0].split(" ")[0],
          last: x[0].split(" ").slice(1).join(" "),
          count: x[1],
        };
      });

      setAsignees(final);
    }
  }, [statusLogs, users]);

  const scrollToDiv = (index) => {
    pageRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  return (
    <div
      className={`h-full bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 p-8 ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content overflow-auto">
        <div className="flex flex-col ">
          <span className="text-lg font-sans mb-8">HOME</span>
          <div className="flex flex-col gap-9">
            <div className="flex gap-12 ">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full gap-8 lg:flex-row md:flex-col md:flex"
              >
                {applicationData ? (
                  <LineChart props={{ data: applicationData }} />
                ) : null}
                {asignees ? (
                  <AssignmentChart props={{ data: asignees }} />
                ) : null}
              </motion.div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="w-full flex flex-col gap-2">
                <h2>Programs Shortcut</h2>
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    checked={hideInactivePrograms}
                    onChange={(e) => {
                      setHideInactivePrograms(!hideInactivePrograms);
                    }}
                  />
                  <label>Hide inactive programs</label>
                </div>
              </div>
              <div className="flex flex-wrap gap-6 rounded border p-8 bg-gray-50 cursor-pointer">
                {pageRefs &&
                  programShortcutList?.map((x, index) => {
                    return (
                      <span
                        className="bg-gray-50 rounded hover:text-blue-500 underline"
                        onClick={() => {
                          scrollToDiv(index);
                        }}
                      >{`${x.programName}`}</span>
                    );
                  })}
              </div>
            </div>
            {programShortcutList &&
              statusList &&
              applicationList &&
              appStatus &&
              programShortcutList?.map((el, index) => {
                return (
                  <div className="flex flex-col" ref={addToRefs}>
                    <ProgramSummary
                      key={index}
                      props={{
                        title: el.programName,
                        steps: statusList?.filter((status) => {
                          return status.programId === el.id;
                        }),
                        apps: applicationList?.filter((app) => {
                          return app.programId === el.id;
                        }),
                        appStatus: appStatus,
                        totals: el.programBudget,
                        program: el,
                        grantTotals: el.grantFunding,
                        statusLogs: statusLogs,
                      }}
                    />
                    <span>{el.name}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
