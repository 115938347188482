import React from "react";
import program from "../../features/program";

const ProgramSummaryBar = ({
  props,
  title,
  total,
  completed,
  in_progress,
  estimate,
  program,
}) => {
  console.log("program:::, ", program);
  //index doesnt match
  //console.log("Program Summary Bar props:", program);

  const genColors = [];

  return (
    <div className="flex flex-col w-full p-[2rem]">
      <div className="flex flex-row">
        <span className="text-lg font-bold">{title}</span>
      </div>
      <div className="flex flex-row">
        <span className="text-md">
          ${completed}/${total} budget spent (
          <span className="text-green-700">completed</span> applications)
        </span>
      </div>
      <div className="flex flex-row">
        <span className="text-md">
          ${in_progress}/${total} budget spent (
          <span className="text-green-700">completed</span> +{" "}
          <span className="text-amber-500">in progress</span> applications)
        </span>
      </div>
      <div className="flex flex-row w-full h-[4rem]">
        <div
          className="flex flex-col h-full bg-lime-700"
          style={{
            width: (completed / total) * 100 + "%",
            // width: 50 + "%",
          }}
        >
          <span className="text-lg text-center mt-auto mb-auto text-gray-50 font-bold">
            {`${Math.floor((completed / total) * 100)} %`}
          </span>
        </div>
        <div
          className="flex flex-col h-full bg-amber-400"
          style={{
            width: ((in_progress - completed) / total) * 100 + "%",
            // width: 50 + "%",
          }}
        >
          {in_progress && (
            <span className="text-lg text-center mt-auto mb-auto text-gray-50 font-bold">
              {Math.floor((in_progress / total) * 100)}%
            </span>
          )}
        </div>
        <div
          className="flex flex-col h-full bg-gray-300"
          style={{
            width: Math.floor(((total - in_progress) / total) * 100) + "%",
          }}
        />
      </div>

      {program.defaultUnitCost ? (
        <div className="flex flex-row w-full">
          <span className="text-lg">
            <span className="font-bold text-lg">
              {Math.floor((total - in_progress) / program.defaultUnitCost)}
            </span>{" "}
            estimated additional applications can be{" "}
            {title.includes("Fund") ? "funded" : "accepted"}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ProgramSummaryBar;
