import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Moment from "react-moment";
import { updateBudget } from "../../features/programBudgetLog";
import { selectAllUsers } from "../../features/user";
import moment from "moment";

import { useSelector } from "react-redux";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const ProgramBudget = ({ props }) => {
  const [budgetComment, setBudgetComment] = useState(props.budget.comment);

  const updateBudget = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/program/budget/update`
          : `http://localhost:3000/program/budget/update`;

      const res = await axios.post(
        url,
        { id: props.budget.id, comment: e },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const updatedBudget = res.data.data;
      console.log(updatedBudget);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    updateBudget(budgetComment);
  }, [budgetComment]);

  const [user, setUser] = useState(null);
  const users = useSelector(selectAllUsers);

  useEffect(() => {
    if (users) {
      const temp = users.filter((user) => {
        return user.id === props.budget.userId;
      });
      setUser(temp[0]);
    }
  });

  return (
    <tr className={`rounded-lg dark:text-white text-black text-start`}>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        <span>$ </span>
        {props.budget.value.toLocaleString()}
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {/* <Moment format="MM/DD/YYYY">
          {moment.utc(props.budget.createdAt).format("MM/DD/YYYY")}
        </Moment> */}
        <Moment format="MM/DD/YYYY">{props.budget.createdAt}</Moment>
      </td>
      <td className="p-3 dark:text-white hover:underline whitespace-nowrap">
        {user && `${user.email}`}
      </td>
      <td className="p-3  text-blue-500 dark:text-white hover:underline r whitespace-nowrap">
        {/* fix in future there shouldn't be two inputs determined by budgetComment */}
        <textarea
          onChange={(e) => {
            setBudgetComment(e.target.value);
          }}
          defaultValue={budgetComment}
          className="text-black border-2 border-gray-200 rounded w-full p-2"
        />
      </td>
    </tr>
  );
};

export default ProgramBudget;
