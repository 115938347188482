export const checkEmptyFields = (state) => {
  const errors = {};
  for (const [key, value] of Object.entries(state)) {
    if (
      value === "" &&
      key !== "defaultUnitCost" &&
      key !== "defaultUnitWaterSavings"
    ) {
      // alert(`missing required field ${key}`);
      errors[key] = `missing required field ${key}`;
    }
  }

  if (Object.keys(errors).length > 0) {
    console.log("errors", Object.keys(errors));
    return { empty: true, errors };
  } else {
    console.log("successssss", Object.keys(errors));
    return { empty: false, errors };
  }
};

export const passwordConfirmation = (state) => {
  if (state.password !== state.confirmPassword) {
    alert("password and password confirmation don't match");
    return false;
  } else if (state.password.length < 6) {
    alert("password doesn't meet requirement lenght of 6");
    return false;
  }
  return true;
};

export const validEmailCheck = (state) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
};
