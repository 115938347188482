import React, { useEffect, useState } from "react";
import ProgramSummaryCard from "./ProgramSummaryCard";
import ProgramSummaryCardStatus from "./ProgramSummaryCardStatus";
import TotalsSummary from "../../components/Home/TotalsSummary";
import ProgramSummaryBar from "./ProgramSummaryBar";

const ProgramSummary = ({ props }) => {
  console.log("statusData:", props);

  const [status, setStatus] = useState(null);
  const [complete, setComplete] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [completeGrant, setCompleteGrant] = useState(0);
  const [cancelGrant, setCancelGrant] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  useEffect(() => {
    const count = {};
    const overdue = {};
    let completeCost = 0;
    let nonCancel = 0;
    let completeGrant = 0;
    let cancelGrant = 0;

    for (let i = 0; i < props.apps?.length; i++) {
      const currStatus = props.appStatus?.filter((status) => {
        return status.applicationId === props.apps[i].id;
      });
      if (currStatus?.length > 0 && currStatus[0].statusId !== "0") {
        //restrict apps in state 0
        count[currStatus[0].statusId]
          ? (count[currStatus[0].statusId] += 1)
          : (count[currStatus[0].statusId] = 1);

        const statusName = props.steps?.filter((s) => {
          return parseInt(s.statusId) === currStatus[0].statusId;
        });
        if (statusName?.length > 0) {
          if (statusName[0]?.name.toLowerCase() === "complete") {
            completeCost += props.apps[i].cost;
            completeGrant += props.apps[i].grantFundedAmount;
          }
          if (statusName[0]?.name.toLowerCase() !== "cancelled") {
            nonCancel += props.apps[i].cost;
            cancelGrant += props.apps[i].grantFundedAmount;
          }
          console.log("count: ", count);

          ///
          const daysBeforeAlert = statusName[0]?.daysBeforeAlert;
          console.log("days before alert:", daysBeforeAlert);
          const appStatusLog = props?.statusLogs?.filter((log) => {
            return log.applicationId === props.apps[i].id;
          });

          if (appStatusLog?.length > 0) {
            appStatusLog.sort((a, b) => {
              return b.id - a.id;
            });
            console.log("appStatusLog: ", appStatusLog);
            let recentCreated = new Date(appStatusLog[0].createdAt);
            console.log("recentCreated", recentCreated);
            const alertDate = recentCreated.getDate() + daysBeforeAlert;
            recentCreated.setDate(alertDate);
            console.log("alertDate: ", recentCreated);
            const currentDate = new Date();

            console.log("currentDate: ", currentDate);
            console.log("recent created", recentCreated);
            if (currentDate >= recentCreated) {
              console.log("currentDate >= recentCreated");
              overdue[currStatus[0].statusId]
                ? (overdue[currStatus[0].statusId] += 1)
                : (overdue[currStatus[0].statusId] = 1);
            }
          }
        }
      }
    }
    console.log("completeGrant:", completeGrant);
    const temp = props.steps.map((step) => {
      return {
        label: step.number,
        name: step.name,
        apps: count[parseInt(step.statusId)]
          ? count[parseInt(step.statusId)]
          : 0,
        overdue: overdue[parseInt(step.statusId)]
          ? overdue[parseInt(step.statusId)]
          : 0,
      };
    });

    setStatus(temp);
    setOverdueCount(overdue);
    setComplete(completeCost);
    setCompleteGrant(completeGrant);
    setCancel(nonCancel);
    setCancelGrant(cancelGrant);

    //program budget
  }, [props.steps]);

  const cardBorder = [
    "border-teal-400",
    "border-green-400",
    "border-orange-400",
    "border-blue-400",
  ];

  // the test data used to create the card. grabbed from ACWDCONS-23's graphics
  const tmpData = {
    statusData: [
      { label: "1", name: "Pending Review", apps: 2, overdue: 0 },
      { label: "2", name: "Initial Review", apps: 1, overdue: 0 },
      { label: "2b", name: "Application Incomplete", apps: 7, overdue: 2 },
      {
        label: "3",
        name: "Customer Pre-Install Activities",
        apps: 25,
        overdue: 5,
      },
      { label: "4", name: "Pre-Install Review", apps: 3, overdue: 1 },
      { label: "4b", name: "Pre-Install Incomplete", apps: 0, overdue: 0 },
      { label: "5", name: "Awaiting Installation", apps: 17, overdue: 1 },
      { label: "6", name: "Post-Install Review", apps: 5, overdue: 2 },
      { label: "7", name: "Supervisor Review", apps: 2, overdue: 2 },
      { label: "8", name: "Payment Pending", apps: 7, overdue: 0 },
      { label: "9", name: "Complete", apps: 45, overdue: 0 },
    ],
    programBudget: {
      title: "Program Budget",
      total: 75000,
      completed: 47000,
      in_progress: 20000,
    },
    grantFunding: {
      title: "Grant Funding",
      total: 47000,
      completed: 40000,
      in_progress: 2500,
    },
  };

  // delete this once we got the data regularly. quick safety check to make sure we display something
  props.data = props.data ? props.data : tmpData;

  // quick safety check on if the data exists
  if (!props?.title) {
    return <></>;
  }

  return (
    <div className="w-full h-[auto]  pb-[1rem] bg-gray-50 dark:bg-gray-700 rounded shadow">
      <h2 className="text-lg top-0 w-full bg-wc-green  text-white rounded-t text-center  tracking-wide p-2 shadow-md border border-gray-200 dark:border-gray-400">
        {`${props.title} program summary`}
      </h2>
      <div className="flex flex-row pt-3">
        <div className="flex flex-col absolute w-[12%] pl-[2rem]">
          <div className="flex flex-row h-[4rem]"></div>
          <div className="flex flex-row h-[4rem]"></div>
          <div className="flex flex-row h-[2rem]">
            <span className=""># Apps</span>
          </div>
          <div className="flex flex-row h-[2rem]">
            <span className=""># Overdue</span>
          </div>
        </div>
        <div className="flex flex-col w-full">
          {false && (
            <>
              {/* The original program summary made by CE */}
              {props.steps.map((el, index) => {
                return (
                  <ProgramSummaryCard
                    key={index}
                    index={index}
                    props={{
                      title: el.description,
                      count: el.count,
                      icon: el.icon,
                      border: cardBorder[index],
                    }}
                  />
                );
              })}
              <TotalsSummary props={{ totals: props.totals }} />
            </>
          )}
          {status && (
            <>
              <ProgramSummaryCardStatus data={status} />
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row w-full">
        <div
          className={`flex flex-col ${
            props.data.grantFunding ? "w-[50%]" : "w-full"
          }`}
        >
          {/* {complete && ( */}
          <ProgramSummaryBar
            title={props.data.programBudget.title}
            total={props.totals}
            completed={complete}
            in_progress={cancel}
            program={props.program}
          />
          {/* )} */}
        </div>
        {props.program.grantFunding ? (
          <div className="flex flex-col w-[50%]">
            <ProgramSummaryBar
              title={props.data.grantFunding.title}
              total={props.grantTotals}
              completed={completeGrant}
              in_progress={cancelGrant}
              program={props.program}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProgramSummary;
