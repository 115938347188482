export const formatAddress = (payload) => {
  return `${payload[0] ? payload[0]?.toString().trim() : ""}${
    payload[1] ? payload[1]?.trim() : ""
  } ${payload[2] ? payload[2]?.trim() : ""} ${
    payload[3] ? payload[3]?.trim() : ""
  } ${payload[4] ? payload[4]?.trim() : ""} ${
    payload[5] ? payload[5]?.trim() : ""
  } ${payload[6] ? payload[6]?.trim() : ""}`;
};
export const formatFullAddress = (payload) => {
  return `${
    payload[0] &&
    payload[0] !== "undefined" &&
    payload[0] !== "null" &&
    payload[0] !== "NULL"
      ? payload[0]?.toString().trim()
      : ""
  }${
    payload[1] &&
    payload[1] !== "undefined" &&
    payload[1] !== "null" &&
    payload[1] !== "NULL"
      ? ` ${payload[1]?.trim()}`
      : ""
  }${
    payload[2] &&
    payload[2] !== "undefined" &&
    payload[2] !== "null" &&
    payload[2] !== "NULL"
      ? ` ${payload[2]?.trim()}`
      : ""
  }${
    payload[3] &&
    payload[3] !== "undefined" &&
    payload[3] !== "null" &&
    payload[3] !== "NULL"
      ? ` ${payload[3]?.trim()}`
      : ""
  }${
    payload[4] &&
    payload[4] !== "undefined" &&
    payload[4] !== "null" &&
    payload[4] !== "NULL"
      ? ` ${payload[4]?.trim()}`
      : ""
  }${
    payload[5] &&
    payload[5] !== "undefined" &&
    payload[5] !== "null" &&
    payload[5] !== "NULL"
      ? ` ${payload[5]?.trim()}`
      : ""
  }${
    payload[6] &&
    payload[6] !== "undefined" &&
    payload[6] !== "null" &&
    payload[6] !== "NULL"
      ? ` ${payload[6]?.trim()}`
      : ""
  }${
    payload[7] &&
    payload[7] !== "undefined" &&
    payload[7] !== "null" &&
    payload[7] !== "NULL"
      ? `, ${payload[7]?.trim()}`
      : ""
  }${
    payload[8] &&
    payload[8] !== "undefined" &&
    payload[8] !== "null" &&
    payload[8] !== "NULL"
      ? `, ${payload[8]?.trim()}`
      : ""
  }${
    payload[9] &&
    payload[9] !== "undefined" &&
    payload[9] !== "null" &&
    payload[9] !== "NULL"
      ? ` ${payload[9]?.trim()}`
      : ""
  }`;
};
