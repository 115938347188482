const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net";
const local_base_url = "http://localhost:3000";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const axiosInstance = axios.create({
  baseURL: active_base_url,
  headers: {
    test: "hello",
  },
});

export default axiosInstance;
