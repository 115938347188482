import React, { useEffect } from "react";
import StartApplication from "../../Applications/StartApplication";
import {
  getFieldStatus,
  selectAllFields,
  fetchFields,
} from "../../../features/programField";
import {
  getUploadStatus,
  selectAllUploads,
  fetchUploads,
} from "../../../features/programUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProperties,
  getPropertyStatus,
  selectAllProperties,
} from "../../../features/properties";

const StartApplicationContainer = ({ props }) => {
  const dispatch = useDispatch();
  const fieldStatus = useSelector(getFieldStatus);
  const uploadStatus = useSelector(getUploadStatus);
  const propertyStatus = useSelector(getPropertyStatus);

  const uploadList = useSelector(selectAllUploads);
  const fieldList = useSelector(selectAllFields);
  const propertyList = useSelector(selectAllProperties);

  useEffect(() => {
    // On table load
    if (uploadStatus === "idle") dispatch(fetchUploads());
    if (propertyStatus === "idle") dispatch(fetchProperties());
    if (fieldStatus === "idle") dispatch(fetchFields());
  }, [uploadStatus, propertyStatus, fieldStatus, dispatch]);

  // Since this component is specifically for StartApplication, we can set this to true
  // const isStartApplication = true;

  return (
    <div
      id="mainContainer"
      className={`transition h-full font-sans duration-200 dark:bg-gray-900 dark:text-gray-50 md:px-24 md:py-6  ${
        props.sidebarOpen && localStorage.getItem("user_id") !== "123"
          ? "md:ml-[15rem]"
          : "md:ml-0"
      }`}
    >
      <div className="main-content md:overflow-auto">
        {
          <>
            <StartApplication
              props={{
                programs: props.programs,
                uploads: uploadList,
                fields: fieldList,
                properties: propertyList,
              }}
            />
          </>
        }
      </div>
    </div>
  );
};

export default StartApplicationContainer;
