import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import Customer from "./Customer";
import UpdateCustomerModal from "./UpdateCustomerModal";
import { selectAllCustomers } from "../../features/customer";

import AddCustomer from "./AddCustomerModal.js";

import DeleteModal from "../Misc/DeleteModal";
import CustomerPopupModal from "./CustomerPopupModal";
import PropertyModal from "../Properties/PropertyModal.js";
import Paginate from "../Misc/Paginate";
import { FaMagnifyingGlass } from "react-icons/fa6";

const CustomerTable = ({ props }) => {
  //pagination
  const customer_list = useSelector(selectAllCustomers);
  const [pageNumber, setPageNumber] = useState(0);

  const customersPerPage = 500;

  const [renderList, setRenderList] = useState(customer_list);

  const [filterType, setFilterType] = useState("");
  const [filterText, setFilterText] = useState("");

  //deleteModal
  const [deleteModal, setDeleteModal] = useState(false);
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  ////
  //propertyModal
  const [modalController, setModalController] = useState({
    propertyModal: false,
    property: null,
    programCost: "null",
    waterSavings: null,
    openPropertyModal: (payload, programCost, waterSavings) => {
      setModalController((prev) => ({
        ...prev,
        propertyModal: true,
        property: payload,
        programCost: programCost,
        waterSavings: waterSavings,
      }));
      closePopupModal();
    },
    closePropertyModal: () => {
      setModalController((prev) => ({
        ...prev,
        propertyModal: false,
      }));
    },
  });

  //updateModal
  const [updateModal, setUpdateModal] = useState(false);
  const closeUpdateModal = () => {
    setUpdateModal(false);
  };
  const openUpdateModal = () => {
    setUpdateModal(true);
  };

  // popupModal
  const [popupModal, setPopupModal] = useState(false);
  const closePopupModal = () => {
    setPopupModal(false);
  };

  const [customerId, setCustomerId] = useState(2);
  const openPopupModal = (id) => {
    setCustomerId(id);
    setPopupModal(true);
  };

  useEffect(() => {
    if (filterText && filterType) {
      let tmpObj = customer_list.filter((x) => {
        if (filterType == "email") {
          return x.email.toLowerCase().includes(filterText.toLowerCase())
            ? true
            : false;
        } else {
          let tmpName = (x.firstName + " " + x.lastName).toLowerCase();
          return tmpName.toLowerCase().includes(filterText.toLowerCase())
            ? true
            : false;
        }
      });
      setRenderList(tmpObj);
    } else setRenderList(customer_list);
  }, [filterText, filterType]);

  const [currentPage, setCurrentPage] = useState(1);
  const lastPostIndex = currentPage * 50;
  const firstPostIndex = lastPostIndex - 50;

  const displayUsers = renderList
    ?.slice(firstPostIndex, lastPostIndex)
    .filter((cs) => {
      return cs.id !== 123;
    }) //add filter to hide guest customer
    .map((customer, index) => {
      return (
        <Customer
          props={customer}
          index={index}
          key={index}
          openDeleteModal={openDeleteModal}
          openUpdateModal={openUpdateModal}
          openPopupModal={openPopupModal}
          openCustomerModal={props.openCustomerModal}
        />
      );
    });

  const pageCount = Math.ceil(renderList?.length / customersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //////
  const [addCustomer, setAddCustomer] = useState(false);

  const closeAddCustomer = () => {
    setAddCustomer(false);
  };

  const [addProperty, setAddProperty] = useState(false);
  const openAddProperty = () => {
    setAddProperty(true);
  };
  const closeAddProperty = () => {
    setAddProperty(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`application-table bg-white rounded-t dark:bg-gray-700  duration-400 ease-in-out transition-spacing font-sans flex flex-col`}
    >
      <span className="text-lg font-sans mb-8 ">CUSTOMERS</span>
      <AnimatePresence exitBeforeEnter initial={false}>
        {deleteModal && (
          <DeleteModal
            handleClose={closeDeleteModal}
            heading={"Are you sure you want to delete this customer?"}
            text={
              "Admin will still be able to view the customer in the customer table but the active status will be false, and the customer will no longer be able to login."
            }
          />
        )}
        {modalController.propertyModal && (
          <PropertyModal props={{ modalController: modalController }} />
        )}
        {updateModal && <UpdateCustomerModal handleClose={closeUpdateModal} />}
        {popupModal && (
          <CustomerPopupModal
            handleClose={closePopupModal}
            props={{ id: customerId, modalController: modalController }}
          />
        )}
      </AnimatePresence>

      <div className="flex flex-row  rounded-t mb-8 items-center">
        <label className="mr-2">Search by</label>
        <select
          className="border rounded h-[2.5rem] text-black mr-2"
          onChange={(e) => {
            setFilterType(e.target.value);
          }}
        >
          <option value="" disabled={true} selected={true}>
            Select One:
          </option>
          <option value="name">Customer Name</option>
          <option value="email">Customer Username</option>
        </select>

        {/* <input
          type="search"
          className="form-input block h-[2.5rem] border rounded w-2/12  text-gray-900 leading-5 transition duration-150 ease-in-out bg-white  placeholder-gray-500 appearance-none  cursor-pointer focus:outline-none focus:shadow-outline-blue focus:border-blue-300 "
          placeholder="Search"
          onChange={(e) => {
            console.log("Filter text: ", e);
            setFilterText(e.target.value);
          }}
        /> */}

        <div className="relative rounded-sm rounded-t w-fit">
          <div className="relative w-fit">
            <input
              type="search"
              className="form-input block h-[2.5rem] border rounded w-[24rem] pl-8 text-gray-900 leading-5 transition duration-150 ease-in-out bg-white  placeholder-gray-500 appearance-none  cursor-pointer focus:outline-none focus:shadow-outline-blue focus:border-blue-300 "
              placeholder="Search"
              onChange={(e) => {
                console.log("Filter text: ", e);
                setFilterText(e.target.value);
              }}
            />
            <div className="absolute top-0 bottom-0 left-0 flex items-center pointer-events-none pl-2">
              <FaMagnifyingGlass />
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="mb-8" /> */}

      <div className="overflow-auto  w-full rounded border border-gray-100 max-h-[50rem]">
        <table className="min-w-full shadow">
          <thead className="border-b-2 bg-blue-reef text-white sticky top-0">
            <tr>
              <th className="p-3 py-1 text-sm font-semibold tracking-wide text-start whitespace-nowrap border-r border-gray-50">
                Customer ID
              </th>
              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap border-r border-gray-50 ">
                Customer Name
              </th>
              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap border-r border-gray-50 ">
                Username
              </th>

              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap border-r border-gray-50 ">
                Number of Associated Applications
              </th>

              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap border-r border-gray-50 ">
                Cumulative Water Savings
              </th>
              <th className="p-3 text-sm font-semibold tracking-wide text-start whitespace-nowrap border-gray-50 ">
                Cumulative Program Costs
              </th>
            </tr>
          </thead>

          {renderList && <tbody className="divide-y">{displayUsers}</tbody>}
        </table>
      </div>
      <Paginate
        props={{
          totalItems: renderList?.length,
          itemsPerPage: 50,
          setCurrentPage: setCurrentPage,
        }}
      />

      {addCustomer && (
        <AddCustomer
          closeAddCustomer={closeAddCustomer}
          openAddProperty={openAddProperty}
          closeAddProperty={closeAddProperty}
        />
      )}
    </motion.div>
  );
};

export default CustomerTable;
