import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setToast } from "../utils/ToastNotification";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/location";
const local_base_url = "http://localhost:3000/location";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  locations: [],
  status: "idle",
  error: null,
};

export const fetchLocations = createAsyncThunk(
  "location/fetchLocations",
  async () => {
    try {
      const res = await axios.post(`${active_base_url}/fetch-all`, {});
      const locations = res.data.data;
      return locations;
    } catch (err) {
      console.log(err);
    }
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState: { value: initStateValue },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLocations.pending, (state, action) => {
        state.value.status = "loading";
        state.value.locations = action.payload;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.locations = action.payload;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllLocations = (state) => state.location.value.locations;
export const getLocationStatus = (state) => state.location.value.status;

export default locationSlice.reducer;
