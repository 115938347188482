import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "../Ui/button";

import { useDispatch, useSelector } from "react-redux";
import { selectAllUsers, deleteUser, fetchUsers } from "../../features/user";

import Users from "./Users.js";
import DeleteModal from "../Misc/DeleteModal";
import UpdateModal from "./UpdateUserModal";
import AddModal from "./AddUserModal";

const AdminTable = ({ sidebarOpen, setUsers, allUsers }) => {
  const user_list = useSelector(selectAllUsers);
  const dispatch = useDispatch();

  const [addUser, setAddUser] = useState(false);
  const openAddUser = () => {
    setAddUser(true);
  };
  const closeAddUser = () => {
    setAddUser(false);
  };
  const adminDeleteUser = async (id) => {
    try {
      dispatch(deleteUser({ id: id }));
      dispatch(fetchUsers());
    } catch (err) {
      console.log(err);
    }
  };

  //local state
  //delete
  const deleteId = useRef("");
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    setDeleteModal(true);
    deleteId.current = id;
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  //update
  const user_id = useRef(null);
  const firstName = useRef("");
  const lastName = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const role = useRef("");
  const deleted = useRef(null);
  const [updateModal, setUpdateModal] = useState(false);
  const openUpdateModal = (userInfo) => {
    user_id.current = userInfo.id;
    firstName.current = userInfo.firstName;
    lastName.current = userInfo.lastName;
    email.current = userInfo.email;
    phone.current = userInfo.phone;
    role.current = userInfo.role;
    deleted.current = userInfo.deleted;
    setUpdateModal(true);
  };

  //inactive filter
  const [renderList, setRenderList] = useState(user_list);

  const [inactive, setInactive] = useState(null);
  const openInactive = () => {
    setInactive(true);
  };
  const closeInactive = () => {
    setInactive(false);
  };

  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 500;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers = renderList
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    .map((user, index) => {
      return (
        <Users
          index={index}
          key={index}
          user={user}
          openDeleteModal={openDeleteModal}
          openUpdateModal={openUpdateModal}
        />
      );
    });

  useEffect(() => {
    if (inactive) {
      setRenderList(
        user_list.filter((user) => {
          return user.deleted === true;
        })
      );
    } else {
      setRenderList(user_list);
    }
  }, [inactive, user_list]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`admin-table bg-white dark:bg-gray-800 rounded  duration-400 ease-in-out transition-spacing font-sans flex flex-col  `}
    >
      <h2 className="mb-8 font-sans text-lg">Admin</h2>
      <AnimatePresence exitBeforeEnter initial={false}>
        {deleteModal && (
          <DeleteModal
            handleClose={closeDeleteModal}
            heading={"Are you sure you want to delete this user?"}
            text={
              "Admin will still be able to view the user in the admin table but the active status will be false, and user the will no longer be able to login."
            }
            deleteFunc={adminDeleteUser}
            id={deleteId.current}
          />
        )}
        {updateModal && (
          <UpdateModal
            handleClose={closeUpdateModal}
            user_id={user_id.current}
            firstName={firstName.current}
            lastName={lastName.current}
            email={email.current}
            phone={phone.current}
            role={role.current}
            deleted={deleted.current}
            setUsers={setUsers}
            allUsers={allUsers}
          />
        )}
        {addUser && (
          <AddModal
            handleClose={() => {
              closeAddUser();
            }}
          />
        )}
      </AnimatePresence>

      <div className="header w-full rounded-t py-2 flex items-center justify-between">
        <div className="flex gap-4 items-center">
          {user_list && (
            <div className="flex gap-2  mb-4">
              <Button
                variant="outline"
                className="rounded bg-green-reef text-white w-fit mx-auto"
                onClick={() => {
                  openAddUser();
                }}
              >
                Add User
              </Button>
              <div className="show-active flex gap-1 items-center">
                <input
                  type="checkbox"
                  value={inactive}
                  onChange={() => {
                    inactive ? closeInactive() : openInactive();
                  }}
                />
                <label>show inactive</label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <hr className="mb-8" /> */}

      <div className="overflow-auto  w-full rounded shadow border">
        <table className="min-w-full ">
          <thead className=" bg-blue-reef text-white">
            <tr className="">
              <th className="pl-2 py-2 text-sm font-semibold tracking-wider whitespace-nowrap text-start border-r border-gray-50">
                User ID
              </th>
              <th className="pl-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                Full Name
              </th>
              <th className="pl-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                Email
              </th>
              <th className="pl-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                Phone (Office)
              </th>
              <th className="pl-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                User Type
              </th>
              <th className="pl-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                Active
              </th>
              <th className="pl-2 text-sm font-semibold tracking-wider text-center whitespace-nowrap border-r border-gray-50">
                Actions
              </th>
            </tr>
          </thead>

          {renderList && <tbody className="divide-y">{displayUsers}</tbody>}
        </table>
      </div>
    </motion.div>
  );
};

export default AdminTable;
