import React from "react";

const ProgramSummaryCircle = ({
  props,
  label,
}) => {

  return (
    <div>
        <div className="w-auto flex flex-col  ">
            <div className={`flex rounded-full relative transition-all duration-600 s after:bg-gray-400 `} >
                <div className={`rounded-full border-4 w-[4rem] h-[4rem] flex justify-center items-center z-40 transition-all duration-800 border-[#3e6992] bg-[#487baa]`}>
                    <span className="text-white z-50">
                        {label}
                    </span>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ProgramSummaryCircle;
