import React, { useState } from "react";
import { motion } from "framer-motion";
import { MdContactMail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { updateUser } from "../../features/user";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import { Button } from "../Ui/button";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

const ProfileSettings = (sidebarOpen) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(localStorage.getItem("firstName"));
  const [lastName, setLastName] = useState(localStorage.getItem("lastName"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [email, setEmail] = useState(localStorage.getItem("email"));

  const [edit, setEdit] = useState(true);
  const openEdit = () => {
    setEdit(false);
  };
  const closeEdit = () => {
    setEdit(true);
  };

  const [showPassword, setShowPassword] = useState("password");
  const openShowPassword = () => {
    setShowPassword("text");
  };
  const closeShowPassword = () => {
    setShowPassword("password");
  };

  const updateProfile = async () => {
    const updatedUser = {
      user_id: localStorage.getItem("user_id"),
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      role: role,
      email: email,
    };
    console.log(updatedUser);
    const res = await dispatch(
      await updateUser({
        user_id: localStorage.getItem("user_id"),
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        role: role,
        email: email,
        deleted: false,
      })
    );
    if (res.payload) {
      localStorage.setItem("firstName", res.payload.firstName);
      localStorage.setItem("lastName", res.payload.lastName);
      localStorage.setItem("phone", res.payload.phone);
      localStorage.setItem("role", res.payload.role);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full flex flex-col items-center justify-center font-sans  "
    >
      <h2 className="font-sans text-lg mr-auto mb-12">PROFILE</h2>

      <div className="py-8 px-96 w-full">
        <div className="header top-0 h-[4rem]  shadow border-b-2 border-gray-200 bg-gray-50 flex items-center p-8 rounded">
          {localStorage.getItem("user_id") && (
            <div className="border border-light-green-reef rounded-full p-1 shadow">
              <span className="  w-fit rounded-xl my-6 font-bold text-black">{`${localStorage
                .getItem("firstName")[0]
                .toUpperCase()} ${localStorage
                .getItem("lastName")[0]
                .toUpperCase()}`}</span>
            </div>
          )}

          {localStorage.getItem("role") === "ADMIN" && (
            <h2 className="w-fit  bg-ligh-gray-50 text-black  px-4 tracking-wide font-sans h-fit">
              Admin
            </h2>
          )}
          <div className="flex justify-center p-4">
            {!edit && (
              <div className="flex ">
                <Button
                  variant="outline"
                  className="bg-gray-500 text-white rounded"
                  onClick={() => {
                    closeEdit();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outline"
                  className="bg-green-reef text-white rounded"
                  onClick={() => {
                    closeEdit();
                    updateProfile();
                  }}
                >
                  Save
                </Button>
              </div>
            )}
            {edit && (
              <Button
                variant="outline"
                className="bg-blue-reef text-white rounded"
                onClick={() => {
                  openEdit();
                }}
              >
                Edit
              </Button>
            )}
          </div>
          <div className="ml-auto text-gray-700">
            {edit ? <FaLock size="18" /> : <FaLockOpen size="20" />}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="p-10 lg:px-32  shadow flex flex-col gap-4 items-center bg-white w-full">
            <div className="flex mr-auto gap-2 text-blue-reef ">
              <MdContactMail size="24" />
              <h3 className="text-lg tracking-wide ">Contact Information</h3>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col items-start">
                  <div className="lg:flex w-full gap-4">
                    <div className="flex flex-col w-full">
                      <label>First Name</label>
                      <input
                        type="text"
                        className={`border border-gray-200  w-full h-[2.5rem] rounded ${
                          edit && "text-gray-300"
                        }`}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        defaultValue={firstName}
                        disabled={edit}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label>Last Name</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        className={`border border-gray-200  w-full h-[2.5rem] rounded ${
                          edit && "text-gray-300"
                        }`}
                        defaultValue={lastName}
                        disabled={edit}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col items-start">
                  <label>Phone</label>
                  <InputMask
                    type="text"
                    mask="(999) 999-9999"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    className={`border border-gray-200  w-full h-[2.5rem] ${
                      edit && "text-gray-300"
                    }`}
                    defaultValue={phone}
                    disabled={edit}
                  />
                </div>
                <select
                  name="role"
                  id="role"
                  className={` text-black  border border-gray-200 mt-[1.4rem] w-full h-[2.5rem] rounded ${
                    edit && "text-gray-300"
                  }`}
                  disabled={edit}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  <option defaultValue>Select user permissions</option>
                  <option value="ADMIN">Admin</option>
                  <option value="CUSTOMER">Customer</option>
                </select>
              </div>
            </div>
          </div>
          {/* <hr /> */}
          <div className="p-10 lg:px-32 flex flex-col items-center gap-4 shadow bg-white w-full rounded">
            <div className="flex items-center gap-2 text-green-500 mr-auto ">
              <FaUser size="24" />
              <h3 className="text-lg">Account</h3>
            </div>
            <div className="sm:block md:flex justify-start gap-4 w-full">
              <div className="flex flex-col gap-5 w-full">
                <div className="flex flex-col  ">
                  <label>Email/Username</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setEmail();
                    }}
                    className={`border border-gray-200  h-[2.5rem] w-full rounded ${
                      edit && "text-gray-300"
                    }`}
                    defaultValue={email}
                    disabled={edit}
                  />
                </div>
                <div className="flex flex-col justify-center gap-4">
                  <div className="flex flex-col items-start">
                    <label>Password</label>
                    <input
                      type={showPassword}
                      className="border border-gray-200  h-[2.5rem] w-full rounded"
                      disabled={edit}
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label>Confirm Password</label>
                    <input
                      type={showPassword}
                      className="border border-gray-200  h-[2.5rem] w-full rounded"
                      disabled={edit}
                    />
                  </div>
                  <div
                    className={`show-password flex gap-2 items-center ${
                      edit && "text-gray-300"
                    }`}
                  >
                    <label>show password</label>
                    <input
                      type="checkbox"
                      className="mr-auto"
                      value={showPassword === "text" ? false : true}
                      onChange={() => {
                        showPassword === "text"
                          ? closeShowPassword()
                          : openShowPassword();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProfileSettings;
