import React, { useState } from "react";
import Backdrop from "../Misc/Backdrop";
import RegisterContact from "../User/RegisterContact";
import RegisterAccount from "../User/RegisterAccount";

const AddCustomerModal = ({
  openAddProperty,
  closeAddProperty,
  closeAddCustomer,
}) => {
  const [showRegisterAccount, setShowRegisterAccount] = useState(false);
  const openShowRegisterAccount = () => {
    setShowRegisterAccount(true);
  };
  const closeShowRegisterAccount = () => {
    setShowRegisterAccount(false);
  };
  return (
    <Backdrop onClick={closeAddCustomer}>
      {!showRegisterAccount && (
        <RegisterContact
          props={{
            closeShowRegister: closeAddCustomer,
            openAccount: openShowRegisterAccount,
          }}
        />
      )}
      {showRegisterAccount && (
        <RegisterAccount
          props={{ closeShowRegister: closeAddCustomer, type: "internal" }}
        />
      )}
    </Backdrop>
  );
};

export default AddCustomerModal;
