import React, { useState, useEffect } from "react";
import Backdrop from "../Misc/Backdrop";
import StatusBar from "./StatusBar";
import CommunicationHistory from "./CommunicationHistory";
import AssociatedLocationTable from "./AssociatedLocationTable";
import { selectAllCustomers } from "../../features/customer";
import { selectAllUsers } from "../../features/user";
import {
  selectAllValues,
  fetchApplicationValues,
  deleteApplicationValue,
  updateApplicationValue,
  addApplicationValue,
} from "../../features/applicationValue";
import { selectAllFields } from "../../features/programField";
import { selectStatusList, fetchStatusLogs } from "../../features/status";
import { selectAllPrograms } from "../../features/program";
import { formatAddress } from "../../utils/formatAddress";
import DeleteModal from "../Misc/DeleteModal";
import { BsFillTrashFill } from "react-icons/bs";
import { Button } from "../Ui/button";
import HashLoader from "react-spinners/HashLoader";

import {
  selectAllApplications,
  fetchApplications,
} from "../../features/application";
import {
  selectAllProperties,
  fetchProperties,
} from "../../features/properties";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStatusHistory } from "./ApplicationStatusHistory";
import { addWaterSavings } from "../../features/waterSavingsLog";
import { addStatusLog } from "../../features/status";
import { setToast } from "../../utils/ToastNotification";
import { addReviewNote } from "../../features/reviewNotes";
import {
  fetchApplicationProperties,
  selectAllAppProperties,
} from "../../features/applicationProperties";
import CurrencyInput from "react-currency-input-field";

import {
  updateApplicationStatus,
  fetchApplicationStatus,
} from "../../features/applicationStatus";

import { IoIosAdd } from "react-icons/io";

import { selectAllUploads } from "../../features/programUpload";
import InputMask from "react-input-mask";

import { BsShieldExclamation } from "react-icons/bs";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

axios.defaults.headers.common["Client"] = "ACWD";

const UpdateApplicationModal = ({ props }) => {
  const dispatch = useDispatch();

  const fieldList = useSelector(selectAllFields);
  const [fields, setFields] = useState(null);
  const [uploads, setUploads] = useState(null);

  const customerList = useSelector(selectAllCustomers);
  const [customer, setCustomer] = useState(null);

  const valueList = useSelector(selectAllValues);
  const [values, setValues] = useState(null);

  const [showDeleteFile, setShowDeleteFile] = useState(null);
  const hideShowDeleteFile = () => {
    setShowDeleteFile(null);
  };
  const [deleteFileObj, setDeleteFileObj] = useState(null);

  const [program, setProgram] = useState(null);
  const programList = useSelector(selectAllPrograms);

  const [status, setStatus] = useState(null);
  const statusList = useSelector(selectStatusList);

  const applicationList = useSelector(selectAllApplications);

  const [property, setProperty] = useState(null);
  const propertyList = useSelector(selectAllProperties);

  const [statusBarList, setStatusBarList] = useState(null);

  const [documentList, setDocumentList] = useState([]);

  const userList = useSelector(selectAllUsers);

  const uploadList = useSelector(selectAllUploads);

  const [modalMenu, setModalMenu] = useState({
    menu: [true, false, false],
    setMenu: (payload) => {
      const temp = [false, false, false];
      temp[payload] = true;
      setModalMenu((prev) => ({
        ...prev,
        menu: temp,
      }));
    },
  });

  const getDocumentList = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/fetch-by-type`
          : `http://localhost:3000/reference/fetch-by-type`;

      const res = await axios.post(
        url,
        {
          referenceId: props.modalController.modalAppId,
          referenceType: "application",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setDocumentList(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDocumentList();
  }, [props.modalController.modalAppId]);

  const appPropertyList = useSelector(selectAllAppProperties);
  const [appProperties, setAppProperties] = useState(null);
  //filter propreties by app id
  useEffect(() => {
    if (appPropertyList) {
      const temp = appPropertyList?.filter((el) => {
        return el.applicationId === props.modalController.modalAppId;
      });
      setAppProperties(temp);
    }
  }, [appPropertyList]);

  //filter status by logic
  //1. Only render mile stones || none milestone if current status lands on non milestone
  useEffect(() => {
    if (applicationList && programList && statusList && status) {
      const application = applicationList?.filter((app) => {
        return app?.id === props.modalController.modalAppId;
      });

      const program = programList?.filter((program) => {
        return program?.id === application[0]?.programId;
      });

      const status = statusList?.filter((status) => {
        return status?.programId === program[0]?.id;
      });

      const milestones = status?.filter((el) => {
        return el.milestone === true;
      });

      setStatus(status);
    }
  }, [statusList]);

  //filter properties by application propertyID
  useEffect(() => {
    if (applicationList) {
      const temp = applicationList?.filter((app) => {
        return app?.id === props.modalController.modalAppId;
      });

      const propertyId = temp[0]?.propertyId;
      if (propertyList) {
        const property = propertyList?.filter((property) => {
          return property?.id === propertyId;
        });
        setProperty(property[0]);
      }
    }
  }, [applicationList, propertyList]);

  const [updatedApplication, setUpdatedApplication] = useState({
    defaultUnitWaterSavings: null,
    cost: null,
  });

  //filter program by application programId
  useEffect(() => {
    if (applicationList) {
      const appTemp = applicationList?.filter((app) => {
        return app?.id === props.modalController.modalAppId;
      });
      const programId = appTemp[0]?.programId;
      if (programList) {
        const temp = programList?.filter((program) => {
          return program?.id === programId;
        });
        setProgram(temp[0]);
      }
    }
  }, [applicationList]);

  const [appData, setAppData] = useState(null);
  const [cost, setCost] = useState(null);
  const [grantFund, setGrantFund] = useState(null);
  const [appWaterSavings, setAppWaterSavings] = useState(null);
  useEffect(() => {
    console.log("appWaterSavings: ", appWaterSavings);
  }, [appWaterSavings]);
  useEffect(() => {
    const appTemp = applicationList?.filter((app) => {
      return app?.id === props.modalController.modalAppId;
    });
    const app = appTemp[0];
    setAppData(app);

    setCost(app.cost);
    setGrantFund(app.grantFundedAmount);
  }, [statusList, statusBarList]);
  //add back
  useEffect(() => {
    if (applicationList) {
      const appTemp = applicationList?.filter((app) => {
        return app?.id === props.modalController.modalAppId;
      });
      const programId = appTemp[0]?.programId;
      if (statusList) {
        const temp = statusList?.filter((status) => {
          return status?.programId === programId;
        });
        setStatus(temp);
      }
    }
  }, [statusList, statusBarList]);

  useEffect(() => {
    if (customerList) {
      const temp = customerList?.filter((customer) => {
        return customer?.id === props.modalController.modalCustomerId;
      });
      if (customerList?.length > 0) {
        setCustomer(temp[0]);
      }
    }
  });

  const [curFields, setCurFields] = useState(null);
  const [ogValues, setOgValues] = useState(null);

  const [checkValues, setCheckValues] = useState(null);
  const [initCheckValues, setInitCheckValues] = useState(null);
  const [checkApplicationValues, setCheckApplicationValues] = useState(null);
  const [applicationValues, setApplicationValues] = useState(null);

  const [lookupSearch, setLookupSearch] = useState("");
  const [lookupOptions, setLookupOptions] = useState(null);
  const [searchList, setSearchList] = useState(null);
  const [myList, setMylist] = useState([]);
  const [myListFieldId, setMyListFieldId] = useState(null);
  const [useMultiplier, setUseMultiplier] = useState(false);
  const [deleteList, setDeleteList] = useState([]);
  const [addList, setAddList] = useState([]);

  const [sumInputLabel, setSumInputLabel] = useState(0);
  const [newItem, setNewItem] = useState({
    lookupName1: "",
    lookupName2: "",
    inputLabel: "",
    multiplier: "",
  });

  const handleCheckboxChange = async (e, id) => {
    setCheckValues((prev) => ({
      ...prev,
      [id]: e.target.checked,
    }));
  };

  const deleteFromMyList = (index, id) => {
    let tempDelete = [...deleteList];
    let temp = [...myList];

    tempDelete.push(id);
    setDeleteList(tempDelete);

    if (
      temp?.filter((y) => {
        return y.index === index;
      })?.length > 0
    ) {
      const removeIndex = temp.findIndex((obj) => obj.id === index);

      temp.splice(removeIndex, 1);
      setMylist([...temp]);
    } else {
      const toastPayload = {
        index: 2,
        description: 16,
        type: "application",
      };
      setToast(toastPayload);
    }
  };

  const generateList = () => {
    if (
      lookupOptions &&
      lookupOptions?.length > 0 &&
      lookupSearch?.length > 0
    ) {
      const temp = lookupOptions?.filter((lo) => {
        return (
          lo["lookupName1"]
            .toLowerCase()
            .includes(lookupSearch.toLowerCase()) ||
          lo["lookupName2"].toLowerCase().includes(lookupSearch.toLowerCase())
        );
      });
      if (temp?.length > 0) {
        setSearchList([...temp]);
      } else {
        setSearchList(0);
      }
    }
  };

  const addToMyList = (payload) => {
    const toAdd = [...addList];
    const newList = [...myList];
    const index = myList?.length;

    if (payload) {
      const tempObj = {
        id: index,
        index: index,
        lookupName1: payload.lookupName1,
        lookupName2: payload.lookupName2,
        inputLabel: payload.inputLabel,
        multiplier: payload.multiplier,
      };
      newList.push(tempObj);
      console.log("MYLISTPUSH: ", newList);
      setMylist([...newList]);
      toAdd.push(tempObj);
      setAddList([...toAdd]);
    } else {
      const tempObj = {
        id: index,
        index: index,
        lookupName1: newItem.lookupName1,
        lookupName2: newItem.lookupName2,
        inputLabel: newItem.inputLabel,
        multiplier: newItem.multiplier,
      };
      newList.push(tempObj);
      setMylist(newList);
      toAdd.push(tempObj);
      setAddList([...toAdd]);
    }
  };

  const setLookupInput = (lo, e) => {
    const temp = [...lookupOptions];

    temp.forEach((x) => {
      if (
        x.lookupName1 === lo.lookupName1 &&
        x.lookupName2 === lo.lookupName2
      ) {
        x.inputLabel = e.target.value;
      }
    });
    setLookupOptions([...temp]);
  };

  useEffect(() => {
    let total = 0;
    let sumString = "";
    if (myList?.length > 0) {
      if (!useMultiplier) {
        myList?.forEach((x, index) => {
          total += parseInt(x.inputLabel) * 1;
          if (index !== myList?.length - 1) {
            sumString += ` [${x.inputLabel} * 1] +`;
          } else {
            sumString += ` [${x.inputLabel} * 1]`;
          }
        });
      } else {
        myList?.forEach((x, index) => {
          total += parseInt(x.inputLabel) * parseInt(x.multiplier);
          if (index !== myList?.length - 1) {
            sumString += ` [${x.inputLabel} * ${x.multiplier}] +`;
          } else {
            sumString += ` [${x.inputLabel} * ${x.multiplier}]`;
          }
        });
      }
    }
    setSumInputLabel(total);
    console.log("SUMSTRING: ", sumString);
  }, [myList]);

  useEffect(() => {
    if (valueList) {
      let curApp = applicationList?.filter((x) => {
        return x?.id === props.modalController.modalAppId;
      })[0];
      let curProg = curApp?.programId;
      const currFields = fieldList?.filter((x) => {
        return x?.programId === curProg;
      });

      const orderedCurrFields = currFields.sort((a, b) => {
        return a.applicationOrder - b.applicationOrder;
      });

      console.log("ordered curr fields: ", orderedCurrFields);
      setCurFields(orderedCurrFields);
      const currVals = orderedCurrFields?.map((x) => {
        const valList = valueList?.filter((y) => {
          return (
            y?.fieldId === x?.id &&
            y?.applicationId === props.modalController.modalAppId &&
            !y?.checkValueId
          );
        });

        return valList?.length > 0
          ? valList?.reduce((a, b) => {
              return a.createdAt > b.createdAt ? a : b;
            })
          : null;
      });

      const valArray = {};

      orderedCurrFields?.map((x) => {
        const val = currVals.filter((y) => {
          if (y) {
            return y?.fieldId === x?.id;
          }
          return null;
        });

        if (val?.length > 0) {
          valArray[x?.id] = val[0]?.value;
        } else {
          valArray[x?.id] = null;
        }
        return 0;
      });

      setValues(valArray);
      setOgValues(valArray);
    }
  }, [
    valueList,
    fieldList,
    props.modalController,
    programList,
    applicationList,
  ]);

  useEffect(() => {
    //checkbox values
    const allApplicationValues = valueList?.filter((value) => {
      return value.applicationId === props.modalController.modalAppId;
    });
    console.log("valueList: ", valueList);

    const applicationValues = allApplicationValues?.filter((x) => {
      return !x.checkValueId;
    });

    console.log("all application values: ", allApplicationValues);

    let curApp = applicationList?.filter((x) => {
      return x?.id === props.modalController.modalAppId;
    })[0];
    let curProg = curApp?.programId;
    const currFields = fieldList?.filter((x) => {
      return x?.programId === curProg;
    });

    console.log("modal app id: ", props.modalController.modalAppId);
    const checkApplicationValues = valueList
      ?.filter((value) => {
        return value.applicationId === props.modalController.modalAppId;
      })
      ?.filter((x) => {
        return x.checkValueId;
      });
    console.log("check-application-values: ", checkApplicationValues);
    checkApplicationValues
      ? setCheckApplicationValues([...checkApplicationValues])
      : setCheckApplicationValues([]);
    const tempCheck = {};

    currFields?.forEach((field) => {
      if (field.fieldType === "CHECK" && field.checkValues?.length > 0) {
        field.checkValues.forEach((val) => {
          const cav = checkApplicationValues?.filter((x) => {
            return x.fieldId === field.id && x.checkValueId === val.id;
          })[0];
          if (cav) {
            tempCheck[`${cav.id}-${cav.fieldId}`] =
              cav.value === "true" ? true : false;
          }
        });
      } else if (
        field.fieldType === "LOOKUP" &&
        field.lookupOptions?.length > 0
      ) {
        const cav = applicationValues?.filter((x) => {
          return x.fieldId === field.id;
        });
        let listArr = [];
        cav?.forEach((x, index) => {
          const temp = {
            valueId: x.id,
            id: index,
            index: index,
            lookupName1: x.lookupName1,
            lookupName2: x.lookupName2,
            inputLabel: x.value,
            multiplier: x.multiplier,
          };

          listArr.push(temp);
        });

        setMylist(listArr);
        setMyListFieldId(field.lookupOptions[0].fieldId);

        const lookupTemp = [];

        field.lookupOptions.map((lo) => {
          const tempObj = {};
          tempObj["id"] = lo.id;
          tempObj["fieldId"] = lo.fieldId;
          tempObj["lookupName1"] = lo.lookupName1;
          tempObj["lookupName2"] = lo.lookupName2;
          tempObj["multiplier"] = lo.multiplier;
          tempObj["inputLabel"] = "";
          lookupTemp.push(tempObj);
        });

        setLookupOptions([...lookupTemp]);
        // console.log(
        //   "USEMULTIPLIER: ",
        //   field.lookupCharacteristics.useMultiplier
        // );
        setUseMultiplier(field.lookupCharacteristics.useMultiplier);
      }
    });

    console.log("TEMPCHECK: ", tempCheck);
    setCheckValues({ ...tempCheck });
    setInitCheckValues({ ...tempCheck });

    const latestValues = [];
    const latestSet = {};
    for (let i = 0; i < applicationValues?.length; i++) {
      latestSet[applicationValues[i].fieldId] = applicationValues[i];
    }
    for (const [key, value] of Object.entries(latestSet)) {
      latestValues.push(value);
    }
    console.log("LATESTVALUES: ", latestValues);
    setApplicationValues([...latestValues]);
    /////
  }, []);

  useEffect(() => {
    if (uploadList) {
      let curApp = applicationList?.filter((x) => {
        return x?.id == props.modalController.modalAppId;
      })[0];
      let curProg = curApp?.programId;
      let curUploads = uploadList
        ?.filter((x) => {
          return x?.programId == curProg;
        })
        .map((x) => {
          let targetDoc = documentList?.filter((y) => y?.secondaryId == x?.id);
          if (targetDoc)
            return targetDoc.map((tg) => {
              return (
                <div className="flex flex-col w-full gap-1">
                  <label htmlFor="" className="">
                    {x.name}
                  </label>
                  <span
                    className="text-blue-reef underline cursor-pointer"
                    onClick={() => {
                      downloadFile(tg.fileLocation);
                    }}
                  >
                    {tg.fileName}
                  </span>
                </div>
              );
            });
          else
            return (
              <div className="flex flex-col w-full gap-1">
                <label htmlFor="" className="">
                  {x.name}
                </label>
                <span>No document uploaded.</span>
              </div>
            );
        });

      setUploads(curUploads);
    }
  }, [
    valueList,
    uploadList,
    documentList,
    props.modalController,
    programList,
    applicationList,
  ]);

  const [waterSavings, setWaterSavings] = useState(null);
  const getWaterSavings = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/water-savings/fetch-by-id`
          : `http://localhost:3000/application/water-savings/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.modalController.modalAppId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const water_savings = res.data.data;
      if (water_savings?.length > 1) {
        water_savings.sort((a, b) => {
          return a.id - b.id;
        });
        setWaterSavings(water_savings);
        setAppWaterSavings(water_savings.at(-1).value);
        setUpdatedApplication((prev) => ({
          ...prev,
          defaultUnitWaterSavings: parseInt(water_savings.at(-1).value),
        }));
      } else if (water_savings?.length === 1) {
        setWaterSavings(water_savings);
        setAppWaterSavings(water_savings[0].value);

        setUpdatedApplication((prev) => ({
          ...prev,
          defaultUnitWaterSavings: parseInt(water_savings[0].value),
        }));
      } else {
        setWaterSavings(null);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCommunicationLogs();
  }, [props]);

  const [statusLogs, setStatusLogs] = useState(null);
  const getStatusLogs = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/status/status-log/fetch-by-id`
          : `http://localhost:3000/status/status-log/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.modalController.modalAppId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const status_logs = res.data.data;
      if (status_logs?.length > 0) {
        status_logs.sort((a, b) => {
          return b.id - a.id;
        });
        setStatusLogs(status_logs);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWaterSavings();
    getStatusLogs();
  }, [props]);

  const [communicationHistory, setCommunicationHistory] = useState(null);
  const getCommunicationLogs = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/communication-history/fetch-by-id`
          : `http://localhost:3000/application/communication-history/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.modalController.modalAppId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const communication_log = res.data.data;

      setCommunicationHistory(communication_log);
    } catch (err) {
      console.log(err);
    }
  };

  const [load, setLoad] = useState(false);

  useEffect(() => {
    console.log("VALUES: ", values);
  }, [values]);
  const updateValues = async (payload) => {
    try {
      console.log("OG VALUES: ", ogValues);
      console.log("Updating values: ", values);

      for (const [key, value] of Object.entries(values)) {
        if (value !== ogValues[key]) {
          const res = await dispatch(
            addApplicationValue({
              applicationId: payload,
              fieldId: parseInt(key),
              value: value,
              checkValueId: null,
              multiplier: null,
              lookupName1: null,
              lookupName2: null,
              calcResult: null,
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateApplication = async () => {
    try {
      console.log("checkValues: ", checkValues);

      setLoad(true);
      if (reviewNotes !== initReviewNote) {
        await dispatch(
          addReviewNote({
            value: reviewNotes,
            userId: parseInt(localStorage.getItem("user_id")),
            applicationId: props.modalController.modalAppId,
          })
        );

        getReviewNotes();
      }
      if (
        updatedApplication?.defaultUnitWaterSavings &&
        updatedApplication?.defaultUnitWaterSavings !==
          waterSavings?.at(-1).value
      ) {
        const res = await dispatch(
          addWaterSavings({
            value: updatedApplication?.defaultUnitWaterSavings,
            userId: parseInt(localStorage.getItem("user_id")),
            comment: "",
            applicationId: props.modalController.modalAppId,
            admin: true,
          })
        );
      }

      if (statusName || statusNumber || asignee) {
        const status = {
          statusName: statusName,
          statusNumber: statusNumber,
          userId: parseInt(localStorage.getItem("user_id")),
          comment: "",
          applicationId: props.modalController.modalAppId,
          staffAssignmentId: asignee,
        };

        //add status log
        await dispatch(addStatusLog(status));
        await dispatch(fetchStatusLogs());
        //update application stuatus

        if (statusName || statusNumber) {
          await dispatch(updateApplicationStatus(statusObj));
          await dispatch(fetchApplicationStatus());
        }
        if (asignee) {
          const email = userList?.filter((x) => {
            return x?.id === parseInt(asignee);
          })[0].email;
          const programId = applicationList?.filter((x) => {
            return x?.id === props.modalController.modalAppId;
          })[0]?.programId;
          if (process.env.REACT_APP_PRODUCTION === "true") {
            const res = await axios.post(
              `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
              {
                subject: `Application ${props.modalController.modalAppId} (${
                  programList?.filter((x) => {
                    return x?.id === programId;
                  })[0].programName
                }) has been assigned to you in the Water Savings Center `,
                body: `Application ${props.modalController.modalAppId} (${
                  programList?.filter((x) => {
                    return x?.id === programId;
                  })[0].programName
                }) has been assigned to you in the Water Savings Center `,
                to: [`${email}`],
                fromName: "ACWD Water Savings Center",
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
          }
        }
      }

      if (parseFloat(cost) !== appData.cost && cost !== null) {
        //update application with cost
        const url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/application/cost/update`
            : `http://localhost:3000/application/cost/update`;
        await axios.post(
          url,
          { id: props.modalController.modalAppId, cost: parseFloat(cost) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      if (
        parseFloat(grantFund) !== appData.grantFundedAmount &&
        grantFund !== null
      ) {
        //update application with cost
        const url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/application/grant-fund/update`
            : `http://localhost:3000/application/grant-fund/update`;

        await axios.post(
          url,
          {
            id: props.modalController.modalAppId,
            grantFundedAmount: parseFloat(grantFund),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }

      if (
        parseInt(appWaterSavings) !== appData?.waterSavings &&
        appWaterSavings !== null
      ) {
        const url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/water-savings/update`
            : `http://localhost:3000/water-savings/update`;
        await axios.post(
          url,
          {
            id: props.modalController.modalAppId,
            waterSavings: parseInt(appWaterSavings),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }

      for (const [key, value] of Object.entries(checkValues)) {
        if (initCheckValues[key] !== value) {
          const valueInfo = {
            id: key.split("-")[0],
            value: value,
          };
          await dispatch(updateApplicationValue(valueInfo));
        }
      }

      if (deleteList?.length > 0) {
        deleteList?.forEach(async (x) => {
          await dispatch(deleteApplicationValue({ id: x }));
          await dispatch(fetchApplicationValues()); //not updating in time tmp fix in forloop
        });
      }

      if (addList?.length > 0) {
        addList?.forEach(async (x) => {
          const tempObj = {
            applicationId: props.modalController.modalAppId,
            fieldId: myListFieldId,
            multiplier: x.multiplier ? x.multiplier : 1,
            lookupName1: x.lookupName1,
            lookupName2: x.lookupName2,
            calcResult: useMultiplier
              ? x.multiplier * parseInt(x.inputLabel)
              : parseInt(x.inputLabel),
            value: x.inputLabel,
            checkValueId: null,
          };
          await dispatch(addApplicationValue(tempObj));
          await dispatch(fetchApplicationValues()); //not updating in time tmp fix in forloop
        });
      }

      await updateValues(props.modalController.modalAppId);

      await dispatch(fetchApplications());
      await dispatch(fetchApplicationValues());
      setLoad(false);
      props.update();
      const toastPayload = {
        index: 0,
        description: 2,
        type: "application",
      };
      setToast(toastPayload);
      props.modalController.closeAppModal();
    } catch (err) {
      console.log(err);
    }
  };

  function isAllowedFile(file) {
    const fileName = file.name.toLowerCase();
    const extension = fileName
      .substring(fileName.lastIndexOf("."))
      .toLowerCase();
    const notAllowedExtensions = [".exe"];
    const notAllowedMimeTypes = ["video/*"];

    if (notAllowedExtensions.includes(extension)) {
      return false;
    }

    if (notAllowedMimeTypes.some((type) => file.type.startsWith(type))) {
      return false;
    }

    return true;
  }

  const uploadDocument = async (input) => {
    const formData = new FormData(); //referenceId, fileName, fileLocation, uploader, referenceType
    formData.append("file", input.file);
    formData.append("appId", input.appId);
    formData.append("fileName", input.file.fileName);
    formData.append("referenceId", input.appId);
    formData.append("referenceType", "application");
    formData.append("uploader", localStorage.getItem("user_id"));

    // upload the document
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/upload`
          : `http://localhost:3000/blobstorage/upload`;
      const res = await axios.post(
        url,
        //`http://localhost:3000/blobstorage/upload`,
        formData,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      formData.append("fileLocation", res.data.name);

      setDocumentLink(res.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const setDocumentLink = async (data) => {
    // add the links to the document
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/add`
          : `http://localhost:3000/reference/add`;

      const res = await axios.post(
        url,
        {
          referenceId: props.modalController.modalAppId,
          referenceType: "application",
          secondaryId: null,
          fileName: data.name,
          fileLocation: data.name,
          uploader: localStorage.getItem("user_id"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      getDocumentList();
    } catch (err) {
      console.log(err);
    }

    // update the document list
  };

  const downloadFile = async (input) => {
    let tmpObj = {
      fileName: input,
    };
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/download`
          : `http://localhost:3000/blobstorage/download`;

      const res = await axios.post(url, tmpObj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "arraybuffer",
      });

      createDownload(res.data, input);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const createDownload = (byteStream, filename) => {
    const blob = new Blob([byteStream], { type: "application/octet-stream" });
    const blobUrl = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();

    // Remove the anchor from the DOM
    document.body.removeChild(anchor);

    // Clean up the URL
    URL.revokeObjectURL(blobUrl);
  };

  const deleteFile = async (input) => {
    console.log("Deleting file.");
    console.log(input);
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/delete`
          : `http://localhost:3000/reference/delete`;

      const res = await axios.post(
        url,
        {
          id: input.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      getDocumentList();
      hideShowDeleteFile();
    } catch (err) {
      console.log(err);
    }
  };

  //set status
  const [statusObj, setStatusObj] = useState(null);

  const setObj = (statusObj) => {
    setStatusObj(statusObj);
  };
  const [statusName, setStatusName] = useState(null); // create a default status name
  const addStatusName = (status) => {
    setStatusName(status);
  };
  const [statusNumber, setStatusNumber] = useState(null); //create a default status number
  const addStatusNumber = (status) => {
    setStatusNumber(status);
  };
  const [asignee, setAsignee] = useState(null);
  const addAsignee = (id) => {
    setAsignee(id);
  };

  const validateUpdate = (el) => {
    if (el) {
      return true;
    } else {
      const toastPayload = {
        index: 1,
        description: 10,
        type: "application",
      };
      setToast(toastPayload);
      return false;
    }
  };

  //Internal Review Notes
  const [reviewNotes, setReviewNotes] = useState(null);
  const [initReviewNote, setInitReviewNote] = useState(null);

  const getReviewNotes = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/review-note/fetch-by-id`
          : `http://localhost:3000/application/review-note/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.modalController.modalAppId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const review_notes = res.data.data;
      review_notes.sort((a, b) => {
        return a.id - b.id;
      });

      if (review_notes?.length > 0) {
        setReviewNotes(review_notes.at(-1).value);
        setInitReviewNote(review_notes.at(-1).value);
      } else {
        setReviewNotes("");
        setInitReviewNote("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getReviewNotes();
  }, [props]);

  //associated locations
  const [alInput, setAlInput] = useState(false);
  const toggleAltInput = () => {
    setAlInput(!alInput);
  };

  const [locationId, setLocationId] = useState("");

  const addLocation = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/account/location/fetch-by-id`
          : `http://localhost:3000/account/location/fetch-by-id`;

      await axios.post(
        url,
        {
          id: parseInt(locationId),
          applicationId: props.modalController.modalAppId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      toggleAltInput();

      await dispatch(fetchApplicationProperties());
      await dispatch(fetchProperties());
    } catch (err) {
      console.log(err);
      const toastPayload = {
        index: 1,
        description: 9,
        type: "application",
      };
      setToast(toastPayload);
    }
  };

  return (
    <Backdrop
      onClick={() => {
        props.modalController.openWarningModal();
      }}
    >
      <div
        className="w-[80rem] h-[50rem] overflow-auto bg-white text-black rounded shadow flex flex-col relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center sticky top-0 w-full border border-gray-200 rounded-t z-50 bg-white">
          <div
            className={` px-2 py-2 cursor-pointer flex justify-center w-full rounded-l border-r border-gray-200 ${
              modalMenu.menu[0] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              modalMenu.setMenu(0);
            }}
          >
            <span className="tracking-wider">Application Details</span>
          </div>
          <div
            className={` px-2 py-2 cursor-pointer flex w-full justify-center border-r bg-white border-gray-200 ${
              modalMenu.menu[1] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              modalMenu.setMenu(1);
            }}
          >
            <span className="tracking-wider ">Communication History</span>
          </div>
          <div
            className={`px-2 py-2 cursor-pointer flex w-full justify-center rounded-r bg-white  ${
              modalMenu.menu[2] ? "!bg-blue-reef text-white" : ""
            }`}
            onClick={() => {
              modalMenu.setMenu(2);
            }}
          >
            <span className="tracking-wider">Status History</span>
          </div>
        </div>
        {!load ? (
          <>
            {modalMenu.menu[0] && (
              <div className="application-details flex flex-col gap-6 p-5 overflow-auto">
                {status && (
                  <div className="rounded bg-gray-50">
                    <StatusBar
                      props={{
                        statusList: status,
                        customer: false,
                        appId: props.modalController.modalAppId,
                        saveStatus: setObj,
                        addStatusName: addStatusName,
                        addStatusNumber: addStatusNumber,
                        addAsignee: addAsignee,
                        type: "ADMIN",
                        program: program,
                      }}
                    />
                  </div>
                )}

                <div className="bg-gray-50 p-4 flex flex-col gap-4 shadow rounded">
                  {
                    <>
                      <div className="flex gap-4">
                        <div className="flex flex-col w-full">
                          <h2 className="tracking-wide font-bold text-blue-reef">
                            Internal Application Fields
                          </h2>
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex flex-col w-full">
                          <span>Water Savings(gals per year)</span>

                          <CurrencyInput
                            id="input-example"
                            name="input-name"
                            className="w-full border-gray-200 border-2 rounded h-[2.5rem] dark:bg-gray-600 pl-2"
                            value={
                              appWaterSavings
                                ? appWaterSavings
                                    .toString()
                                    .replace(/[!,]/g, "")
                                : ""
                            }
                            onChange={(e) => {
                              setUpdatedApplication((prev) => ({
                                ...prev,
                                defaultUnitWaterSavings: e.target.value
                                  ? e.target.value
                                      .toString()
                                      .replace(/[!,]/g, "")
                                  : "",
                              }));
                              ////
                              setAppWaterSavings(
                                e.target.value.toString().replace(/[!,]/g, "")
                              );
                            }}
                            allowDecimals={true}
                            decimalsLimit={2}
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <span>Cost</span>
                          <div className="flex items-center gap-1">
                            <span>$</span>
                            <CurrencyInput
                              id="input-example"
                              name="input-name"
                              className="w-full border-gray-200 border-2 rounded h-[2.5rem] dark:bg-gray-600 pl-2"
                              value={
                                cost
                                  ? cost?.toString().replace(/[!,]/g, "")
                                  : ""
                              }
                              onChange={(e) => {
                                setCost(
                                  e.target.value.toString().replace(/[!,]/g, "")
                                );
                              }}
                              allowDecimals={true}
                              decimalsLimit={2}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <span>Grant Funded Amount</span>
                          <div className="flex items-center gap-1">
                            <span>$</span>
                            <CurrencyInput
                              id="input-example"
                              name="input-name"
                              className="w-full border-gray-200 border-2 rounded h-[2.5rem] dark:bg-gray-600 pl-2"
                              value={
                                grantFund
                                  ? grantFund?.toString().replace(/[!,]/g, "")
                                  : ""
                              }
                              onChange={(e) => {
                                setGrantFund(
                                  e.target.value.toString().replace(/[!,]/g, "")
                                );
                              }}
                              allowDecimals={true}
                              decimalsLimit={2}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  <div>
                    <span
                      className="italic text-blue-400 cursor-pointer hover:underline"
                      onClick={() => {
                        props.waterSavingsController.openWaterSavingsModal();
                      }}
                    >
                      View history of water savings changes
                    </span>
                  </div>

                  {program && (reviewNotes || reviewNotes === "") && (
                    <div className="flex flex-col">
                      <span>Internal Review Notes</span>
                      <textarea
                        defaultValue={reviewNotes}
                        type="text"
                        className="border border-gray-200 h-[4.5rem] rounded p-2 text-black"
                        onChange={(e) => {
                          setReviewNotes(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="bg-gray-50 p-4 flex flex-col gap-4 shadow rounded">
                  <div>
                    <h2 className="tracking-wide font-bold text-blue-reef">
                      Applicant Information
                    </h2>
                    <hr />
                  </div>
                  {customer && customerList ? (
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-4">
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Customer ID</label>
                          <input
                            className="w-full border border-gray-200 h-[2.5rem] rounded"
                            defaultValue={customer?.id}
                            disabled
                            placeholder={"Customer ID"}
                          />
                        </div>
                        <div className="flex flex-col w-full ">
                          <label htmlFor="">First Name</label>
                          <input
                            className=" border border-gray-200 h-[2.5rem] rounded"
                            defaultValue={customer?.firstName}
                            disabled
                            placeholder={"First Name"}
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Last Name</label>
                          <input
                            className=" border border-gray-200 h-[2.5rem] rounded"
                            disabled
                            defaultValue={customer?.lastName}
                            placeholder={"Last Name"}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Email</label>
                          <input
                            className="w-full border border-gray-200 h-[2.5rem] rounded"
                            disabled
                            defaultValue={customer?.email}
                            placeholder={"Email"}
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Telephone</label>
                          <InputMask
                            mask="(999) 999-9999"
                            className="w-full border border-gray-200 h-[2.5rem] rounded"
                            disabled
                            defaultValue={customer?.phone}
                            placeholder={"Telephone"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>Admin</span>
                  )}
                </div>
                <div className="bg-gray-50 p-4 flex flex-col gap-4 shadow rounded">
                  <div>
                    <h2 className="tracking-wide font-bold text-blue-reef">
                      Property Information
                    </h2>
                    <hr />
                  </div>
                  {property && propertyList && (
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-4">
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Property ID</label>
                          <input
                            className="w-full border border-gray-200 h-[2.5rem] rounded"
                            placeholder={"Property ID"}
                            disabled
                            defaultValue={property?.id}
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Location ID</label>
                          <input
                            className="w-full border border-gray-200 h-[2.5rem] rounded"
                            placeholder={"Location ID"}
                            disabled
                            defaultValue={property?.location?.locationNo}
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <label htmlFor="">Location Class</label>
                          <input
                            className="w-full border border-gray-200 h-[2.5rem] rounded"
                            placeholder={"Location Class"}
                            disabled
                            defaultValue={property?.location?.locationClass}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex flex-col w-8/12">
                          <label htmlFor="">
                            Street{" "}
                            {property?.manualEntry && (
                              <span className="inline-block ml-4 text-orange-400 font-semibold">
                                <BsShieldExclamation
                                  size="16"
                                  className="inline-block mr-1"
                                />
                                This is a manually entered property address!
                              </span>
                            )}
                          </label>

                          <input
                            className="border border-gray-200 h-[2.5rem] rounded"
                            placeholder={"Street"}
                            disabled
                            defaultValue={
                              property?.manualEntry === null
                                ? formatAddress([
                                    property.location?.houseNo,
                                    property.location?.streetPfxDir,
                                    property.location?.streetNm,
                                    property.location?.streetNmSfx,
                                    property.location?.streetSfxDir,
                                    property.location?.secAddrId,
                                    property.location?.secAddRange,
                                  ])
                                : `${
                                    property.mfcStreetNo
                                      ? property.mfcStreetNo
                                      : ""
                                  } ${
                                    property.mfcStreetName
                                      ? property.mfcStreetName
                                      : ""
                                  }`
                            }
                          />
                        </div>
                        <div className="flex flex-col w-2/12">
                          <label htmlFor="">City</label>
                          <input
                            className=" border border-gray-200 h-[2.5rem] rounded"
                            placeholder={"City"}
                            disabled
                            defaultValue={property?.city || ""}
                          />
                        </div>
                        <div className="flex flex-col w-2/12">
                          <label htmlFor="">Zip</label>
                          <input
                            className="border border-gray-200 h-[2.5rem] rounded"
                            placeholder={"Zip"}
                            disabled
                            defaultValue={property?.postalCode || ""}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col items-start gap-4 shadow-md">
                    <div className="flex flex-col gap-1">
                      <button
                        className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                        onClick={() => {
                          toggleAltInput();
                        }}
                      >
                        Associate Additional Property With Application
                      </button>
                      {alInput && (
                        <div className="flex gap-2">
                          <input
                            className="w-full border border-gray-200 rounded"
                            placeholder=" Enter Location ID"
                            onChange={(e) => {
                              setLocationId(e.target.value);
                            }}
                          />
                          <button
                            className="text-white bg-green-reef hover:bg-green-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-green-400 dark:hover:bg-green-reef focus:outline-none dark:focus:ring-green-500"
                            onClick={addLocation}
                          >
                            Add
                          </button>
                        </div>
                      )}
                    </div>
                    {propertyList && appProperties ? (
                      <AssociatedLocationTable
                        props={{
                          appPropertyList: appProperties,
                          propertyList: propertyList,
                        }}
                      />
                    ) : (
                      <span>loading...</span>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 p-4 flex flex-col gap-4 shadow rounded">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col">
                      <span className="text-blue-reef font-bold tracking-wide">
                        Submitted Application
                      </span>
                      <span>
                        Internal-facing fields (hidden from customer) have a red
                        border.
                      </span>
                    </div>
                    <hr />
                    {values && Object.keys(values).length > 0 && (
                      <div className="flex">
                        <div className="flex gap-4 flex-col w-full">
                          {curFields?.map((x, index) => {
                            if (
                              x?.fieldType === "NUMBER" ||
                              x?.fieldType === "STRING" ||
                              x?.fieldType === "DATE"
                            ) {
                              return (
                                <div
                                  className="flex flex-col w-full gap-1"
                                  key={index}
                                >
                                  <div className="w-full">
                                    <label htmlFor="" className="font-bold">
                                      {x?.fieldName}
                                    </label>
                                    <hr />
                                  </div>
                                  <input
                                    type="text"
                                    className={`flex flex-col w-full gap-1 border rounded h-[2.5rem] ${
                                      x.internalFacing
                                        ? "border-red-400"
                                        : "border-gray-200"
                                    }`}
                                    onChange={(e) => {
                                      setValues((prev) => ({
                                        ...prev,
                                        [x?.id]: e.target.value,
                                      }));
                                    }}
                                    defaultValue={
                                      values[x?.id] ? values[x?.id] : ""
                                    }
                                  />
                                </div>
                              );
                            } else if (x?.fieldType === "SECTION") {
                              return (
                                <div className="flex flex-col items-start  mr-auto gap-1">
                                  <div
                                    className={`w-full ${
                                      x.internalFacing
                                        ? "border-red-400"
                                        : "border-gray-200"
                                    } p-2 rounded`}
                                  >
                                    <label className="font-bold">
                                      {x?.fieldName}
                                    </label>
                                    <hr />
                                  </div>
                                </div>
                              );
                            } else if (x?.fieldType === "BOOLEAN") {
                              return (
                                <div className="flex flex-col items-start  mr-auto gap-1">
                                  <div className="w-full">
                                    <label className="font-bold">
                                      {x?.fieldName}
                                    </label>
                                    <hr />
                                  </div>
                                  <div
                                    className={`border ${
                                      x.internalFacing
                                        ? "border-red-400"
                                        : "border-gray-200"
                                    } p-2 rounded`}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        values[x?.id]
                                          ? values[x?.id] === "true"
                                            ? true
                                            : false
                                          : false
                                      }
                                      onChange={(e) => {
                                        setValues((prev) => ({
                                          ...prev,
                                          [x?.id]:
                                            values[x?.id] === "true"
                                              ? "false"
                                              : "true",
                                        }));
                                      }}
                                      className="h-5 w-5"
                                    />
                                  </div>
                                </div>
                              );
                            } else if (x?.fieldType === "DROPDOWN") {
                              return (
                                <div className="flex flex-col items-start  mr-auto gap-1  w-full">
                                  <div className="w-full">
                                    <label className="font-bold">
                                      {x?.fieldName}
                                    </label>
                                    <hr className="mb-4" />
                                  </div>

                                  <select
                                    className={` h-[2.5rem] border p-2 rounded w-6/12 ${
                                      x.internalFacing
                                        ? "border-red-400"
                                        : "border-gray-200"
                                    }`}
                                    onChange={(e) => {
                                      setValues((prev) => ({
                                        ...prev,
                                        [x?.id]: e.target.value,
                                      }));
                                    }}
                                  >
                                    <option default disabled selected>
                                      select
                                    </option>
                                    {x.dropdownValues?.length > 0
                                      ? x.dropdownValues.map((dv) => {
                                          return (
                                            <option
                                              selected={
                                                values[x?.id] === dv.value
                                              }
                                            >
                                              {dv.value}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </div>
                              );
                            } else if (x.fieldType === "CHECK") {
                              return (
                                <div className="flex flex-col gap-2">
                                  <div className="w-full">
                                    <label className="font-bold">
                                      {x?.fieldName}
                                      {/* {x.id} */}
                                    </label>
                                    <hr />
                                  </div>

                                  {x?.checkValues?.map((cv) => {
                                    const cav = checkApplicationValues?.filter(
                                      (y) => {
                                        //check application value
                                        return (
                                          y.fieldId === x.id &&
                                          y.checkValueId === cv.id
                                        );
                                      }
                                    )[0];
                                    if (cav) {
                                      return (
                                        <div
                                          className={`w-fit p-2 rounded flex gap-2 border ${
                                            x.internalFacing
                                              ? "border-red-400"
                                              : "border-gray-200"
                                          }`}
                                        >
                                          <input
                                            type="checkbox"
                                            className="w-4"
                                            defaultChecked={
                                              checkValues[
                                                `${cav.id}-${cav.fieldId}`
                                              ]
                                            }
                                            onClick={(e) => {
                                              handleCheckboxChange(
                                                e,
                                                `${cav.id}-${cav.fieldId}`
                                              );
                                            }}
                                          />
                                          <label>{cv.value}</label>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className={`w-fit p-2 rounded flex gap-2 border ${
                                            x.internalFacing
                                              ? "border-red-400"
                                              : "border-gray-200"
                                          }`}
                                        >
                                          <input
                                            type="checkbox"
                                            className="w-4"
                                            // defaultChecked={
                                            //   checkValues[
                                            //     `${cav.id}-${cav.fieldId}`
                                            //   ]
                                            // }
                                            onClick={(e) => {
                                              // handleCheckboxChange(
                                              //   e,
                                              //   `${cav.id}-${cav.fieldId}`
                                              // );
                                            }}
                                          />
                                          {/* <label>{cv.value}</label> */}
                                        </div>
                                      );
                                    }
                                  })}
                                  {x.checkOther && (
                                    <>
                                      <span className="mt-2">Other</span>
                                      <input
                                        type="text"
                                        defaultValue={
                                          applicationValues?.filter(
                                            (appVal) => {
                                              return appVal.fieldId === x?.id;
                                            }
                                          ).length > 0
                                            ? applicationValues?.filter(
                                                (appVal) => {
                                                  return (
                                                    appVal.fieldId === x?.id
                                                  );
                                                }
                                              )[0].value
                                            : null
                                        }
                                        onChange={(e) => {
                                          setValues((prev) => ({
                                            ...prev,
                                            [x.id]: e.target.value,
                                          }));
                                        }}
                                        className={`flex flex-col w-full gap-1 border rounded h-[2.5rem] ${
                                          x.internalFacing
                                            ? "border-red-400"
                                            : "border-gray-200"
                                        }`}
                                      />
                                    </>
                                  )}
                                </div>
                              );
                            } else if (x.fieldType === "LOOKUP") {
                              return (
                                <div className="flex flex-col gap-4 mt-4">
                                  <div className="border-b border-gray-300">
                                    <label className="font-bold">
                                      {x?.fieldName}
                                    </label>
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <span className="font-bold">My List</span>
                                    <span className="">{`Total ${
                                      x?.lookupCharacteristics?.useMultiplier
                                        ? x.lookupCharacteristics
                                            .multiplierLabel
                                        : ""
                                    }: ${sumInputLabel}`}</span>
                                    <table className="table-auto w-full text-left shadow">
                                      <thead>
                                        <tr className="bg-blue-reef text-white">
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {x.lookupCharacteristics.name1Label}
                                          </th>
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {x.lookupCharacteristics.name2Label}
                                          </th>
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {x.lookupCharacteristics.inputLabel}
                                          </th>
                                          {x?.lookupCharacteristics
                                            ?.useMultiplier && (
                                            <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                              {
                                                x.lookupCharacteristics
                                                  .multiplierLabel
                                              }
                                            </th>
                                          )}
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {"Actions"}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {myList?.map((ml) => {
                                          return (
                                            <tr className="h-[2.5rem]">
                                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                {ml.lookupName1}
                                              </td>
                                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                {ml.lookupName2}
                                              </td>
                                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                {ml.inputLabel}
                                              </td>
                                              {x.lookupCharacteristics
                                                .useMultiplier && (
                                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                  {ml.multiplier *
                                                    ml.inputLabel}
                                                </td>
                                              )}
                                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                {
                                                  <BsFillTrashFill
                                                    size="20"
                                                    className="text-red-500 cursor-pointer hover:text-gray-200"
                                                    onClick={(e) => {
                                                      deleteFromMyList(
                                                        ml.id,
                                                        ml.valueId
                                                      );
                                                    }}
                                                  />
                                                }
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="flex flex-col gap-4">
                                    <div className="flex flex-col">
                                      <span className="font-bold">
                                        Search the Lookup
                                      </span>
                                      <span>
                                        {
                                          x.lookupCharacteristics
                                            .searchDescription
                                        }
                                      </span>
                                    </div>
                                    <div className="flex gap-2">
                                      <input
                                        type="text"
                                        className="`border-gray-200 border rounded p-2 w-8/12"
                                        onChange={(e) => {
                                          setLookupSearch(e.target.value);
                                        }}
                                      />
                                      <button
                                        className="bg-green-reef p-2 rounded text-white shadow"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          generateList();
                                        }}
                                      >
                                        Search
                                      </button>
                                    </div>
                                    <table className="table-auto w-full text-left shadow">
                                      <thead>
                                        <tr className="bg-blue-reef text-white">
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {x.lookupCharacteristics.name1Label}
                                          </th>
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {x.lookupCharacteristics.name2Label}
                                          </th>
                                          {x.lookupCharacteristics
                                            .useMultiplier && (
                                            <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                              {
                                                x.lookupCharacteristics
                                                  .multiplierLabel
                                              }
                                            </th>
                                          )}
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {x.lookupCharacteristics.inputLabel}
                                          </th>
                                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                            {"Actions"}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {searchList !== 0 ? (
                                          searchList?.map((sl, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="h-[2.5rem]"
                                              >
                                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                  {sl.lookupName1}
                                                </td>
                                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                  {sl.lookupName2}
                                                </td>
                                                {x.lookupCharacteristics
                                                  .useMultiplier && (
                                                  <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                    {sl.multiplier}
                                                  </td>
                                                )}
                                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 w-3/12">
                                                  <input
                                                    className="`border-gray-200 border rounded p-2 w-full"
                                                    onChange={(e) => {
                                                      setLookupInput(sl, e);
                                                    }}
                                                  />
                                                </td>
                                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                                  <button
                                                    className="bg-green-reef rounded shadow hover:bg-white hover:border hover:text-gray-900 text-gray-50"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      addToMyList(sl, index);
                                                    }}
                                                  >
                                                    <IoIosAdd size="24" />
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr
                                            key={index}
                                            className="h-[2.5rem]"
                                          >
                                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                              {"No search results"}
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="flex flex-col">
                                    <span className="font-bold">
                                      Add new item not found in search
                                    </span>
                                    <span>
                                      {x.lookupCharacteristics.addDescription}
                                    </span>
                                    <div className="flex gap-4">
                                      <input
                                        className="`border-gray-200 border rounded p-2 w-8/12 h-[2.5rem]"
                                        placeholder={
                                          x.lookupCharacteristics.name1Label
                                        }
                                        onChange={(e) => {
                                          setNewItem((prev) => ({
                                            ...prev,
                                            lookupName1: e.target.value,
                                          }));
                                        }}
                                      />
                                      <input
                                        className="`border-gray-200 border rounded p-2 w-8/12 h-[2.5rem]"
                                        placeholder={
                                          x.lookupCharacteristics.name2Label
                                        }
                                        onChange={(e) => {
                                          setNewItem((prev) => ({
                                            ...prev,
                                            lookupName2: e.target.value,
                                          }));
                                        }}
                                      />
                                      {x.lookupCharacteristics
                                        .useMultiplier && (
                                        <input
                                          className="`border-gray-200 border rounded p-2 w-8/12 h-[2.5rem]"
                                          placeholder={
                                            x.lookupCharacteristics
                                              .multiplierLabel
                                          }
                                          onChange={(e) => {
                                            setNewItem((prev) => ({
                                              ...prev,
                                              multiplier: e.target.value,
                                            }));
                                          }}
                                        />
                                      )}
                                      <input
                                        className="`border-gray-200 border rounded p-2 w-8/12 h-[2.5rem]"
                                        placeholder={
                                          x.lookupCharacteristics.inputLabel
                                        }
                                        onChange={(e) => {
                                          setNewItem((prev) => ({
                                            ...prev,
                                            inputLabel: e.target.value,
                                          }));
                                        }}
                                      />
                                      <button
                                        className="bg-green-reef p-2 rounded text-white shadow"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          addToMyList();
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          {uploads}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 p-4 flex flex-col gap-4 shadow rounded">
                  <div className="flex flex-col gap-2">
                    <span className="text-blue-reef font-bold tracking-wide">
                      Admin Documents Upload
                    </span>
                    <hr />
                    <div>
                      <table className="w-full">
                        <thead className="bg-blue-reef text-white">
                          <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap ">
                            File Name
                          </th>
                          <th className="p-3  font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
                            Upload Date
                          </th>
                          <th className="p-3  font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
                            Uploaded By
                          </th>
                          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
                            Actions
                          </th>
                        </thead>
                        <tbody className="divide-y">
                          {/* if there is a secondary id, it means that it is an upload when it comes to applications and not directly tied to the application */}
                          {(documentList?.filter(
                            (x) => x.uploader && !x.secondaryId
                          )?.length === 0
                            ? true
                            : false) && (
                            <tr className="text-center">
                              <td colSpan="100%">
                                This application currently has no admin files
                                uploaded.
                              </td>
                            </tr>
                          )}
                          {documentList
                            ?.filter((x) => x.uploader && !x.secondaryId)
                            .map((el, index) => {
                              return (
                                <tr
                                  className={`rounded-lg dark:text-white text-black text-start p-3 hover:bg-blue-50`}
                                >
                                  <td className="p-3 dark:text-white whitespace-nowrap underline cursor-pointer text-blue-reef">
                                    <span
                                      onClick={() => {
                                        downloadFile(el.fileName);
                                      }}
                                    >
                                      {el.fileName.split("Z_")[1]}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {((ts) =>
                                        new Date(
                                          `${ts.slice(0, 4)}-${ts.slice(
                                            4,
                                            6
                                          )}-${ts.slice(6, 8)}T${ts.slice(
                                            9,
                                            11
                                          )}:${ts.slice(11, 13)}:${ts.slice(
                                            13,
                                            15
                                          )}.${ts.slice(15)}Z`
                                        )
                                          .toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          })
                                          .replace(/,/, ","))(
                                        el.fileName.split("Z_")[0]
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {((user) => {
                                        return user
                                          ? user?.firstName +
                                              " " +
                                              user?.lastName
                                          : "CUSTOMER";
                                      })(
                                        userList?.filter(
                                          (x) => x?.id == el.uploader
                                        )[0]
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <button>
                                      <BsFillTrashFill
                                        size="18"
                                        className="cursor-pointer dark:text-white hover:text-red-400"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          setShowDeleteFile(1);
                                          setDeleteFileObj(el.id);
                                        }}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <input
                      type="file"
                      id="docpicker"
                      accept="*"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];

                        if (isAllowedFile(selectedFile)) {
                          let tmpObj = {
                            file: selectedFile,
                            appId: props.modalController.modalAppId,
                          };
                          uploadDocument(tmpObj);
                        } else {
                          // TODO: make a better alert
                          alert(
                            "Sorry, you cannot upload videos or .exe files."
                          );
                          // Clear the file input if needed
                          e.target.value = null;
                        }
                      }}
                    />
                    {showDeleteFile && (
                      <DeleteModal
                        handleClose={hideShowDeleteFile}
                        deleteFunc={deleteFile}
                        id={deleteFileObj}
                        heading={"Are you sure you want to delete this file?"}
                      />
                    )}
                  </div>
                </div>
                <div className="btns flex justify-center">
                  <Button
                    variant="outline"
                    className="rounded bg-green-reef text-white w-2/12 mx-auto"
                    onClick={(e) => {
                      e.preventDefault();

                      updateApplication(e);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center gap-4 h-[20rem]">
            <span>Saving application</span>
            <HashLoader size={40} color={"#A3AEB9"} />
          </div>
        )}
        <div className="flex justify-center w-full overflow-auto">
          {modalMenu.menu[1] && (
            <CommunicationHistory
              props={{
                communicationHistory: communicationHistory,
                applicationId: props.modalController.modalAppId,
                getCommunicationLogs: getCommunicationLogs,
                statusList: status,
                customerId: customer?.id,
                cost: cost,
                property: property,
                formatAddress: formatAddress,
              }}
            />
          )}
        </div>
        <div className="flex justify-center w-full overflow-auto">
          {modalMenu.menu[2] && (
            <ApplicationStatusHistory
              props={{
                statusLogs: statusLogs,
                getStatusLogs: getStatusLogs,
              }}
            />
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default UpdateApplicationModal;
