import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  fields: [],
  status: "idle",
  error: null,
};

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/program/field";
const local_base_url = "http://localhost:3000/program/field";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchFields = createAsyncThunk("field/fetchFields", async () => {
  try {
    const res = await axios.post(
      `${active_base_url}/fetch-all`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const fields = res.data.data;
    return fields;
  } catch (err) {
    console.log(err);
  }
});

export const fieldByProgram = createAsyncThunk(
  "field/fetch-by-id",
  async () => {
    try {
      console.log("active base url: ", active_base_url);
      const res = await axios.post(
        `${active_base_url}/fetch-by-id`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const fields = res.data.data;
      return fields;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addField = createAsyncThunk("field/add", async (field) => {
  try {
    console.log("process.env: ", process.env.REACT_APP_PRODUCTION);
    console.log("active base url: ", active_base_url);

    const res = await axios.post(`${active_base_url}/add`, field, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const addField = res.data.data;
    return addField;
  } catch (err) {
    console.log(err);
  }
});

export const deleteField = createAsyncThunk("field/delete", async (id) => {
  try {
    const res = await axios.post(`${active_base_url}/delete`, id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const deletedField = res.data.data;
    return deletedField;
  } catch (err) {
    console.log(err);
  }
});

export const updateField = createAsyncThunk("field/update", async (field) => {
  try {
    const res = await axios.post(`${active_base_url}/update`, field, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const updatedField = res.data.data;
    return updatedField;
  } catch (err) {
    console.log(err);
  }
});

export const fieldSlice = createSlice({
  name: "programField",
  initialState: { value: initStateValue },
  reducers: {
    clearProgramFields: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFields.pending, (state, action) => {
        state.value.status = "loading";
        state.value.fields = action.payload;
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.fields = action.payload;
      })
      .addCase(fetchFields.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllFields = (state) => state.programField.value.fields;
export const getFieldStatus = (state) => state.programField.value.status;
export const { clearProgramFields } = fieldSlice.actions;
export default fieldSlice.reducer;
