import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  lookupOptions: [],
  status: "idle",
  error: null,
};

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/lookup-option";
const local_base_url = "http://localhost:3000/lookup-option";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchLookupOptions = createAsyncThunk(
  "lookup-option/fetch-all",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const lo = res.data.data;
      return lo;
    } catch (err) {
      console.log(err);
    }
  }
);

export const lookupOptionById = createAsyncThunk(
  "lookup-option/fetch-by-id",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-by-id`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const lo = res.data.data;
      return lo;
    } catch (err) {
      console.log(err);
    }
  }
);

export const createLookupOption = createAsyncThunk(
  "lookup-option/create",
  async (lookupOption) => {
    try {
      const res = await axios.post(`${active_base_url}/create`, lookupOption, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const lo = res.data.data;
      return lo;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateLookupOption = createAsyncThunk(
  "lookup-option/update",
  async (lookupOption) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, lookupOption, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const lo = res.data.data;
      return lo;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteLookupOption = createAsyncThunk(
  "lookup-option/delete",
  async (id) => {
    try {
      const res = await axios.post(`${active_base_url}/delete`, id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const lo = res.data.data;
      return lo;
    } catch (err) {
      console.log(err);
    }
  }
);

export const lookupOptionSlice = createSlice({
  name: "lookupOption",
  initialState: { value: initStateValue },
  reducers: {
    clearLookupOptions: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLookupOptions.pending, (state, action) => {
        state.value.status = "loading";
        state.value.lookupOptions = action.payload;
      })
      .addCase(fetchLookupOptions.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.lookupOptions = action.payload;
      })
      .addCase(fetchLookupOptions.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllLookupOptions = (state) =>
  state.lookupOption.value.fields;
export const getLookupOptionStatus = (state) => state.lookupOption.value.status;
export const { clearLookupOptions } = lookupOptionSlice.actions;
export default lookupOptionSlice.reducer;
