import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllCustomers } from "../../features/customer";
import ReactPaginate from "react-paginate";
import Pagination from "@mui/material/Pagination";

const Paginate = ({ props }) => {
  let pages = [];
  let numOfPages = Math.ceil(props.totalItems / props.itemsPerPage);
  const [pageNumber, setPageNumber] = useState(0);

  return (
    <Pagination
      count={numOfPages}
      color="primary"
      size="large"
      className="mx-auto mt-4"
      variant="outlined"
      onChange={(e, page) => {
        props.setCurrentPage(page);
      }}
    />
  );
};

export default Paginate;
