import React, { useState } from "react";
import Customer from "./Customer";
import CustomerPopupModal from "../Customers/CustomerPopupModal";

const CustomerTable = ({ props }) => {
  console.log("Customer Table", props);

  // popupModal
  const [popupModal, setPopupModal] = useState(false);
  const closePopupModal = () => {
    setPopupModal(false);
  };

  const [customerId, setCustomerId] = useState(null);
  const openPopupModal = (id) => {
    console.log("open popup called");
    setCustomerId(id);
    setPopupModal(true);
  };

  return (
    <div className="rounded shadow">
      {popupModal && customerId && (
        <CustomerPopupModal
          props={{ id: customerId, closePopupModal: closePopupModal }}
        />
      )}
      <table className="w-full">
        <thead className="border-b-2 border-gray-200 bg-gray-50 text-gray-700">
          <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap ">
            Customer ID
          </th>
          <th className="p-3  font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            First Name
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Last Name
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Account ID
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Account Status
          </th>
          {/* 
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Property ID
          </th>
          */}
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Property Type
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Owner
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Tenant
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Account Holder
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Landscaper
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Property Manager
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Landlord
          </th>
          <th className="p-3 font-semibold tracking-wider text-sm text-start whitespace-nowrap ">
            Other Relationship to Property
          </th>
        </thead>
        <tbody className="divide-y">
          {/* {waterSavings &&
            waterSavings.map((saving, index) => {
              return (
                <WaterSavings props={{ waterSaving: saving, index: index }} />
              );
            })} */}
          {props.customerProperties.map((el, index) => {
            return (
              <Customer
                props={{
                  customer: el,
                  index: index,
                  openPopupModal: openPopupModal,
                  closePopupModal: closePopupModal,
                }}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerTable;
