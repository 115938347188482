import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import ProgramInformation from "../../Programs/ProgramInformation";
import ApprovalSteps from "../../Programs/ApprovalSteps";
import ProgramBudgetLog from "../../Programs/ProgramBudgetLog";
import { selectAllFields } from "../../../features/programField";
import { selectAllUploads } from "../../../features/programUpload";
import { deleteUpload, fetchUploads } from "../../../features/programUpload";

import AddStatusModal from "../../Programs/AddStatusModal";
import AddFieldModal from "../../Programs/AddFieldModal";
import UpdateStatusModal from "../../Programs/UpdateStatusModal";
import DeleteModal from "../../Misc/DeleteModal";
import UpdateFieldModal from "../../Programs/UpdateFieldModal";
import UpdateUploadModal from "../../Programs/UpdateUploadModal";
import AddUploadModal from "../../Programs/AddUploadModal";
import { Button } from "../../Ui/button";
import ProgramImageModal from "../../Programs/ProgramImageModal";
import { AnimatePresence } from "framer-motion";
import AddEmailTemplateModal from "../../Programs/AddEmailTemplateModal";

import {
  getStatusListStatus,
  selectStatusList,
  fetchStatusLogs,
  getStatusLogStatus,
  deleteStatus,
  fetchStatusList,
} from "../../../features/status";
import { deleteField, fetchFields } from "../../../features/programField";
import {
  fetchApplicationValues,
  getValueStatus,
} from "../../../features/applicationValue";
import { getStatus } from "../../../features/applicationStatus";
import {
  getAppPropertyStatus,
  fetchApplicationProperties,
} from "../../../features/applicationProperties";
import { selectAllPrograms } from "../../../features/program";
import { setToast } from "../../../utils/ToastNotification";

import {
  resetEmailTemplateStatus,
  selectEmailTemplates,
  selectEmailTemplateStatus,
  emailTemplateByProgramId,
  deleteEmailTemplate,
} from "../../../features/emailTemplate";

import { useDispatch, useSelector } from "react-redux";

const ProgramsContainer = ({ props }) => {
  const dispatch = useDispatch();

  const statusLogStatus = useSelector(getStatusLogStatus);
  const statusStatus = useSelector(getStatus);
  const applicationValueStatus = useSelector(getValueStatus);
  const fieldList = useSelector(selectAllFields);
  const uploadList = useSelector(selectAllUploads);
  const statusListStatus = useSelector(getStatusListStatus);
  const statusList = useSelector(selectStatusList);
  const appPropertyStatus = useSelector(getAppPropertyStatus);
  const programList = useSelector(selectAllPrograms);
  const emailTemplates = useSelector(selectEmailTemplates);
  const emailTemplateStatus = useSelector(selectEmailTemplateStatus);

  const [addProgram, setAddProgram] = useState(false);
  const openProgram = () => {
    setEditProgram(false);
    setAddProgram(true);
  };
  const closeProgram = () => {
    setAddProgram(false);
    setEditProgram(false);
  };

  const [programInfo, setProgramInfo] = useState(null);
  const [editProgram, setEditProgram] = useState(false);
  const openEditProgram = async () => {
    setAddProgram(false);
    setEditProgram(true);
  };

  const [programId, setProgramId] = useState(null);
  const [programFields, setProgramFields] = useState(null);
  const [programUploads, setProgramUploads] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [fetchTemplates, setFetchTemplates] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const openDeleteTemplate = (id) => {
    setDeleteTemplateId(id);
    setDeleteTemplate(true);
  };

  const closeDeleteTemplate = () => {
    setDeleteTemplateId(null);
    setDeleteTemplate(false);
  };

  const getEmailTemplates = async () => {
    try {
      const res = await dispatch(emailTemplateByProgramId({ id: programId }));
      const sorted = res.payload.data.sort((a, b) => {
        return a.id - b.id;
      });
      setTemplates([...sorted]);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTemplateFunction = async (templateId) => {
    try {
      const res = await dispatch(deleteEmailTemplate(templateId));
      console.log("deleteTempalte: ", res);
      getEmailTemplates();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (programId) {
      getEmailTemplates();
    }
  }, [programId]);

  useEffect(() => {
    if (fieldList) {
      const tempFields = fieldList?.filter((el) => {
        return el?.programId === programId;
      });
      setProgramFields(tempFields);
      if (uploadList) {
        const tempUploads = uploadList?.filter((el) => {
          return el?.programId === programId;
        });

        setProgramUploads(tempUploads);
      }
    }
  }, [programId, fieldList, uploadList]);

  useEffect(() => {
    applicationValueStatus === "idle" && dispatch(fetchApplicationValues());
    appPropertyStatus === "idle" && dispatch(fetchApplicationProperties());
    statusLogStatus === "idle" && dispatch(fetchStatusLogs());
  }, [
    statusListStatus,
    applicationValueStatus,
    statusStatus,
    statusLogStatus,
    appPropertyStatus,
    dispatch,
  ]);

  //program budget log
  const [budgetLogController, setBudgetLogController] = useState({
    programId: null,
    budgetLog: false,
    openBudgetLog: (id) => {
      setBudgetLogController((prev) => ({
        ...prev,
        budgetLog: true,
        programId: id,
      }));
    },
    closeBudgetLog: () => {
      setBudgetLogController((prev) => ({
        ...prev,
        budgetLog: false,
      }));
    },
  });

  //workflow configuration

  const [workflowController, setWorkflowController] = useState({
    modal: false,
    openModal: () => {
      setWorkflowController((prev) => ({
        ...prev,
        modal: true,
      }));
    },
    closeModal: () => {
      setWorkflowController((prev) => ({
        ...prev,
        modal: false,
      }));
    },
  });

  const exportPS = async () => {
    try {
      const workBook = XLSX.utils.book_new();
      const fieldListExport = fieldList.map((x) => {
        return {
          id: x.id,
          programId: x.programId,
          fieldType: x.fieldType,
          fieldName: x.fieldName,
          fieldDescription: x.fieldDescription,
          deleted: x.deleted,
          required: x.required,
        };
      });
      const workSheet = XLSX.utils.json_to_sheet(programList);
      XLSX.utils.book_append_sheet(workBook, workSheet, "programs");
      const workSheet2 = XLSX.utils.json_to_sheet(fieldListExport);
      XLSX.utils.book_append_sheet(workBook, workSheet2, "program fields");

      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

      XLSX.writeFile(
        workBook,
        "Program_Specification_Export-" + Date.now() + ".xlsx"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const [addStatusModal, setAddStatusModal] = useState(false);
  const openAddStatusModal = () => {
    setAddStatusModal(true);
  };
  const closeAddStatusModal = () => {
    setAddStatusModal(false);
  };

  const [addFieldModal, setAddFieldModal] = useState(false);
  const openAddFieldModal = () => {
    setAddFieldModal(true);
  };
  const closeAddFieldModal = () => {
    setAddFieldModal(false);
  };

  //status controller
  const [statusController, setStatusController] = useState({
    statusDeleteModal: false,
    deleteStatusId: null,
    deleteStatusFunction: async (statusId) => {
      try {
        await dispatch(deleteStatus(statusId));
        await dispatch(fetchStatusList());
      } catch (err) {
        console.log(err);
      }
    },
    openStatusDeleteModal: (statusId) => {
      setStatusController((prev) => ({
        ...prev,
        statusDeleteModal: true,
        deleteStatusId: statusId,
      }));
    },
    closeStatusDeleteModal: () => {
      setStatusController((prev) => ({
        ...prev,
        statusDeleteModal: false,
      }));
    },
    statusUpdateModal: false,
    status: null,
    openStatusUpdateModal: (status) => {
      setStatusController((prev) => ({
        ...prev,
        statusUpdateModal: true,
        status: status,
      }));
    },
    closeStatusUpdateModal: () => {
      setStatusController((prev) => ({
        ...prev,
        statusUpdateModal: false,
      }));
    },
  });

  const [fieldController, setFieldController] = useState({
    deleteModal: false,
    deleteFieldId: null,
    deleteFieldFunction: async (fieldId) => {
      try {
        await dispatch(deleteField(fieldId));
        await dispatch(fetchFields());
      } catch (err) {
        console.log(err);
      }
    },
    openDeleteModal: (res) => {
      setFieldController((prev) => ({
        ...prev,
        deleteModal: true,
        deleteFieldId: res.id,
      }));
    },
    closeDeleteModal: () => {
      setFieldController((prev) => ({
        ...prev,
        deleteModal: false,
      }));
    },
    updateModal: false,
    currentField: null,
    openUpdateModal: (field) => {
      setFieldController((prev) => ({
        ...prev,
        updateModal: true,
        currentField: field,
      }));
    },
    closeUpdateModal: () => {
      setFieldController((prev) => ({
        ...prev,
        updateModal: false,
      }));
    },
  });

  //upload controller
  const [uploadController, setUploadController] = useState({
    uploadDeleteModal: false,
    deleteUploadFunction: async (uploadId) => {
      try {
        await dispatch(deleteUpload(uploadId));
        await dispatch(fetchUploads());
        const toastPayload = {
          index: 0,
          description: 4,
          type: "program",
        };
        setToast(toastPayload);
      } catch (err) {
        console.log(err);
      }
    },
    deleteUploadId: null,
    openUploadModal: (res) => {
      setUploadController((prev) => ({
        ...prev,
        uploadDeleteModal: true,
        deleteUploadId: res.id,
      }));
    },
    closeUploadModal: () => {
      setUploadController((prev) => ({
        ...prev,
        uploadDeleteModal: false,
      }));
    },
    uploadUpdateModal: false,
    currentUpload: null,
    openUploadUpdateModal: (upload) => {
      setUploadController((prev) => ({
        ...prev,
        uploadUpdateModal: true,
        currentUpload: upload,
      }));
    },
    closeUploadUpdateModal: () => {
      setUploadController((prev) => ({
        ...prev,
        uploadUpdateModal: false,
      }));
    },
  });

  const [uploadUpdateModal, setUploadUpdateModal] = useState(false);
  const openUploadModal = () => {
    setUploadUpdateModal(true);
  };

  const [addUploadModal, setAddUploadModal] = useState(false);
  const openAddUploadModal = () => {
    setAddUploadModal(true);
  };
  const closeAddUploadModal = () => {
    setAddUploadModal(false);
  };

  const [piModal, setPiModal] = useState(false);
  const [piImage, setPiImage] = useState(null);
  const openPiModal = (payload) => {
    setPiImage(payload);
    setPiModal(true);
  };
  const closePiModal = () => {
    setPiModal(false);
  };

  const [addTemplateModal, setAddTemplateModal] = useState(false);
  const [addTemplateMode, setAddTemplateMode] = useState("");
  const [editTemplate, setEditTemplate] = useState({
    id: null,
    emailName: "",
    subjectLine: "",
    emailBody: "",
  });
  const openAddTemplateModal = (mode) => {
    setAddTemplateModal(true);
    setAddTemplateMode(mode);
    if (mode.type === "edit") {
      const temp = {
        id: mode.id,
        emailName: mode.emailName,
        subjectLine: mode.subjectLine,
        bodyText: mode.bodyText,
      };

      setEditTemplate({ ...temp });
    }
  };
  const closeAddTemplateModal = () => {
    const temp = {
      id: null,
      emailName: "",
      subjectLine: "",
      emailBody: "",
    };
    setEditTemplate({ ...temp });
    setAddTemplateModal(false);
  };

  return (
    <div
      className={`h-full bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 p-8  ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content overflow-auto">
        {" "}
        <div>
          <h2 className="font-sans mb-6 text-lg">PROGRAMS</h2>
          <AnimatePresence exitBeforeEnter initial={false}>
            {addStatusModal && (
              <AddStatusModal
                props={{
                  handleClose: closeAddStatusModal,
                  programId: programInfo[0].id,
                }}
              />
            )}

            {addFieldModal && (
              <AddFieldModal
                props={{
                  handleClose: closeAddFieldModal,
                  programId: programInfo[0].id,
                }}
              />
            )}
            {statusController.statusUpdateModal && (
              <UpdateStatusModal
                props={{ statusController: statusController }}
              />
            )}

            {statusController.statusDeleteModal && (
              <DeleteModal
                deleteFunc={statusController.deleteStatusFunction}
                handleClose={statusController.closeStatusDeleteModal}
                id={statusController.deleteStatusId}
                heading={"Are you sure you want to delete this status?"}
              />
            )}

            {fieldController.updateModal && (
              <UpdateFieldModal
                props={{
                  fieldController: fieldController,
                  programId: programInfo[0].id,
                }}
              />
            )}

            {fieldController.deleteModal && (
              <DeleteModal
                handleClose={fieldController.closeDeleteModal}
                deleteFunc={fieldController.deleteFieldFunction}
                id={fieldController.deleteFieldId}
                heading={"Are you sure you want to delete this field?"}
              />
            )}
            {uploadController.uploadDeleteModal && (
              <DeleteModal
                handleClose={uploadController.closeUploadModal}
                deleteFunc={uploadController.deleteUploadFunction}
                id={uploadController.deleteUploadId}
                heading={
                  "Are you sure you want to delete this required documentation upload?"
                }
              />
            )}

            {deleteTemplate && (
              <DeleteModal
                handleClose={closeDeleteTemplate}
                deleteFunc={deleteTemplateFunction}
                id={deleteTemplateId}
                heading={"Are you sure you want to delete this email template?"}
              />
            )}

            {uploadController.uploadUpdateModal && (
              <UpdateUploadModal
                props={{
                  uploadController: uploadController,
                  programId: programInfo[0].id,
                }}
              />
            )}

            {addUploadModal && (
              <AddUploadModal
                props={{
                  handleClose: closeAddUploadModal,
                  programId: programInfo[0].id,
                }}
              />
            )}
            {addTemplateModal && (
              <AddEmailTemplateModal
                props={{
                  handleClose: closeAddTemplateModal,
                  programId: programInfo[0].id,
                  getEmailTemplates: getEmailTemplates,
                  mode: addTemplateMode,
                  editTemplate: editTemplate,
                }}
              />
            )}
            {piImage && piModal && (
              <ProgramImageModal handleClose={closePiModal} image={piImage} />
            )}
          </AnimatePresence>

          <div className="sm:flex sm:flex-col lg:flex lg:flex-row items-center gap-2 w-full dark:text-gray-50 mb-4">
            <span>Select Program to Configure</span>

            <select
              className="focus:outline-none rounded text-gray-900 dark:bg-gray-600 dark:text-white h-[2.5rem] border"
              onChange={(e) => {
                setProgramId(parseInt(e.target.value));
                const temp = props.programs.filter((el) => {
                  return el.id === parseInt(e.target.value);
                });
                setProgramInfo(temp);
                openEditProgram();
              }}
            >
              <option disabled selected>
                Select a Program
              </option>
              {props.programs &&
                [...props.programs]
                  .sort((a, b) => {
                    // return b.programName - a.programName;
                    return a.programName.toLowerCase() >
                      b.programName.toLowerCase()
                      ? 1
                      : b.programName.toLowerCase() >
                        a.programName.toLowerCase()
                      ? -1
                      : 0;
                  })
                  .map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.programName}
                      </option>
                    );
                  })}
            </select>

            <div className="sm:flex sm:flex-col lg:flex lg:flex-row lg:ml-4 sticky">
              <Button
                variant="outline"
                className="rounded bg-green-reef text-white lg:before:ml-auto"
                onClick={(e) => {
                  openProgram();
                }}
              >
                Add New Program
              </Button>
              <Button
                variant="outline"
                className="rounded bg-green-reef text-white lg:ml-auto"
                onClick={(e) => {
                  exportPS();
                }}
              >
                Export Program Specification
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col max-h-[50rem] overflow-auto">
          <div className="flex w-full justify-center gap-8 p-4 ">
            {addProgram && (
              <ProgramInformation
                props={{
                  closeProgram: closeProgram,
                  edit: false,
                  // openPiModal: openPiModal,
                  // closePiModal: closePiModal,
                }}
              />
            )}
            {budgetLogController.budgetLog && (
              <ProgramBudgetLog
                props={{
                  budgetLogController: budgetLogController,
                  view: "app",
                }}
              />
            )}

            {editProgram &&
              fieldList &&
              programUploads &&
              uploadList &&
              statusList &&
              programInfo && (
                <div className="flex flex-col gap-12 w-full items-center max-h-[50rem] ">
                  {!workflowController.modal && (
                    <ProgramInformation
                      props={{
                        closeProgram: closeProgram,
                        edit: true,
                        program: programInfo,
                        budgetLogController: budgetLogController,
                        workflowController: workflowController,
                        openPiModal: openPiModal,
                        closePiModal: closePiModal,
                      }}
                    />
                  )}
                  {workflowController.modal && (
                    <ApprovalSteps
                      props={{
                        steps: props.data[1],
                        fields: fieldList,
                        uploads: uploadList,
                        statusList: statusList,
                        descriptions: props.data[3],
                        program: programInfo,
                        workflowController: workflowController,
                        openAddStatusModal: openAddStatusModal,
                        openAddFieldModal: openAddFieldModal,
                        statusController: statusController,
                        fieldController: fieldController,
                        uploadController: uploadController,
                        openUploadModal: openUploadModal,
                        openAddTemplateModal: openAddTemplateModal,
                        closeAddTemplateModal: closeAddTemplateModal,
                        openAddUploadModal: openAddUploadModal,
                        templates: templates,
                        openDeleteTemplate: openDeleteTemplate,
                      }}
                    />
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramsContainer;
