import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setToast } from "../utils/ToastNotification";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/reference";
const local_base_url = "http://localhost:3000/reference";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  references: [],
  status: "idle",
  error: null,
};

export const referenceById = createAsyncThunk(
  "reference/fetch-by-id",
  async (payload) => {
    try {
      const res = await axios.post(`${active_base_url}/fetch-by-id`, payload);
      const references = res.data.data;
      return references;
    } catch (err) {
      console.log(err);
    }
  }
);
export const downloadReference = createAsyncThunk(
  "program/image/downlaod",
  async (payload) => {
    try {
      let tmpObj = {
        fileName: payload,
      };
      console.log("download-ref: ", tmpObj);
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/download`
          : `http://localhost:3000/blobstorage/download`;
      const res = await axios.post(url, tmpObj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "arraybuffer",
      });
      console.log("blob-download: ", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);
export const deleteReference = createAsyncThunk(
  "reference/delete",
  async (payload) => {
    try {
      console.log("delete-reference: ", payload);
      const res = await axios.post(
        `https://expressproxy-prod.azurewebsites.net/reference/delete`,
        payload
      );
      console.log("blob-download: ", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const referenceSlice = createSlice({
  name: "reference",
  initialState: { value: initStateValue },
  reducers: {},
  extraReducers(builder) {},
});

// export const selectAllLocations = (state) => state.location.value.locations;
// export const getLocationStatus = (state) => state.location.value.status;

export default referenceSlice.reducer;
