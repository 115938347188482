import React, { useEffect, useState, useReducer } from "react";
import PropertyTable from "../Properties/CustomerPropertyTable";
import CustomerProfile from "../Customers/CustomerProfile";
import RegisterProperty from "../User/RegisterAccount";
import RegisterContact from "./RegisterContact";
import { selectAllApplications } from "../../features/application";
import { useSelector } from "react-redux";
import { selectAllProperties } from "../../features/properties";

const CustomerProfileSettings = ({ props }) => {
  const [tmpUpdate, forceUpdate] = useState(0);
  const [showRegister, setShowRegister] = useState(false);
  const applicationList = useSelector(selectAllApplications);
  const propertyListRedux = useSelector(selectAllProperties);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [propertyList, setPropertyList] = useState(props);
  const outputPList = () => {
    console.log(propertyList);
  };
  useEffect(() => {
    setPropertyList(props);
  }, [props]);
  useEffect(() => {
    console.log("App", applicationList);
  }, [applicationList]);
  return (
    <div className="flex flex-col gap-4 font-sans">
      <h2 className="w-full font-sans text-lg text-gray-900 ">MY PROFILE</h2>
      <div className="flex">
        <CustomerProfile />
      </div>
      <hr className="border-1 border-blue-reef" />
      {applicationList && (
        <div className="flex flex-col  w-full">
          {registerOpen ? (
            <RegisterProperty props={{ setRegisterOpen: setRegisterOpen }} />
          ) : (
            <div className="flex flex-col gap-4">
              <span className="tracking-wide" onClick={outputPList}>
                Properties Registered with My Profile in Water Savings Center
              </span>

              {showRegister == 0 && (
                <button
                  className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500 w-fit"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRegister(1);
                  }}
                >
                  Register a new Property
                </button>
              )}

              {showRegister == 1 && (
                <RegisterContact
                  props={{
                    internal: 1,
                    setShowRegister: setShowRegister,
                    forceUpdate: forceUpdate,
                    propertyList: propertyListRedux,
                    setPropertyList: setPropertyList,
                  }}
                />
              )}
              {showRegister == 0 && (
                <PropertyTable
                  props={{
                    propertyList: propertyList,
                    setPropertyList: setPropertyList,
                  }}
                  applicationList={applicationList}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerProfileSettings;
