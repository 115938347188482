import React, { useState } from "react";
import { motion } from "framer-motion";

import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";

const ProgramUpload = ({ upload, index, props, openUploadModal }) => {
  const [deleteHover, setDeleteHover] = useState(false);

  const [editHover, setEditHover] = useState(false);

  return (
    <tr
      onClick={(e) => {
        props.uploadController.openUploadUpdateModal(upload);
      }}
      className={`rounded-lg dark:text-white text-black text-center hover:bg-blue-50 cursor-pointer `}
    >
      <td className="pl-2 py-2 text-sm dark:text-whitecursor-pointer whitespace-nowrap text-start">
        {upload.name}
      </td>

      <td className="pl-2 text-sm text-gray-700 whitespace-nowrap flex gap-1 justify-start items">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            props.uploadController.openUploadUpdateModal(upload);
          }}
        >
          <BsFillPencilFill
            size="18"
            className="cursor-pointer dark:text-white"
          />
          {editHover && (
            <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
              edit
            </div>
          )}
        </motion.button>

        {localStorage.getItem("role") === "ADMIN" && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.uploadController.openUploadModal({ id: upload.id });
            }}
          >
            <BsFillTrashFill
              size="18"
              className="cursor-pointer dark:text-white"
            />
            {deleteHover && (
              <div className="absolute top-[-38px] right-[-20px] w-fit p-2 bg-black text-white rounded">
                delete
              </div>
            )}
          </motion.button>
        )}
      </td>
    </tr>
  );
};

export default ProgramUpload;
