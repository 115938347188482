import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { addUser, fetchUsers } from "../../features/user";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { FaUserPlus } from "react-icons/fa";
import { Button } from "../Ui/button";

import {
  checkEmptyFields,
  passwordConfirmation,
} from "../../utils/FormValidation";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddUserModal = ({ handleClose, text, setUsers, allUsers }) => {
  const dispatch = useDispatch();

  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    role: "",
  };
  const [user, setUser] = useState(initialUser);
  //methods
  const submit = async (e) => {
    try {
      console.log("role: ", user.role);
      e.preventDefault();
      if (checkEmptyFields(user) && passwordConfirmation(user)) {
        let addedUser = await dispatch(
          addUser({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            phone: user.phone,
            role: user.role,
          })
        );

        if (!addedUser.payload.errors) {
          await dispatch(fetchUsers());
          handleClose();
          setUser(initialUser);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className=" rounded w-[50rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow font-sans"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className=" rounded w-full flex gap-2 items-center justify-center bg-gray-50 border-b">
          <h2 className="text-center flex items-center gap-2 text-gray-500 dark:text-white p-2">
            <FaUserPlus size="24" />
            Add User Profile
          </h2>
        </div>
        <div className="w-full px-12 flex flex-col gap-2 line-clamp-7 min-w-12 py-4">
          <span className=" text-blue-reef dark:text-white">
            User Information
          </span>
          <div className="name flex flex-row gap-2 text-black">
            <input
              type="text"
              className="rounded basis-6/12  border border-gray-200 h-[2.5rem]"
              placeholder=" First name"
              name="firstName"
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }));
              }}
            />
            <input
              type="text"
              className="rounded basis-6/12 border border-gray-200 h-[2.5rem]"
              placeholder=" Last name"
              name="lastName"
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="name flex flex-row gap-4 text-black">
            <input
              type="text"
              className="email text-black rounded  border border-gray-200 basis-6/12 h-[2.5rem]"
              placeholder=" email"
              name="email"
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
            <input
              type="text"
              className="phone text-black rounded  border border-gray-200 basis-6/12 h-[2.5rem]"
              placeholder=" Phone"
              name="phone"
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  phone: e.target.value,
                }));
              }}
            />
          </div>
          <span className=" text-blue-reef dark:text-white ">Account</span>
          <input
            type="password"
            className="password text-black rounded  border border-gray-200 h-[2.5rem]"
            placeholder=" Password"
            name="password"
            onChange={(e) => {
              setUser((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
          />
          <input
            type="password"
            className="confirm-password text-black rounded  border border-gray-200 h-[2.5rem]"
            placeholder=" Confirm password"
            name="confirmPassword"
            onChange={(e) => {
              setUser((prev) => ({
                ...prev,
                confirmPassword: e.target.value,
              }));
            }}
          />
          <select
            name="role"
            id="role"
            className=" text-gray-900 rounded border border-gray-200 h-[2.5rem]"
            onChange={(e) => {
              setUser((prev) => ({
                ...prev,
                role: e.target.value,
              }));
            }}
          >
            <option defaultValue>Select user permssions</option>

            <option value="CUSTOMER">Customer</option>
            <option value="ADMIN">Admin</option>
            <option value="CONTRACTOR">Contractor</option>
          </select>

          <Button
            variant="outline"
            className="rounded bg-green-reef text-white w-fit mx-auto"
            onClick={(e) => {
              submit(e);
            }}
          >
            Save
          </Button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddUserModal;
